import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/app.scss';
import CKEditor from '@ckeditor/ckeditor5-vue';
import "bootstrap/dist/js/bootstrap.js"
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
//import axios from './plugins/axios'
import Notifications from '@kyvg/vue3-notification'
import { abilitiesPlugin } from '@casl/vue';
import ability from './services/ability';

createApp(App).
use(store).
use(router).
use(CKEditor).
use(VueSweetalert2).
use(Notifications).
use(abilitiesPlugin, ability, {
    useGlobalProperties: true
}).mount('#app')


