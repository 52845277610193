import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_qrcode_stream = _resolveComponent("qrcode-stream")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_qrcode_stream, {
      onDecode: _ctx.onDecode,
      onInit: _ctx.onInit
    }, null, 8, ["onDecode", "onInit"])
  ]))
}