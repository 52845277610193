<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34.766"
    height="34.772"
    viewBox="0 0 34.766 34.772"
  >
    <g
      id="Groupe_455"
      data-name="Groupe 455"
      transform="translate(197.999 -273.017)"
    >
      <path
        id="Tracé_985"
        data-name="Tracé 985"
        d="M-180.586,273.017a17.4,17.4,0,0,1,17.353,17.335,17.39,17.39,0,0,1-17.452,17.437A17.408,17.408,0,0,1-198,290.414,17.4,17.4,0,0,1-180.586,273.017Zm15.021,17.371a15.176,15.176,0,0,0-15.112-15.015,15.119,15.119,0,0,0-14.982,15.017A15.186,15.186,0,0,0-180.5,305.439,15.268,15.268,0,0,0-165.564,290.388Z"
        transform="translate(0)"
        fill="#f2f3f5"
      />
      <path
        id="Tracé_986"
        data-name="Tracé 986"
        d="M-144.134,322.255v9.431h2.292v2.1h-6.741v-2.1h2.09v-7.143h-2.248v-2.3Z"
        transform="translate(-35.311 -35.297)"
        fill="#f2f3f5"
      />
      <path
        id="Tracé_987"
        data-name="Tracé 987"
        d="M-140.67,308.141v-2.269h2.289v2.269Z"
        transform="translate(-41.097 -23.552)"
        fill="#f2f3f5"
      />
    </g>
  </svg>
</template>