<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.95"
    height="28.95"
    viewBox="0 0 40.706 40.72"
  >
    <g
      id="Groupe_450"
      data-name="Groupe 450"
      transform="translate(-668.683 -862.018)"
    >
      <path
        id="Tracé_306"
        data-name="Tracé 306"
        d="M668.683,880.627c.325-1.465.543-2.963.991-4.389a20.326,20.326,0,1,1,21.834,26.313,2.162,2.162,0,0,0-.492.188h-3.759a3.692,3.692,0,0,0-.622-.185,20.177,20.177,0,0,1-17.078-14.426c-.372-1.3-.586-2.641-.873-3.963Zm38.464,1.749a18.114,18.114,0,1,0-18.131,18.133A18.145,18.145,0,0,0,707.147,882.375Z"
        transform="translate(0)"
        fill="currentColor"
      />
      <path
        id="Tracé_307"
        data-name="Tracé 307"
        d="M740.316,927.808c.253,1.186.512,2.363,2.2,2.521,0-1.4.043-2.741-.041-4.072-.015-.231-.538-.494-.871-.631-1.552-.638-2.781-1.524-2.854-3.393a3.349,3.349,0,0,1,2.811-3.652c.285-.065.572-.118.893-.184l.16-1.395c.417.412.647.672.911.892a2.68,2.68,0,0,0,.926.654,3.18,3.18,0,0,1,2.683,3.325h-1.739c-.7-1.586-.706-1.6-1.868-1.7,0,1.1-.027,2.191.023,3.279.01.216.277.484.494.612a10.678,10.678,0,0,0,1.117.456c1.8.8,2.442,1.951,2.275,4.065a3.343,3.343,0,0,1-2.807,3.1c-.317.089-.64.156-1.009.245-.309.34.186,1.53-1.054,1.062l-.1-.962-.172.245a21.518,21.518,0,0,1-3.137-2.154c-.5-.48-.546-1.433-.846-2.317Zm3.26,2.476c1.415-.348,2.031-1.043,1.989-2.106s-.716-1.713-1.989-1.892Zm-1.1-10.211c-1.091.054-1.7.493-1.763,1.522-.073,1.121.371,1.622,1.763,1.98Z"
        transform="translate(-54.231 -42.826)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>