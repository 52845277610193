
import { defineComponent } from 'vue'
import BarCodeIcon from "@/components/icons/BarCodeIcon.vue"
import BoxIcon from "@/components/icons/BoxIcon.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
export default defineComponent({
    components: {
        BarCodeIcon,
        BoxIcon,
        InfoIcon
    },
    setup() {
        return{

        }
    },
})
