
import { defineComponent, ref, computed, onMounted } from 'vue'
import Impression from '@/components/ConfigurationComponents/Logistics/Impression.vue';
import Carton from '@/components/ConfigurationComponents/Logistics/Carton.vue';
import MappingCarton from '@/components/ConfigurationComponents/Logistics/MappingCarton.vue';

export default defineComponent({
    setup() {
        const tabs = ['Impression','Carton','Mapping Carton'];
        const activeTab = ref(0);
        const tabComponents = computed(() => {
            return [Impression,Carton,MappingCarton];
        });

        onMounted(() => {
            window.scrollTo(0, 0);
        });

        return {
            tabs,
            activeTab,
            tabComponents,
            MappingCarton
        }
    }
})
