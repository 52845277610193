
import { defineComponent, ref} from 'vue'
import Table from "@/components/Table.vue";
import InfoModal from "@/components/InfoModal.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
export default defineComponent({
     components:{
        Table,
        InfoIcon,
        ArrowLeftIcon,
        InfoModal
    },
    setup() {
    const headers = ref([
      {value: 'sku',text:'sku'},
      {value: 'name',text:'name'},
      {value: 'location',text:'location'},
      {value: 'qte',text:'qte'},
      {value: 'scanned',text:'scanned'},
    ]);
    const rows = ref([
      {
        sku: 145233,
        name: 'parfum',
        location: 'b213',
        qte: '3',
        scanned: '1/2',
      },
      {
        sku: 145233,
        name: 'parfum',
        location: 'b213',
        qte: '3',
        scanned: '1/2',
      },
      {
        sku: 145233,
        name: 'parfum',
        location: 'b213',
        qte: '3',
        scanned: '1/2',
      },
      
    ]);
    const actions = ref(false);
    return {
      headers,rows,actions
    };
  },
})
