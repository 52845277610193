
import { defineComponent, onMounted, ref } from 'vue';
import { ShipmentHistory } from '@/types';
import { useRoute } from 'vue-router';
import OrderRequest from '@/services/requests/order';
import VPagination from "@hennge/vue3-pagination";
export default defineComponent({
    components: {
        VPagination
    },
    setup() {

        const shipment_history = ref<ShipmentHistory[]>();

        const page = ref(1);
        const total_items = ref(0);
        const route = useRoute();
        function getShipmentHistory(){
            OrderRequest.getShipmentHistory(route.params.id, page.value)
            .then((response: any) => {
                shipment_history.value = response.data.data.data_array;
                total_items.value = parseInt(response.data.data.totalCount);
            })
            .catch((error) => {
                console.log(error);
            })
        }

        function changePage(){
            getShipmentHistory();
        }

        onMounted(() => {
            getShipmentHistory();
        })
        return {
            shipment_history,
            getShipmentHistory,
            changePage,
            total_items,
            page
        }
    }
})
