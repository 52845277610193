
import { defineComponent, onMounted, reactive, ref } from 'vue';
import OrderRequest from '@/services/requests/order';
import { useRoute } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import Swal from 'sweetalert2';
import NewDeliveryDocument from '../NewDeliveryDocument.vue';
import { useNotification } from "@kyvg/vue3-notification";

export default defineComponent({
  components: {
    VPagination,
    TrashIcon,
    NewDeliveryDocument
  },
  setup() {
    interface deliveryDocuments {
      type: string,
      _id: string,
      language: string,
      date: string,
      //doneby: string
    }
    interface deleted_document {
      path: string,
      delete: boolean
    }

    const notification = useNotification();
    const delivery_doc_component = ref(false);
    const route = useRoute();
    const documents = ref<deliveryDocuments[]>();
    const page = ref(1);
    const total_items = ref(0);
    /*const form = reactive({
      delivery_document: [] as deleted_document[]
    })*/

    const selectedFiles = ref([]);

    function getDocuments() {
      OrderRequest.getDocuments(route.params.id, page.value)
        .then((response: any) => {
          documents.value = response.data.data.data_array;
          total_items.value = parseInt(response.data.data.totalCount);
        })
    }
    function changePage() {
      getDocuments();
    }
    function addNewDeliveryDocument(event) {
      if (event) {
        let id = route.params.id;
        const formData = new FormData();
        formData.append(`delivery_document[0][type]`, event.type);
        formData.append(`delivery_document[0][language]`, event.language);
        formData.append(`document`, event.document);

        OrderRequest.editOrder(id, formData)
          .then((response) => {
            notification.notify({
              title: "Document successfully added",
              type: "success"
            });
            getDocuments();
          })
      }
    }
    function deleteDocument(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "The Document will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {

        if (result.isConfirmed) {
          let id = route.params.id;
          const form = {
            delivery_document: [] as deleted_document[]
          };
          form.delivery_document.push({
            path: item.path,
            delete: true
          })
          OrderRequest.editOrder(id, form)
            .then((response) => {
              notification.notify({
                title: "Document successfully deleted",
                type: "success"
              });
              getDocuments();
            })
        }
      });
    }
    onMounted(() => {
      getDocuments();
    })
    return {
      getDocuments,
      changePage,
      documents,
      page,
      total_items,
      deleteDocument,
      delivery_doc_component,
      addNewDeliveryDocument,
      selectedFiles
    }
  }
})
