
import { defineComponent, ref, onMounted} from 'vue';
import CloseIcon from "@/components/icons/CloseIcon.vue";
import { useRoute } from 'vue-router';
import { BIconPencilSquare, BIconTrash, BIconFunnel } from 'bootstrap-icons-vue';
import Swal from 'sweetalert2';
import SaveSearch from './SaveSearch.vue';
import VPagination from "@hennge/vue3-pagination";

export default defineComponent({
    components: {
        CloseIcon,
        BIconPencilSquare,
        BIconTrash,
        SaveSearch,
        BIconFunnel,
        VPagination
    },
    setup(props, { emit }) {
        const close = ref();
        const page = ref(1);
        const total_items = ref(0);
        const save_filter = ref(false);
        const saved_filters = ref([
            {
                _id: '2569854',
                name: 'saved filter 1',
                created_at: '2024-02-29'
            },
            {
                _id: '2569854',
                name: 'saved filter 2',
                created_at: '2024-03-01'
            }

        ]);
        const route = useRoute();
        function closeModal() {
            emit('closeModal');
        }
        function selectFilter(item) {
            Swal.fire({
                title: "Are you sure?",
                text: "The filter will be applied",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, apply it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('applied successfully');
                }
            })
        }

        function getSavedFilters() {
            console.log('getSavedFilters');
            total_items.value = saved_filters.value.length;
        }

        function changePage() {
            getSavedFilters();
        }

        function deleteFilter(id) {
            //console.log('deleteFilter');
            Swal.fire({
                title: "Are you sure?",
                text: "The filter will be deleted",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    console.log('deleted');
                }
            })
        }

        onMounted(() => {
            getSavedFilters();
        })

        return {
            close,
            selectFilter,
            closeModal,
            saved_filters,
            route,
            page,
            total_items,
            changePage,
            deleteFilter,
            save_filter
        }
    }
})
