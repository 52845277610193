
import { defineComponent } from "vue";
import { QrcodeStream } from "vue3-qrcode-reader";

export default defineComponent({
  components: {
    QrcodeStream,
  },
  setup() {
    function onDecode(url: string) {
      console.log(url);
      window.location.href = url;
    }
    function onInit(promise: any) {
      promise.then(console.log).catch(console.error);
    }
    return {
      onDecode,
      onInit,
    };
  },
});
