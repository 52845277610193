import axios from "@/plugins/axios";

const ExternalServices = {
    newService(id,form){
        return new Promise((resolve, reject) => {
            axios.patch(`shops/${id}`, form)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });  
    },
    getServiceDetails(id){
        return new Promise((resolve, reject) => {
            axios.get(`/shops/external-services/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    },
    getExternalServicesProducts(id){
        return new Promise((resolve, reject) => {
            axios.get(`/shops/external-services-products/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    }
}

export default ExternalServices;