<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46.516"
    height="46.579"
    viewBox="0 0 46.516 46.579"
  >
    <g
      id="Groupe_105"
      data-name="Groupe 105"
      transform="translate(-803.878 1196)"
    >
      <path
        id="Tracé_45"
        data-name="Tracé 45"
        d="M869.362-1148.376h-9.045v-39.344h9.045Z"
        transform="translate(-37.663 -5.525)"
        fill="#fff"
      />
      <path
        id="Tracé_46"
        data-name="Tracé 46"
        d="M829.422-1126.482h-9.086c-.033-.57-.091-1.1-.092-1.64-.006-8.372.022-16.744-.032-25.115-.008-1.3.339-1.747,1.674-1.692,2.477.1,4.96.029,7.536.029Z"
        transform="translate(-10.9 -27.404)"
        fill="#fff"
      />
      <path
        id="Tracé_47"
        data-name="Tracé 47"
        d="M900.2-1149.926h9v26.772h-9Z"
        transform="translate(-64.277 -30.746)"
        fill="#fff"
      />
      <path
        id="Tracé_48"
        data-name="Tracé 48"
        d="M806.16-1151.7h2.04q19.877,0,39.754.02c.963,0,2.456-.424,2.44,1.159-.015,1.469-1.445,1.048-2.363,1.049q-20.875.029-41.75.013c-2.282,0-2.36-.077-2.361-2.328q0-20.958,0-41.917c0-.333-.109-.725.029-.985a9.423,9.423,0,0,1,.994-1.307,6.138,6.138,0,0,1,1.164,1.322,3.727,3.727,0,0,1,.053,1.478q0,19.628,0,39.255Z"
        transform="translate(0 0)"
        fill="#fff"
      />
    </g>
  </svg>
</template>