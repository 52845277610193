import axios from "@/plugins/axios";

const AccountRequest = {
    editUserInfo(id, form){
        return new Promise((resolve, reject) => {
            axios.patch(`users/${id}`, form)
            .then((response) => resolve(response))
            .catch((error) => reject(error))
        })
    },

    getUserInfo(id){
        return new Promise((resolve, reject) => {
            axios.get(`users/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error))
        })
    },
    getUserLogistic(id){
        return new Promise((resolve, reject) => {
            axios.get(`users/logistic/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error))
        })
    }
}

export default AccountRequest;