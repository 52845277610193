
import { computed, defineComponent, onMounted, ref } from "vue";
import PackSummary from "@/components/PackComponents/PackSummary.vue";
import PackHistory from "@/components/PackComponents/PackHistory.vue";
import PackProducts from "@/components/PackComponents/PackProducts.vue";

export default defineComponent({
    components: {
        PackSummary,
        PackHistory,
        PackProducts
    },
    setup() {
        const tabs = ['Details', 'Products','Changes history'];
        const activeTab = ref(0);
        const tabComponents = computed(() => {
            return [PackSummary, PackProducts, PackHistory];
        });

        onMounted(() => {
            window.scrollTo(0, 0);
        });
        return {
            tabs,
            activeTab,
            tabComponents
        };
    },
});
