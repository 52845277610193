
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent, reactive, ref } from 'vue'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

export default defineComponent({
    components: {
        vSelect,
        CloseIcon,
        Datepicker
    },
    props: {
        id: String
    },
    setup() {

        const form = reactive({
            type: "",
            start_date: "",
            end_date: ""
        });

        const types = ref([
            {
                id: 1,
                value: 'Commandes expédiées'
            },
            {
                id: 2,
                value: 'Produits'
            },
            {
                id: 3,
                value: 'Bordereau de remise'
            },
        ]);
        const rules = reactive({
            type: { required },
            start_date: { required },
            end_date: { required }
        })

        const v$ = useVuelidate(rules, form);

        function submit() {
            console.log('Submit');
        }

        return {
            form,
            types,
            v$,
            submit
        }
    }
})
