
import { computed, defineComponent, inject, onMounted} from "vue";
export default defineComponent({
  setup(){

    onMounted(() => {
     console.log("password")
    });

    return{

    }
  }
})
