
import { defineComponent, onMounted, ref } from "vue";
import Table from "@/components/Table.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import PrepWcondModal from "@/components/PrepWcondModal.vue";
import PrepAllPendOrdersModal from "@/components/PrepAllPendOrdersModal.vue";
import { useNotification } from "@kyvg/vue3-notification";
import PreparationRequest from "@/services/requests/preparation";
import Swal from "sweetalert2";
import { BIconDownload, BIconTrash } from "bootstrap-icons-vue";

export default defineComponent({
  components: {
    Table,
    AddIcon,
    SearchIcon,
    PrepWcondModal,
    PrepAllPendOrdersModal,
    BIconDownload,
    BIconTrash
  },
  setup() {
    const nodeEnv = process.env.NODE_ENV;
    const params = ref('');
    const notification = useNotification();
    const preparation_loading = ref(false);
    const url = ref(`preparations`);
    const page = ref(1);
    const datatable = ref();
    const search_query = ref('');
    const headers = ref([
      { value: 'id', text: 'id', show: true, order: 1, type: "input" },
      { value: 'pending_orders', text: 'orders in preparation', show: true, order: 2, type: "input" },
      { value: 'state', text: 'state', show: true, order: 3, type: "input" },
      { value: 'preparator', text: 'preparator', show: true, order: 4, type: "input" },
      { value: 'created_at', text: 'date', show: true, order: 5, type: "input" }
    ]);
    const rows = ref([
      {
        id: 145233,
        orders_in_preparation: '25',
        state: 'in preparation',
        picker: 'john doe',
        date: '28/02/2022',
      },
      {
        id: 145233,
        orders_in_preparation: '25',
        state: 'in preparation',
        picker: 'john doe',
        date: '28/02/2022',
      },
      {
        id: 145233,
        orders_in_preparation: '25',
        state: 'in preparation',
        picker: 'john doe',
        date: '28/02/2022',
      },

    ]);
    const actions = ref(true);
    const pending_orders_count = ref(0);
    function getPageNumber(value) {
      page.value = value;
    }

    function prepareOrder() {
      var form = {
        state: 1
      }
      if(pending_orders_count.value > 0) {
        preparation_loading.value = true;
        PreparationRequest.prepareOrder(form)
        .then(() => {
          preparation_loading.value = false
          notification.notify({
            title: "Preparation successful! Pending orders have been prepared.",
            type: 'success'
          });
          datatable.value.loadData();
          getPendingOrdersCount();
        }).catch((error) => preparation_loading.value = false)
      }else{
        notification.notify({
          title: "No pending orders available for preparation",
          type: 'error'
        });
      }
      
    }

    function deletePreparation(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "The preparation will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          PreparationRequest.deletePreparation(id).then((response) => {
            notification.notify({
              title: "Preparation successfully deleted",
              type: 'success'
            });
            datatable.value.loadData();
          })
        }
      })
    }

    function downloadPreparation(id) {
      /*const apiUrl =
          nodeEnv === 'development'
            ? 'http://localhost:3000/packing-slip/' + id
            : nodeEnv === 'production'
              ? 'https://logimk.fr/packing-slip/' + id
              : '';*/

      PreparationRequest.downloadPreparation(id).then((response: any) => {

        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `order-preparation-${id}.pdf`;
        link.click();
      })
    }
    function getPendingOrdersCount(){
      PreparationRequest.getPendingOrdersCount()
      .then((response: any)=> {
        pending_orders_count.value = response.data.data.totalCount;
      })
    }
    function configConfirmed(new_config){
      //console.log('Config confirmed', new_config)
      headers.value = new_config;
    }
    onMounted(() => {
      getPendingOrdersCount();
      window.scrollTo(0, 0);
    })
    return {
      headers,
      rows,
      actions,
      getPageNumber,
      params,
      notification,
      url,
      datatable,
      search_query,
      prepareOrder,
      deletePreparation,
      downloadPreparation,
      pending_orders_count,
      preparation_loading,
      configConfirmed
    };
  },
});
