
import { computed, defineComponent, inject, onMounted, ref, watch} from "vue";
import General from '@/components/ConfigurationComponents/Account/General.vue';
import Password from '@/components/ConfigurationComponents/Account/Password.vue';
import Douanes from '@/components/ConfigurationComponents/Account/Douanes.vue';
import Invoices from '@/components/ConfigurationComponents/Account/Invoices.vue';
import InvoiceAddress from '@/components/ConfigurationComponents/Account/InvoiceAddress.vue';
export default defineComponent({
  components:{
    General,
    Password,
    Douanes,
    Invoices,
    InvoiceAddress
  },
  setup() {
    const tabs = ['general', 'password', 'douanes', 'invoices', 'invoice-address'];
    const activeTab = ref(0);
    const tabComponents = computed(() => {
      return [General, Password, Douanes, Invoices, InvoiceAddress];
    });
   
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      tabs,
      activeTab,
      tabComponents
    };
  },
});
