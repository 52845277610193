import { Plugin } from 'vue'
import axios, { AxiosInstance } from 'axios'
import Cookies from 'js-cookie';

const nodeEnv = process.env.NODE_ENV;

// Create a new instance of Axios
const instance: AxiosInstance = axios.create({
  baseURL: nodeEnv == 'development' ? 'http://localhost:3000/api/' : nodeEnv == 'production' ? 'https://logimk.fr/api/' : '',
  headers: {
    'Content-Type': 'application/json',
    'Authorization': Cookies.get('access_token') ? 'Bearer '+Cookies.get('access_token') : '' 
  }
})

// Define the plugin
const axiosPlugin: Plugin = {
  install(app) {
    // Inject the Axios instance into the app context
    app.provide<AxiosInstance>('axios', instance)
  }
}

export default instance;