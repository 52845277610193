
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
import AddIcon from "@/components/icons/AddIcon.vue";
import NewReturnedOrder from '../NewReturnedOrder.vue';
export default defineComponent({
    components: {
        VPagination,
        AddIcon,
        NewReturnedOrder
    },
    setup() {
        const page = ref(1);
        const total_items = ref(0);
        const route = useRoute();
        const returned_order_modal = ref(false);
        const returned_products = ref([
            {
                _id: '125478',
                order_init: 'XXXXXXXX',
                reason: 'XXXXXXXX',
                reason_ref: 'XXXXXXXX',
                back_in_stock_on: 'XXXXXXXX',
                created_at: 'XXXXXXXX'
            },
            {
                _id: '2569854',
                order_init: 'XXXXXXXX',
                reason: 'XXXXXXXX',
                reason_ref: 'XXXXXXXX',
                back_in_stock_on: 'XXXXXXXX',
                created_at: 'XXXXXXXX'
            },
            {
                _id: '36587452',
                order_init: 'XXXXXXXX',
                reason: 'XXXXXXXX',
                reason_ref: 'XXXXXXXX',
                back_in_stock_on: 'XXXXXXXX',
                created_at: 'XXXXXXXX'
            }
        ]);

        function getChangesHistory() {
            /*OrderRequest.getChangesHistory(route.params.id, page.value)
            .then((response: any) => {
                changes_history.value = response.data.data.data_array;
                total_items.value = parseInt(response.data.data.totalCount);
            })
            .catch((error) => {
                console.log(error);
            })*/
            console.log('get returned orders');
        }

        function changePage() {
            getChangesHistory();
        }
        return {
            returned_products,
            page,
            total_items,
            route,
            changePage,
            returned_order_modal
        }
    }
})
