
import { defineComponent, ref, onMounted} from "vue";
import Table from "@/components/Table.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import AnswerModal from "@/components/AnswerModal.vue";
export default defineComponent({
  components: {
    Table,
    SearchIcon,
    AnswerModal,
  },
  setup() {
    const headers = ref([
      { value: "id", text: "id" },
      { value: "shop", text: "shop" },
      { value: "email", text: "email" },
      { value: "last_answer", text: "last answer" },
    ]);
    const rows = ref([
      {
        id: 145233,
        shop: "shop name",
        email: "shopname@example.com",
        last_answer: "April 21,2011 at 9:12PM 6 hours ago",
      },
      {
        id: 145233,
        shop: "shop name",
        email: "shopname@example.com",
        last_answer: "April 21,2011 at 9:12PM 6 hours ago",
      },
      {
        id: 145233,
        shop: "shop name",
        email: "shopname@example.com",
        last_answer: "April 21,2011 at 9:12PM 6 hours ago",
      },
    ]);
    const actions = ref(true);
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      headers,
      rows,
      actions,
    };
  },
});
