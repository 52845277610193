
import { computed, defineComponent, onMounted, reactive, ref} from "vue";
import OrderDetails from '@/components/OrderComponents/OrderDetails.vue';
import OrderDocument from '@/components/OrderComponents/OrderDocument.vue';
import OrderChangesHistory from '@/components/OrderComponents/OrderChangesHistory.vue';
import OrderShipmentHistory from "@/components/OrderComponents/OrderShipmentHistory.vue";
import OrderSplitting from "@/components/OrderComponents/OrderSplitting.vue";
import ReturnedOrder from "@/components/OrderComponents/ReturnedOrder.vue";
import SubOrders from "@/components/OrderComponents/SubOrders.vue";
import Messages from "@/components/OrderComponents/Messages.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  components:{
    OrderDetails,
    OrderDocument,
    OrderChangesHistory,
    OrderShipmentHistory,
    OrderSplitting,
    ReturnedOrder,
    SubOrders,
    Messages
  },
  setup() {
    const route = useRoute();
    const order = ref({});
    const tabs = ['Details', 'Documents', 'Changes History', 'Shipment History', 'Order Splitting','Returned Order','Sub Orders','Messages'];
    const activeTab = ref(0);
    const tabComponents = computed(() => {
      return [OrderDetails, OrderDocument, OrderChangesHistory, OrderShipmentHistory, OrderSplitting,ReturnedOrder,SubOrders,Messages];
    });
    
    onMounted(() => {
     // getOrder();
      window.scrollTo(0, 0);
    });
    return {
      route,
      order,
      tabs,
      activeTab,
      tabComponents,
      //getOrder
    };
  },
});
