
import PackRequest from '@/services/requests/packs';
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
export default defineComponent({
    components:{
        VPagination
    },
    setup(){
        interface auditEntries {
            field: string,
            before: string,
            after: string,
            date: string,
            doneby: string
        }
        const page = ref(1);
        const total_items = ref(0);
        const route = useRoute();
        const changes_history = ref<auditEntries[]>();
        function getChangesHistory(){
            PackRequest.getChangesHistory(route.params.id, page.value)
            .then((response: any) => {
                changes_history.value = response.data.data.data_array;
                total_items.value = parseInt(response.data.data.totalCount);
            })
            .catch((error) => {
                console.log(error);
            })
        }

        function changePage(){
            getChangesHistory();
        }

        onMounted(() => {
          getChangesHistory();
        })
        return {
            changes_history,
            getChangesHistory,
            page,
            total_items,
            changePage
        }
    }
});
