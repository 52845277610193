<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28.862"
    height="38.228"
    viewBox="0 0 40.568 53.733"
  >
    <g
      id="Groupe_451"
      data-name="Groupe 451"
      transform="translate(-792.585 1608.516)"
    >
      <path
        id="Tracé_87"
        data-name="Tracé 87"
        d="M833.153-1539.3H792.585a29.309,29.309,0,0,0,6.711-12.62,50.717,50.717,0,0,0,1.666-13.917,11.792,11.792,0,0,1,9.972-11.857,11.787,11.787,0,0,1,13.669,11.554,48.358,48.358,0,0,0,2.408,16.282,29.5,29.5,0,0,0,5.692,10C832.826-1539.721,832.936-1539.571,833.153-1539.3Z"
        transform="translate(0 -23.415)"
        fill="#192440"
      />
      <path
        id="Tracé_88"
        data-name="Tracé 88"
        d="M851.07-1405.273h12.676c-.093,2.971-2.944,5.536-6.144,5.635A6.419,6.419,0,0,1,851.07-1405.273Z"
        transform="translate(-44.645 -155.149)"
        fill="#192440"
      />
      <path
        id="Tracé_89"
        data-name="Tracé 89"
        d="M869.874-1603.463a2.556,2.556,0,0,1-2.568-2.417,2.552,2.552,0,0,1,2.465-2.636,2.551,2.551,0,0,1,2.569,2.413A2.565,2.565,0,0,1,869.874-1603.463Z"
        transform="translate(-57.038 0)"
        fill="#192440"
      />
    </g>
  </svg>
</template>