
import Footer from "@/components/Footer.vue";
import { defineComponent, inject, onMounted, reactive, ref} from "vue";
import ProfileIcon from "@/components/icons/ProfileIcon.vue";
import PadlockIcon from "@/components/icons/PadlockIcon.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie'
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import authRequest from "@/services/requests/auth";
import axios from "@/plugins/axios";
import { BIconX, BIconExclamationTriangleFill } from "bootstrap-icons-vue";
export default defineComponent({
  components: { Footer, ProfileIcon, PadlockIcon,BIconX,BIconExclamationTriangleFill },
  name: "Login",
  setup() {
    const store = useStore(); 
    const router = useRouter();
    const disabled = ref(false);
    const form = reactive({
      email: "",
      password: ""
    });
    const rules = {
      email: { required },
      password: { required }
    }
    const v$ = useVuelidate(rules, form);
    const error_alert = ref(false);
    const error_message = ref('');
    function login(){
      v$.value.$validate();
      if (!v$.value.$error) {
        disabled.value = true;
        authRequest.login(form).then((response: any) => {
          const decoded = jwt.decode(response.data.token);
          Cookies.set('access_token', response.data.token, { expires: decoded.exp })
          store.commit('setUser', decoded);
          disabled.value = false;
          axios.defaults.headers['Authorization'] = `Bearer ${Cookies.get('access_token')}`;
          router.push({name: 'Home'});
          
        }).catch((error) => {
          disabled.value = false;
          error_alert.value = true;
          error_message.value = error.response.data.message 
        })
      }  
      
    }
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      form,
      login,
      v$,
      store,
      router,
      disabled,
      error_alert,
      error_message
    };
  },
});
