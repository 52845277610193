

import { defineComponent, ref } from 'vue';
import Table from "@/components/Table.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { useStore } from "vuex";
import { useNotification } from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
import NewTransporter from "@/components/ConfigurationComponents/Transporters/NewTransporter.vue";
import TrasportersRequests from '@/services/requests/transporters';
import {
    BIconCheckLg,
    BIconXLg,
    BIconClock,
    BIconPencilSquare,
    BIconTrash,
    //BIconUpload
} from "bootstrap-icons-vue";
export default defineComponent({
    components: {
        Table,
        AddIcon,
        SearchIcon,
        NewTransporter,
        BIconPencilSquare,
        BIconTrash,
        BIconCheckLg,
        BIconXLg,
        BIconClock,
    },
    setup() {
        const store = useStore();
        const datatable = ref();
        const url = ref('transporters');
        const params = ref('');
        const headers = ref([
            { value: "service", text: "transporter", show: true, order: 1, type: "input" },
            { value: "api_connexion", text: 'Api Connexion', show: true, order: 2, type: "input"}
        ]);
        const notification = useNotification();
        const actions = ref(true);
        const rows = ref([
            {
                _id: 1,
                transporter: "Dhl - DHL by 12plus"
            },
            {
                _id: 2,
                transporter: "GlsByApi - GLS Standard",
            },
        ]);
        function configConfirmed(new_config) {
            headers.value = new_config;
        }

        function loadData() {
            datatable.value.loadData();
        }

        function service(service) {
            var service_name = ''
            switch (service) {
                case 1:
                    service_name = 'GLS'
                    break;
                case 2:
                    service_name = 'Mondial Relay'
                    break;    

                default:
                    break;
            }

            return service_name;
        }

        function deleteTransporter(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "The Transporter will be deleted",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    TrasportersRequests.deleteTransporter(id)
                        .then((response) => {
                            notification.notify({
                                title: "Transporter successfully deleted",
                                type: "success"
                            });
                            loadData();
                        })
                }
            })
        }

        return {
            store,
            datatable,
            url,
            params,
            headers,
            configConfirmed,
            loadData,
            notification,
            actions,
            deleteTransporter,
            rows,
            service
        }
    }
})

