<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="20.8"
    height="24.25"
    viewBox="0 0 256 256"
    xml:space="preserve"
    fill="#9D9D9D"
  >
    <defs></defs>
    <g transform="translate(128 128) scale(0.72 0.72)" style="">
      <g
        style="
          stroke: none;
          stroke-width: 0;
          stroke-dasharray: none;
          stroke-linecap: butt;
          stroke-linejoin: miter;
          stroke-miterlimit: 10;
          fill: #9D9D9D;
          fill-rule: nonzero;
          opacity: 1;
        "
        transform="translate(-175.05 -175.05000000000004) scale(3.89 3.89)"
      >
        <path
          d="M 66.611 32.961 c -1.657 0 -3 -1.343 -3 -3 v -5.349 C 63.611 14.349 55.263 6 45 6 s -18.612 8.349 -18.612 18.612 v 5.349 c 0 1.657 -1.343 3 -3 3 s -3 -1.343 -3 -3 v -5.349 C 20.388 11.041 31.429 0 45 0 c 13.57 0 24.611 11.041 24.611 24.612 v 5.349 C 69.611 31.618 68.269 32.961 66.611 32.961 z"
          style="
            stroke: none;
            stroke-width: 1;
            stroke-dasharray: none;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 10;
            fill: #9D9D9D;
            fill-rule: nonzero;
            opacity: 1;
          "
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
        <path
          d="M 74.637 37.646 H 15.363 c -1.657 0 -3 1.343 -3 3 v 16.717 C 12.363 75.359 27.004 90 45 90 s 32.637 -14.641 32.637 -32.637 V 40.646 C 77.637 38.989 76.294 37.646 74.637 37.646 z M 48 64.207 v 7.853 c 0 1.657 -1.343 3 -3 3 c -1.657 0 -3 -1.343 -3 -3 v -7.853 c -2.079 -1.084 -3.5 -3.256 -3.5 -5.763 c 0 -3.59 2.91 -6.5 6.5 -6.5 s 6.5 2.91 6.5 6.5 C 51.5 60.951 50.079 63.123 48 64.207 z"
          style="
            stroke: none;
            stroke-width: 1;
            stroke-dasharray: none;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 10;
            fill: #9D9D9D;
            fill-rule: nonzero;
            opacity: 1;
          "
          transform=" matrix(1 0 0 1 0 0) "
          stroke-linecap="round"
        />
      </g>
    </g>
  </svg>
</template>