<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.19"
    height="20.19"
    viewBox="0 0 28.393 28.393"
  >
    <g
      id="Groupe_110"
      data-name="Groupe 110"
      transform="translate(-1055.5 1084.229)"
    >
      <g
        id="Groupe_106"
        data-name="Groupe 106"
        transform="translate(1055.5 -1084.229)"
      >
        <path
          id="Tracé_49"
          data-name="Tracé 49"
          d="M1069.1-1070.633h-13.6v-13.6h13.6Zm-11.6-2h9.6v-9.6h-9.6Z"
          transform="translate(-1055.5 1084.229)"
          fill="currentColor"
        />
      </g>
      <g
        id="Groupe_107"
        data-name="Groupe 107"
        transform="translate(1070.296 -1084.229)"
      >
        <path
          id="Tracé_50"
          data-name="Tracé 50"
          d="M1106.1-1070.633h-13.6v-13.6h13.6Zm-11.6-2h9.6v-9.6h-9.6Z"
          transform="translate(-1092.5 1084.229)"
          fill="currentColor"
        />
      </g>
      <g
        id="Groupe_108"
        data-name="Groupe 108"
        transform="translate(1055.5 -1069.433)"
      >
        <path
          id="Tracé_51"
          data-name="Tracé 51"
          d="M1069.1-1033.633h-13.6v-13.6h13.6Zm-11.6-2h9.6v-9.6h-9.6Z"
          transform="translate(-1055.5 1047.229)"
          fill="currentColor"
        />
      </g>
      <g
        id="Groupe_109"
        data-name="Groupe 109"
        transform="translate(1070.296 -1069.433)"
      >
        <path
          id="Tracé_52"
          data-name="Tracé 52"
          d="M1106.1-1033.633h-13.6v-13.6h13.6Zm-11.6-2h9.6v-9.6h-9.6Z"
          transform="translate(-1092.5 1047.229)"
          fill="currentColor"
        />
      </g>
    </g>
  </svg>
</template>