<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="94.555"
    height="74.172"
    viewBox="0 0 81.555 64.172"
  >
    <g
      id="Groupe_454"
      data-name="Groupe 454"
      transform="translate(-49.71 -129.77)"
    >
      <path
        id="Tracé_974"
        data-name="Tracé 974"
        d="M49.71,130.768c.519-.329,1.032-.934,1.557-.945,3.964-.08,7.931-.044,12.006-.044v2.982H52.907v8.364h-3.2Z"
        fill="#192440"
      />
      <path
        id="Tracé_975"
        data-name="Tracé 975"
        d="M49.71,182.55H52.8V190.8h10.49v3.1H56.1a37.412,37.412,0,0,1-4.185-.044,8.159,8.159,0,0,1-2.205-.953Z"
        fill="#192440"
      />
      <path
        id="Tracé_976"
        data-name="Tracé 976"
        d="M131.265,141.125h-3.088V132.9h-9.742v-3.122c3.781,0,7.529-.038,11.274.044.525.012,1.038.616,1.556.945Z"
        fill="#192440"
      />
      <path
        id="Tracé_977"
        data-name="Tracé 977"
        d="M131.265,192.906c-.626.332-1.245.935-1.879.951-3.6.092-7.2.045-10.933.045v-3.016h9.612V182.55h3.2Z"
        fill="#192440"
      />
      <path
        id="Tracé_978"
        data-name="Tracé 978"
        d="M61.763,140.53h5.2v42.556h-5.2Z"
        fill="#192440"
      />
      <path
        id="Tracé_979"
        data-name="Tracé 979"
        d="M109.72,183.146H104.5v-42.57h5.223Z"
        fill="#192440"
      />
      <path
        id="Tracé_980"
        data-name="Tracé 980"
        d="M78.183,183.062V140.581h5.212v42.481Z"
        fill="#192440"
      />
      <path
        id="Tracé_981"
        data-name="Tracé 981"
        d="M119.248,140.565v42.516h-4.7V140.565Z"
        fill="#192440"
      />
      <path
        id="Tracé_982"
        data-name="Tracé 982"
        d="M99.571,140.581v42.512H95.92V140.581Z"
        fill="#192440"
      />
      <path
        id="Tracé_983"
        data-name="Tracé 983"
        d="M91.017,183.126H88.294V140.549h2.723Z"
        fill="#192440"
      />
      <path
        id="Tracé_984"
        data-name="Tracé 984"
        d="M71.814,140.526h1.447V183.1l-1.447.108Z"
        fill="#192440"
      />
    </g>
  </svg>
</template>