
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from "vue";
import GetIcon from "@/components/icons/GetIcon.vue";
import ImportIcon from "@/components/icons/ImportIcon.vue";

import AddIcon from "@/components/icons/AddIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import useVuelidate from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";
import { ValidateEach } from "@vuelidate/components";
import { BIconExclamationTriangleFill, BIconX } from "bootstrap-icons-vue";
import OrderRequest from "@/services/requests/order";
import ShopRequest from "@/services/requests/shop";
import { useNotification } from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import NewDeliveryDocument from "@/components/NewDeliveryDocument.vue";
import { countries } from "@/data";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default defineComponent({
  components: {
    GetIcon,
    ImportIcon,
    AddIcon,
    TrashIcon,
    BIconExclamationTriangleFill,
    BIconX,
    ValidateEach,
    vSelect,
    NewDeliveryDocument
  },
  setup() {
    interface Product {
      _id: string,
      sku: string,
      unit_price: string,
      quantity: string
    }
    interface Service {
      name: string;
      service: number | null;
    }
    /*interface Service {
      _id: string,
      transporter_services: Array<any>
    }*/
    const countries_data = ref(countries)
    const validate_each = ref();
    const delivery_doc_component = ref(false);
    const notification = useNotification();
    const router = useRouter();
    const error_alert = ref(false);
    const error_message = ref('');
    const shops = ref();
    const fileInput = ref();
    const store = useStore();
    const services = ref<Service[]>([]);
    const formatted_services = computed(() => {
      return services.value.map((item) => ({
        name: serviceName(item.service),
        service: item.service
      }))
    })
    const form = reactive({
      reference: "",
      shopIds: [],
      delivery_mode: 0,
      product_entries: [] as Product[],
      address: {
        street: "",
        street2: "",
        street3: "",
        zip_code: "",
        city: "",
        country: "",
        additional_info: "",
        state: "",
        pick_up_reference: ""
      },
      recipient: {
        firstname: "",
        lastname: "",
        company: "",
        phone: "",
        phone2: "",
        civility: "",
        email: ""
      }
    });
    const rules = {
      reference: { required },
      shopIds: { required }
      /*delivery_mode: { required },
      address: {
        street: { required },
        zip_code: { required },
        city: { required },
        country: { required },
        state: { required },
        pick_up_reference: { required }
      },
      recipient: {
        firstname: { required },
        lastname: { required },
        company: { required },
        phone: { required },
        civility: { required },
        email: { required, email }
      },*/
    };
    const product_entries_rules = {
      sku: { required },
      unit_price: "",
      quantity: { required },
    };
    const selectedFiles = ref<any>([]);
    const v$ = useVuelidate(rules, form);
    function generateRandomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    }
    function handleFileChange(event) {
      const files = Array.from(event.target.files);
      files.forEach((item: any) => {
        selectedFiles.value.push(item)
      })
      // selectedFiles.value = Array.from(event.target.files);
    }
    function addNewDeliveryDocument(event) {
      if (event) {
        selectedFiles.value.push(event);
      }
    }
    function addProduct() {
      form.product_entries.push({
        _id: generateRandomString(20),
        sku: "",
        unit_price: "",
        quantity: "",
      });
    }
    function deleteProduct(index) {
      if (form.product_entries.length > 1) {
        form.product_entries.splice(index, 1);
        /* validate_each.value.__vuelidateRemoveInstances[0].removeChildResultsFromParent(index);
         console.log(validate_each.value);*/
      }
    }
    function deleteDocument(index) {
      selectedFiles.value.splice(index, 1);
    }
    function AddNewOrder() {
      /*const closeSwal = new Promise<void>((resolve) => {
        Swal.close();
        resolve(); // Resolve the promise when loading is hidden
      });*/
      // Create a FormData object
      const formData = new FormData();

      // Append other form data to the FormData
      formData.append('reference', form.reference);
      formData.append('delivery_mode', String(form.delivery_mode));

      // Append shopIds as an array
      /*for (const shopId of form.shopIds) {
        formData.append('shopIds[]', shopId);
      }*/
      for (let i = 0; i < form.shopIds.length; i++) {
        formData.append(`shopIds[${i}]`, form.shopIds[i]);
      }

      // Append product entries as an array of objects
      /*for (const product of form.product_entries) {
        // Assuming product is an object with properties like 'name' and 'price'
        formData.append('product_entries[][sku]', product.sku);
        formData.append('product_entries[][unit_price]', product.unit_price);
        formData.append('product_entries[][quantity]', product.quantity);
      }*/
      for (let i = 0; i < form.product_entries.length; i++) {
        const product = form.product_entries[i];
        formData.append(`product_entries[${i}][sku]`, product.sku);
        formData.append(`product_entries[${i}][unit_price]`, product.unit_price.toString());
        formData.append(`product_entries[${i}][quantity]`, product.quantity.toString());
      }

      // Append the address object
      const address = form.address;
      for (const key in address) {
        //if (recipient.hasOwnProperty(key)) {
        formData.append(`address[${key}]`, address[key]);
        //}
      }

      // Append the recipient object
      const recipient = form.recipient;
      for (const key in recipient) {
        //if (recipient.hasOwnProperty(key)) {
        formData.append(`recipient[${key}]`, recipient[key]);
        //}
      }
      // Append selected files (documents) to the FormData
      /*for (let i = 0; i < selectedFiles.value.length; i++) {
        formData.append(`delivery_document`, selectedFiles.value[i]);
      }*/
      for (let i = 0; i < selectedFiles.value.length; i++) {
        const doc = selectedFiles.value[i];
        formData.append(`delivery_document[${i}][type]`, doc.type);
        formData.append(`delivery_document[${i}][language]`, doc.language);
        formData.append(`document`, doc.document);
      }

      //return;
      v$.value.$validate();
      if (!v$.value.$error) {
        Swal.showLoading();
        OrderRequest.addOrder(formData)
          .then((response: any) => {
            if (response.data.success == true) {
              Swal.close();
              notification.notify({
                title: "Order successfully added",
                type: "success"
              });
              setTimeout(() => {
                router.push({ name: 'OrdersList' });
              }, 500);
            }
          })
          .catch((error) => {
            Swal.close();
            setTimeout(() => {
              error_message.value = error.response.data.message;
              error_alert.value = true;
              window.scrollTo(0, 0);
            }, 500)
          })
      } else {
        let animationFrameId;

        const animateScroll = () => {
          const errorElements = document.querySelectorAll('.item__error');
          if (errorElements.length > 0) {
            const firstErrorParent = errorElements[0].closest('.input-item');
            if (firstErrorParent) {
              firstErrorParent.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
            animationFrameId = requestAnimationFrame(animateScroll);
          }
        };
        animationFrameId = requestAnimationFrame(animateScroll);
      }
    }
    function getShops() {
      return new Promise((resolve, reject) => {
        ShopRequest.getShopsName()
          .then((response: any) => {
            shops.value = response.data.data;
            resolve(response)
          })
          .catch((error) => reject(error))
      })
    }
    function serviceName(key){
      switch (key) {
        case 0: 
          return 'Simple Letter'
        case 1:
          return 'GLS';
        case 2:
          return 'Mondial Relay';
        default:
          break;
      }
    }
    function getTransporters() {
      OrderRequest.getTransporters()
        .then((response: any) => {
          services.value = response.data.data;
          services.value.unshift({
            name: 'simple letter',
            service: 0
          })

        })
    }
    onMounted(() => {
      getTransporters();
      getShops().then(() => {
        if (Object.keys(store.state.duplicateOrder).length > 0) {
          //form.product_entries = [];
          for (const key in form) {
            if (Object.prototype.hasOwnProperty.call(store.state.duplicateOrder, key)) {
              form[key] = store.state.duplicateOrder[key];
            }
          }

          store.commit('clearDuplicateOrder');
        } else {
          addProduct();
        }
      });

      window.scrollTo(0, 0);
    });
    return {
      form,
      addProduct,
      deleteProduct,
      AddNewOrder,
      v$,
      error_alert,
      product_entries_rules,
      getShops,
      shops,
      store,
      error_message,
      handleFileChange,
      selectedFiles,
      fileInput,
      deleteDocument,
      addNewDeliveryDocument,
      delivery_doc_component,
      validate_each,
      generateRandomString,
      countries_data,
      services,
      formatted_services
    };
  },
});
