import axios from "@/plugins/axios";

const ProductRequest = {
    newProduct(form){
        return new Promise((resolve, reject) => {
            axios.post('products', form)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    },
    deleteProduct(id){
        return new Promise((resolve, reject) => {
            axios.delete(`products/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        })
    },
    getProductById(id){
        return new Promise((resolve, reject) =>{
            axios.get(`/products/${id}`)
            .then((response) => resolve(response))
            .catch((err) => reject(err))
        })
    },
    editProduct(id,form){
        return new Promise((resolve, reject) => {
            axios.patch(`/products/${id}`, form)
            .then((response) => resolve(response))
            .catch((err) => reject(err))
        })
    },
    getChangesHistory(id: any, page){
        return new Promise((resolve, reject) => {
            axios.get(`products/auditlogs/${id}?_page=${page}`)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
        }); 
    },
}

export default ProductRequest;