
import { computed, defineComponent, ref } from "vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
//import QrCodeIcon from "@/components/icons/QrCodeIcon.vue";
import { BIconDownload } from "bootstrap-icons-vue";
import Vue3Html2pdf from "vue3-html2pdf";
//import runSummary from "@/pdf-templates/runSummary.vue";
import packingSlip from "@/pdf-templates/packingSlip.vue";
export default defineComponent({
  props: {
    id: String,
  },
  components: {
    CloseIcon,
    //QrCodeIcon,
    BIconDownload,
    Vue3Html2pdf,
    //runSummary,
    packingSlip
  },
  setup() {
    const html2Pdf = ref();
    const htmlToPdfOptions = computed(() => {
      return {
        margin: 0,

        filename: `hehehe.pdf`,

        image: {
          type: "jpeg",
          quality: 1,
        },

        enableLinks: false,

        html2canvas: {
          scale: 1,
          useCORS: true,
        },

        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      };
    });
    function generateReport() {
      html2Pdf.value.generatePdf();
    }
    return {
      html2Pdf,
      generateReport,
      htmlToPdfOptions
    };
  },
});
