import axios from "@/plugins/axios";

const authRequest = {
    login(form){
        return new Promise((resolve, reject) => {
            axios?.post('auth/login', form)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
        })
    },
    register(form){
        return new Promise((resolve, reject) => {
            axios?.post('auth/signup', form)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
        })
    }
}

export default authRequest;