
const countries = [
    { alpha2: 'AF' },
    { alpha2: 'AL' },
    { alpha2: 'DZ' },
    { alpha2: 'AS' },
    { alpha2: 'AD' },
    { alpha2: 'AO' },
    { alpha2: 'AI' },
    { alpha2: 'AQ' },
    { alpha2: 'AG' },
    { alpha2: 'AR' },
    { alpha2: 'AM' },
    { alpha2: 'AW' },
    { alpha2: 'AU' },
    { alpha2: 'AT' },
    { alpha2: 'AZ' },
    { alpha2: 'BS' },
    { alpha2: 'BH' },
    { alpha2: 'BD' },
    { alpha2: 'BB' },
    { alpha2: 'BY' },
    { alpha2: 'BE' },
    { alpha2: 'BZ' },
    { alpha2: 'BJ' },
    { alpha2: 'BM' },
    { alpha2: 'BT' },
    { alpha2: 'BO' },
    { alpha2: 'BA' },
    { alpha2: 'BW' },
    { alpha2: 'BV' },
    { alpha2: 'BR' },
    { alpha2: 'IO' },
    { alpha2: 'BN' },
    { alpha2: 'BG' },
    { alpha2: 'BF' },
    { alpha2: 'BI' },
    { alpha2: 'KH' },
    { alpha2: 'CM' },
    { alpha2: 'CA' },
    { alpha2: 'CV' },
    { alpha2: 'KY' },
    { alpha2: 'CF' },
    { alpha2: 'TD' },
    { alpha2: 'CL' },
    { alpha2: 'CN' },
    { alpha2: 'CX' },
    { alpha2: 'CC' },
    { alpha2: 'CO' },
    { alpha2: 'KM' },
    { alpha2: 'CG' },
    { alpha2: 'CD' },
    { alpha2: 'CK' },
    { alpha2: 'CR' },
    { alpha2: 'CI' },
    { alpha2: 'HR' },
    { alpha2: 'CU' },
    { alpha2: 'CY' },
    { alpha2: 'CZ' },
    { alpha2: 'DK' },
    { alpha2: 'DJ' },
    { alpha2: 'DM' },
    { alpha2: 'DO' },
    { alpha2: 'EC' },
    { alpha2: 'EG' },
    { alpha2: 'SV' },
    { alpha2: 'GQ' },
    { alpha2: 'ER' },
    { alpha2: 'EE' },
    { alpha2: 'SZ' },
    { alpha2: 'ET' },
    { alpha2: 'FK' },
    { alpha2: 'FO' },
    { alpha2: 'FJ' },
    { alpha2: 'FI' },
    { alpha2: 'FR' },
    { alpha2: 'GF' },
    { alpha2: 'PF' },
    { alpha2: 'TF' },
    { alpha2: 'GA' },
    { alpha2: 'GM' },
    { alpha2: 'GE' },
    { alpha2: 'DE' },
    { alpha2: 'GH' },
    { alpha2: 'GI' },
    { alpha2: 'GR' },
    { alpha2: 'GL' },
    { alpha2: 'GD' },
    { alpha2: 'GP' },
    { alpha2: 'GU' },
    { alpha2: 'GT' },
    { alpha2: 'GG' },
    { alpha2: 'GN' },
    { alpha2: 'GW' },
    { alpha2: 'GY' },
    { alpha2: 'HT' },
    { alpha2: 'HM' },
    { alpha2: 'VA' },
    { alpha2: 'HN' },
    { alpha2: 'HK' },
    { alpha2: 'HU' },
    { alpha2: 'IS' },
    { alpha2: 'IN' },
    { alpha2: 'ID' },
    { alpha2: 'IR' },
    { alpha2: 'IQ' },
    { alpha2: 'IE' },
    { alpha2: 'IM' },
    { alpha2: 'IL' },
    { alpha2: 'IT' },
    { alpha2: 'JM' },
    { alpha2: 'JP' },
    { alpha2: 'JE' },
    { alpha2: 'JO' },
    { alpha2: 'KZ' },
    { alpha2: 'KE' },
    { alpha2: 'KI' },
    { alpha2: 'KP' },
    { alpha2: 'KR' },
    { alpha2: 'KW' },
    { alpha2: 'KG' },
    { alpha2: 'LA' },
    { alpha2: 'LV' },
    { alpha2: 'LB' },
    { alpha2: 'LS' },
    { alpha2: 'LR' },
    { alpha2: 'LY' },
    { alpha2: 'LI' },
    { alpha2: 'LT' },
    { alpha2: 'LU' },
    { alpha2: 'MO' },
    { alpha2: 'MK' },
    { alpha2: 'MG' },
    { alpha2: 'MW' },
    { alpha2: 'MY' },
    { alpha2: 'MV' },
    { alpha2: 'ML' },
    { alpha2: 'MT' },
    { alpha2: 'MH' },
    { alpha2: 'MQ' },
    { alpha2: 'MR' },
    { alpha2: 'MU' },
    { alpha2: 'YT' },
    { alpha2: 'MX' },
    { alpha2: 'FM' },
    { alpha2: 'MD' },
    { alpha2: 'MC' },
    { alpha2: 'MN' },
    { alpha2: 'ME' },
    { alpha2: 'MS' },
    { alpha2: 'MA' },
    { alpha2: 'MZ' },
    { alpha2: 'MM' },
    { alpha2: 'NA' },
    { alpha2: 'NR' },
    { alpha2: 'NP' },
    { alpha2: 'NL' },
    { alpha2: 'NC' },
    { alpha2: 'NZ' },
    { alpha2: 'NI' },
    { alpha2: 'NE' },
    { alpha2: 'NG' },
    { alpha2: 'NU' },
    { alpha2: 'NF' },
    { alpha2: 'MP' },
    { alpha2: 'NO' },
    { alpha2: 'OM' },
    { alpha2: 'PK' },
    { alpha2: 'PW' },
    { alpha2: 'PS' },
    { alpha2: 'PA' },
    { alpha2: 'PG' },
    { alpha2: 'PY' },
    { alpha2: 'PE' },
    { alpha2: 'PH' },
    { alpha2: 'PN' },
    { alpha2: 'PL' },
    { alpha2: 'PT' },
    { alpha2: 'PR' },
    { alpha2: 'QA' },
    { alpha2: 'RE' },
    { alpha2: 'RO' },
    { alpha2: 'RU' },
    { alpha2: 'RW' },
    { alpha2: 'BL' },
    { alpha2: 'SH' },
    { alpha2: 'KN' },
    { alpha2: 'LC' },
    { alpha2: 'MF' },
    { alpha2: 'PM' },
    { alpha2: 'VC' },
    { alpha2: 'WS' },
    { alpha2: 'SM' },
    { alpha2: 'ST' },
    { alpha2: 'SA' },
    { alpha2: 'SN' },
    { alpha2: 'RS' },
    { alpha2: 'SC' },
    { alpha2: 'SL' },
    { alpha2: 'SG' },
    { alpha2: 'SX' },
    { alpha2: 'SK' },
    { alpha2: 'SI' },
    { alpha2: 'SB' },
    { alpha2: 'SO' },
    { alpha2: 'ZA' },
    { alpha2: 'GS' },
    { alpha2: 'SS' },
    { alpha2: 'ES' },
    { alpha2: 'LK' },
    { alpha2: 'SD' },
    { alpha2: 'SR' },
    { alpha2: 'SJ' },
    { alpha2: 'SE' },
    { alpha2: 'CH' },
    { alpha2: 'SY' },
    { alpha2: 'TW' },
    { alpha2: 'TJ' },
    { alpha2: 'TZ' },
    { alpha2: 'TH' },
    { alpha2: 'TL' },
    { alpha2: 'TG' },
    { alpha2: 'TK' },
    { alpha2: 'TO' },
    { alpha2: 'TT' },
    { alpha2: 'TN' },
    { alpha2: 'TR' },
    { alpha2: 'TM' },
    { alpha2: 'TC' },
    { alpha2: 'TV' },
    { alpha2: 'UG' },
    { alpha2: 'UA' },
    { alpha2: 'AE' },
    { alpha2: 'GB' },
    { alpha2: 'US' },
    { alpha2: 'UM' },
    { alpha2: 'UY' },
    { alpha2: 'UZ' },
    { alpha2: 'VU' },
    { alpha2: 'VE' },
    { alpha2: 'VN' },
    { alpha2: 'VG' },
    { alpha2: 'VI' },
    { alpha2: 'WF' },
    { alpha2: 'EH' },
    { alpha2: 'YE' },
    { alpha2: 'ZM' },
    { alpha2: 'ZW' }
  ];
  
  export { countries };
  