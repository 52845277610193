import axios from "@/plugins/axios";
const TrasportersRequests = {
    newTrasnporter(id,form){
        return new Promise((resolve, reject) => {
            axios.post(`transporters`, form)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });  
    },
    deleteTransporter(id){
        return new Promise((resolve, reject) => {
            axios.delete(`transporters/${id}`,)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });  
    },
    editTransporter(id, form){
        return new Promise((resolve, reject) => {
            axios.patch(`transporters/${id}`, form)
            .then(res => resolve(res))
            .catch(err => reject(err))
        });
    },
    getTransporterDetails(id){
        return new Promise((resolve, reject) => {
            axios.get(`transporters/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    }
}

export default TrasportersRequests;