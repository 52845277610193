
import { defineComponent, reactive, ref } from "vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import vSelect  from "vue-select";
import "vue-select/dist/vue-select.css";
import ExternalServices from "@/services/requests/external-services";
import {useStore} from "vuex";

export default defineComponent({
  props: {
    id: String,
  },
  components: {
    CloseIcon,
    vSelect
  },
  setup(props, { emit }) {
    const close = ref();
    const store = useStore();
    const form = reactive({
      name: '',
      service: ''
    })
    const shop_id = ref(store.state.user.firstShop);
    const rules = reactive({
        name: { required },
        service: { required }
    })
    const v$ = useVuelidate(rules, form);
    const services = ref([
      {
        id: 1,
        value: 'Woocommerce'
      },
      {
        id: 2,
        value: 'Shopify'
      },
      {
        id: 3,
        value: 'Amazon'
      },
      {
        id: 4,
        value: 'Prestashop'
      },
      {
        id: 5,
        value: 'Magento'
      },
      {
        id: 6,
        value: 'BigCommerce'
      },
      {
        id: 7,
        value: 'Wizishop'
      },
      {
        id: 8,
        value: 'AliExpress'
      },
      {
        id: 9,
        value: 'Cdiscount'
      },
      {
        id: 10,
        value: 'Rakuten'
      }
    ]);
    function submit(){
      v$.value.$validate();
      if (!v$.value.$error) {
        const external_services: any = [];
        external_services.push(form)
        /*formData.append(`external_services[0][name]`, form.name);
        formData.append(`external_services[0][service]`, form.service);*/
        ExternalServices.newService(shop_id.value, {
          external_services: external_services
        })
        .then((response: any) => {
          emit('loadData');
          close.value.click();
        })
        .catch((error :any) => {
          console.log(error);
        })
      }
    }
    return {
      form,
      rules,
      v$,
      submit,
      services,
      shop_id,
      close
    };
  },
});
