
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
//import CheckIcon from "@/components/icons/CheckIcon.vue";
import OrderRequest from "@/services/requests/order";
import { useRoute } from "vue-router";
import _ from "lodash"; // Import Lodash
import useVuelidate from "@vuelidate/core";
import { ValidateEach } from "@vuelidate/components";
import { required } from "@vuelidate/validators";
import { useNotification } from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import AddIcon from "@/components/icons/AddIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";
import { BIconX, BIconExclamationTriangleFill } from "bootstrap-icons-vue";
import { countries } from "@/data";
import TrackingNumberModal from "../TrackingNumberModal.vue";

export default defineComponent({

  components: {
    //CheckIcon,
    "v-select": VueSelect,
    ValidateEach,
    AddIcon,
    TrashIcon,
    BIconX,
    BIconExclamationTriangleFill,
    TrackingNumberModal
  },
  setup() {
    const tracking_number_modal = ref(false);
    const countries_data = ref(countries)
    const nodeEnv = process.env.NODE_ENV;

    interface Product {
      _id: string,
      sku: string,
      unit_price: number | string,
      quantity: number | string
    }

    interface Service {
      name: string;
      service: number | null;
    }

    const store = useStore();
    const notification = useNotification();
    const router = useRouter();
    const route = useRoute();
    const error_alert = ref(false);
    const error_message = ref('');
    const order_status = ref([
      { value: 1, text: "To Prepare" },
      { value: 2, text: "In Preparation" },
      { value: 3, text: "Shipped" },
      { value: 4, text: "Awaiting Stock" },
      { value: 5, text: "Cancelled" },
      { value: 6, text: "Error" },
      { value: 7, text: "On Hold" }
    ]);
    const generateLabelCount = ref(0);
    const initialForm = reactive({
      _id: "",
      status: "",
      weight: "",
      //tracking_number: "",
      reference: "",
      order_date: "",
      shipping_date: "",
      transporter: "",
      delivery_mode: "",
      product_entries: [] as Product[],
      address: {
        street: "",
        street2: "",
        street3: "",
        zip_code: "",
        city: "",
        country: "",
        additional_info: "",
        pick_up_reference: "",
        //state: ""
      },
      recipient: {
        firstname: "",
        lastname: "",
        company: "",
        phone: "",
        phone2: "",
        civility: "",
        email: ""
      },
      cn23: false
    });

    const form = reactive({ ...initialForm });
    const product_entries_rules = {
      sku: { required },
      unit_price: "",
      quantity: { required },
    };
    const rules = {
      //return{

      reference: { required },
      //shopIds: { required },
      //delivery_mode: { required },
      status: { required },
      //weight: "",
      //tracking_number: "",
      //order_date: "",
      /*address: {
        street: { required },
        street2: "",
        street3: "",
        zip_code: { required },
        city: { required },
        country: { required },
        additional_info: "",
        state: { required }
        //pick_up_reference: { required }
      },
      recipient: {
        firstname: { required },
        lastname: { required },
        company: { required },
        phone: { required },
        phone2: "",
        civility: { required },
        email: { required }
      },*/
    };
    const v$ = useVuelidate(rules, form);
    /*const services = ref([
      {
        id: 1,
        value: 'GLS'
      },
      {
        id: 2,
        value: 'Mondial Relay'
      },
    ]);*/
    const services = ref<Service[]>([]);
    const originalFormData: any = reactive({});
    const filteredStatuses = computed(() => {
      return filterOrderStatusByFormStatus(originalFormData.status);
    })

    const displayedServices = computed(() => {
      return services.value.map(service => ({
        text: serviceName(service.service),
        value: service.service
      }));
    })
    function serviceName(key){
      switch (key) {
        case 1:
          return 'GLS';
        case 2:
          return 'Mondial Relay';
        default:
          break;
      }
    }
    function filterOrderStatusByFormStatus(formStatus) {
      // Assuming formStatus is the value of the selected status in form
      return order_status.value.filter(status => {
        switch (formStatus) {
          case 1:
            // If formStatus is 1, show all statuses
            return true;
          case 2:
            // If formStatus is 2, show statuses In Preparation, Shipped, Cancelled
            return [2, 3, 5].includes(status.value);
          case 3:
            // If formStatus is 3, show only Shipped status
            return status.value === 3;
          case 4:
            // If formStatus is 4, show statuses To Prepare, Cancelled
            return [1, 4, 5].includes(status.value);
          case 5:
            // If formStatus is 5, show all statuses
            return [5].includes(status.value);
          case 6:
            // If formStatus is 6, show statuses To Prepare, Cancelled
            return [1, 6, 5].includes(status.value);
          case 7:
            // If formStatus is 7, show statuses In Preparation, Awaiting Stock, Cancelled
            return [2, 4, 5, 7].includes(status.value);
          default:
            return false;
        }
      });
    }
    function generateRandomString(length) {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    }
    function findDifferences(obj1, obj2) {
      const differences = {};

      for (const key in obj2) {
        //console.log(obj2[key])
        if (Array.isArray(obj2[key])) {
          // If the property is an array, compare the array elements
          const newObjects = obj2[key].filter(item2 => !obj1[key].some(item1 => item1._id === item2._id));
          if (newObjects.length > 0) {
            differences[key] = newObjects;
          }
        } else if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key]) && obj1[key] !== undefined) {
          // If the property is an object, recursively compare it
          const nestedDifferences = findDifferences(obj1[key], obj2[key]);
          if (Object.keys(nestedDifferences).length > 0) {
            differences[key] = nestedDifferences;
          }
        } else if (obj1[key] !== obj2[key]) {
          // If the property is not an object or array and has changed, track the difference
          differences[key] = obj2[key];
        }
      }

      return differences;

      /* const differences = {};

      for (const key in obj2) {
        if (Array.isArray(obj2[key])) {
          // If the property is an array, compare the array elements
          const newObjects = obj2[key].filter(item2 => !obj1[key].some(item1 => item1._id === item2._id));
          if (newObjects.length > 0) {
            differences[key] = newObjects;
          }
        } else if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
          // If the property is an object, recursively compare it
          const nestedDifferences = findDifferences(obj1[key], obj2[key]);
          if (Object.keys(nestedDifferences).length > 0) {
            differences[key] = nestedDifferences;
          }
        } else if (obj1[key] !== obj2[key]) {
          // If the property is not an object or array and has changed, track the difference
          if (obj2[key] !== "") {
            differences[key] = obj2[key];
          }
        }
      }

      return differences;*/
    }

    function addProduct() {
      form.product_entries.push({
        _id: generateRandomString(20),
        sku: "",
        unit_price: "",
        quantity: "",
      });
    }
    function deleteProduct(index) {
      if (form.product_entries.length > 1) {
        form.product_entries.splice(index, 1);
      }
    }
    function generateLabel() {
      const apiUrl =
        nodeEnv === 'development'
          ? 'http://localhost:3000/api/orders/pdf/' + form._id
          : nodeEnv === 'production'
            ? 'https://logimk.fr/api/orders/pdf/' + form._id
            : '';

      OrderRequest.generateLabel(apiUrl).then((response: any) => {

        const blob = new Blob([response.data]);
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `label-${form._id}.pdf`;
        link.click();

        generateLabelCount.value++;
      })
    }
    function filterOrder(order) {
      const { auditEntries, delivery_document, ...filteredOrder } = order; // Remove the 'products' property
      return filteredOrder;
    }
    function getOrder() {
      OrderRequest.getOrderById(route.params.id).then((response: any) => {
        const order = response.data.data;
        const filteredOrder = filterOrder(order);
        //store.commit('setEditOrderData', filteredOrder);
        _.merge(form, filteredOrder);
        _.merge(originalFormData, filteredOrder);
        //store.commit('setAuditLogs', order.auditEntries);

      });
    }

    function compareProducts(originalProducts, modifiedProducts) {
      const finalForm: any = [];

      // Create a map of original products for faster lookups
      const originalMap = new Map();
      originalProducts.forEach((product) => {
        originalMap.set(product.sku, product);
      });

      // Compare each product in the modifiedProducts array
      modifiedProducts.forEach((modifiedProduct) => {
        const originalProduct = originalMap.get(modifiedProduct.sku);

        if (!originalProduct) {
          // Product does not exist in originalProducts, mark it as new
          finalForm.push(modifiedProduct);
        } else if (
          modifiedProduct.unit_price !== originalProduct.unit_price ||
          modifiedProduct.quantity !== originalProduct.quantity
        ) {
          // Product exists in originalProducts and has different properties, mark it as modified
          finalForm.push({
            sku: modifiedProduct.sku,
            unit_price: modifiedProduct.unit_price,
            quantity: modifiedProduct.quantity,
          });
        }
      });

      // Find deleted products (products that exist in originalProducts but not in modifiedProducts)
      originalProducts.forEach((originalProduct) => {
        if (!modifiedProducts.find((product) => product.sku === originalProduct.sku)) {
          finalForm.push({ sku: originalProduct.sku, delete: true });
        }
      });

      return finalForm;
    }
    function editOrder() {
      v$.value.$validate();
      if (!v$.value.$error) {
        Swal.showLoading();
        const differences: any = findDifferences(originalFormData, form);
        const changes: any = compareProducts(originalFormData.product_entries, form.product_entries)
        differences.product_entries = changes;
        OrderRequest.editOrder(route.params.id, differences)
          .then((response: any) => {
            if (response.data.success == true) {
              Swal.close();
              notification.notify({
                title: "Order successfully updated",
                type: "success"
              });
              /*setTimeout(() => {
                router.push({ name: 'OrdersList' });
              }, 500);*/
            }

          }).catch((error) => {
            Swal.close();
            setTimeout(() => {
              error_message.value = error.response.data.message;
              error_alert.value = true;
              window.scrollTo(0, 0);
            }, 500)
          })
      } else {
        let animationFrameId;
        const animateScroll = () => {
          const errorElements = document.querySelectorAll('.item__error');
          if (errorElements.length > 0) {
            const firstErrorParent = errorElements[0].closest('.input-item');
            if (firstErrorParent) {
              firstErrorParent.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
            animationFrameId = requestAnimationFrame(animateScroll);
          }
        };
        animationFrameId = requestAnimationFrame(animateScroll);
      }
    }
    function duplicateOrder() {
      // Assuming you have an order object to duplicate
      const duplicatedOrder = { ...form };
      store.commit('setDuplicateOrder', duplicatedOrder);

      // Redirect to the AddOrder page with the duplicated order
      router.push({ name: 'NewOrder' });
    }
    function generateCN23() {
      if (generateLabelCount.value > 0) {
        const apiUrl =
          nodeEnv === 'development'
            ? 'http://localhost:3000/api/orders/cn23/' + form._id
            : nodeEnv === 'production'
              ? 'https://logimk.fr/api/orders/cn23/' + form._id
              : '';

        OrderRequest.generateLabel(apiUrl).then((response: any) => {

          const blob = new Blob([response.data]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `cn23-${form._id}.pdf`;
          link.click();

          generateLabelCount.value++;
        })
      } else {
        Swal.fire({
          title: "The label not generated",
          text: "You should generate label before",
          icon: "warning"
        })
      }
    }

    function getTransporters() {
      OrderRequest.getTransporters()
        .then((response: any) => {
          services.value = response.data.data;
          /*services.value.unshift({
            name: 'simple letter',
            service: 0
          })*/

        })
    }
    onMounted(() => {
      getTransporters();
      getOrder();
    })
    return {
      form,
      initialForm,
      getOrder,
      editOrder,
      generateLabel,
      v$,
      duplicateOrder,
      store,
      order_status,
      addProduct,
      deleteProduct,
      product_entries_rules,
      error_alert,
      error_message,
      originalFormData,
      findDifferences,
      generateCN23,
      generateRandomString,
      generateLabelCount,
      countries_data,
      filterOrderStatusByFormStatus,
      filteredStatuses,
      tracking_number_modal,
      services,
      getTransporters,
      serviceName,
      displayedServices
    };
  },
});
