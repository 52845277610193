<template>
    <footer class="footer bg-white mt-auto">
       <div class="d-flex justify-content-center py-3">
            <p class="mb-0"><span class="fw-bold">Logimk</span> © {{new Date().getFullYear()}}</p>   
        </div> 
    </footer>
</template>

<style lang="scss" scoped>

</style>