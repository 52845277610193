
import { useNotification } from "@kyvg/vue3-notification";
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import ShopRequest from "@/services/requests/shop";

export default defineComponent({
  setup(props,{emit}) {
    const store = useStore();
    const route = useRoute();
    const notification = useNotification();
    const update_orders = computed(() => {
      return store.getters.updateOrders
    });
    const form = reactive({
      cancel_notify: update_orders.value.cancel_notify,
      shipment_notify: update_orders.value.shipment_notify,

    });
    interface externalServices {
      _id: any,
      cancel_notify: string,
      shipment_notify: boolean,
    }
    function EditOrderStatus() {
      let id = store.state.user.firstShop;

      const form_data = {
        external_services: [] as externalServices[]
      };

      form_data.external_services.push({
        _id: route.params.id,
        cancel_notify: form.cancel_notify,
        shipment_notify: form.shipment_notify
      });
      Swal.showLoading();
      ShopRequest.editShop(id, form_data)
        .then(() => {
          emit('getServiceDetails');
          Swal.close();
          notification.notify({
            title: "Successfully updated",
            type: "success"
          });
        })
        .catch(() => {
          Swal.close();
          notification.notify({
            title: "Something went wrong",
            type: "error"
          });
        })
    }
    return {
      store,
      route,
      notification,
      form,
      EditOrderStatus
    };
  },
});
