
import { computed, defineComponent, onMounted, ref } from "vue";
import ProductSummary from "@/components/ProductComponents/ProductSummary.vue";
import ProductHistory from "@/components/ProductComponents/ProductHistory.vue";

export default defineComponent({
  components: {
    ProductSummary,
    ProductHistory
  },
  setup() {
    const tabs = ['Details', 'Changes history'];
    const activeTab = ref(0);
    const tabComponents = computed(() => {
      return [ProductSummary, ProductHistory];
    });

    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      tabs,
      activeTab,
      tabComponents
    };
  },
});
