

import { reactive, defineComponent, ref, computed } from 'vue';
import { BIconEye } from 'bootstrap-icons-vue';
import { BIconEyeSlash } from 'bootstrap-icons-vue';
import { useStore } from 'vuex';
import ShopRequest from '@/services/requests/shop';
import { useRoute } from 'vue-router';
import Swal from "sweetalert2";
import { useNotification } from '@kyvg/vue3-notification';

export default defineComponent({
  components: {
    BIconEye,
    BIconEyeSlash
  },
  setup(props, { emit }) {
    const showPublicKey = ref(false);
    const showPrivateKey = ref(false);
    const showToken = ref(false);
    const store = useStore();
    const route = useRoute();
    const notification = useNotification();
    const access_api = computed(() => {
      return store.getters.accessApi
    });
    const currentService = computed(() => {
      return store.getters.currentServiceName
    });

    interface externalServices {
      _id: any,
      name: string,
      acces_api: {
        url: string,
        public_key: string,
        private_key: string,
        location: string | number
      }
    }

    const form = reactive({
      name: access_api.value.name,
      url: access_api.value.url,
      public_key: access_api.value.public_key,
      private_key: access_api.value.private_key,
      location: access_api.value.location,
      //token: access_api.value.token
    });

    function togglePublicKeyVisibility(prop) {
      showPublicKey.value = !showPublicKey.value;
    }
    function togglePrivateKeyVisibility(prop) {
      showPrivateKey.value = !showPrivateKey.value;
    }
    function toggleTokenVisibility() {
      showToken.value = !showToken.value;
    }
    function EditApiAccess() {
      let id = store.state.user.firstShop;
      Swal.showLoading();
      const form_data = {
        external_services: [] as externalServices[]
      };

      form_data.external_services.push({
        _id: route.params.id,
        name: form.name,
        acces_api: {
          url: form.url,
          public_key: form.public_key,
          private_key: form.private_key,
          location: form.location
        },

      });

      ShopRequest.editShop(id, form_data)
        .then((response: any) => {
          //console.log(response);
          emit('getServiceDetails');
          Swal.close();
          if (response.data.data.responses.length > 0) {
            response.data.data.responses.forEach(element => {
              notification.notify({
                title: element,
                type: "success",
                //duration: -1, // Set duration to 0 to prevent automatic dismissal
                duration: 5000
              });
            });
          }

          if (response.data.data.errors.length > 0) {
            response.data.data.errors.forEach(element => {
              notification.notify({
                title: element,
                type: "error",
                duration: 5000
              });
            });
          }

        })
        .catch(() => {
          Swal.close();
          notification.notify({
            title: "Something went wrong",
            type: "error"
          });
        })
    }

    return {
      togglePublicKeyVisibility,
      togglePrivateKeyVisibility,
      showPublicKey,
      showPrivateKey,
      access_api,
      form,
      EditApiAccess,
      currentService,
      showToken,
      toggleTokenVisibility
    }
  }
})
