
import { defineComponent, ref, onMounted, watch, inject, shallowRef } from "vue";
import Table from "@/components/Table.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { BIconPencilSquare, BIconTrash } from "bootstrap-icons-vue";
import Swal from "sweetalert2";
import { useNotification } from "@kyvg/vue3-notification";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ShopRequest from "@/services/requests/shop";
import ProductRequest from "@/services/requests/product";

export default defineComponent({
  components: {
    Table,
    AddIcon,
    SearchIcon,
    "v-select": VueSelect,
    BIconPencilSquare,
    BIconTrash
  },
  setup() {
    interface shopResponse {
      _id: string;
      name: string;
    }
    const shops = ref<shopResponse>();
    const shopId = ref('');
    const params = ref('');
    const notification = useNotification();
    const url = ref(`products/user`);
    const page = ref(1);
    const datatable = ref();
    const search_query = ref('');
    const headers = ref([
      { value: "_id", text: "id", order: 1, show: true, type: "input"},
      { value: "origin", text: "origin", order: 2, show: true,type: "input" },
      { value: "title", text: "title", order: 3, show: true, type: "input" },
      { value: "stock", text: "stock", order: 4, show: true, type: "input" },
      { value: "location", text: "location", order: 5, show: true, type: "input" },
      { value: "ean13", text: "ean 13", order: 6, show: true, type: "input" }
    ]);
  
    //const rows = ref([]);
    const actions = ref(true);
    watch([shopId, search_query], (val) => {
      setParams(val)
    });
    function configConfirmed(new_config){
      //console.log('Config confirmed', new_config)
      headers.value = new_config;
    }
    function setParams(val) {
      if (Array.isArray(val)) {
        params.value = '';
        if ((val[0] !== '' && val[0] !== null) && val[1] === '') {
          params.value = `_id=${val[0]}`;
        } else if (val[1] !== '' && (val[0] === '' || val[0] === null)) {
          params.value = `_q=${val[1]}`;
        } else if ((val[0] !== '' && val[0] !== null) && val[1] !== '') {
          params.value = `_q=${val[1]}&_id=${val[0]}`;
        }
      } else {
        params.value = "";
      }
    }
    function getPageNumber(value) {
      page.value = value;
    }
    
    function deleteProduct(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "The product will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          ProductRequest.deleteProduct(id).then((response) => {
            notification.notify({
              title: "Product successfully deleted",
              type: 'success'
            });
            datatable.value.loadData();
          })
        }
      })
    }
    function getShops() {
     ShopRequest.getShopsName()
      .then((response: any) => {
        shops.value = response.data.data;
      })
    }
    
    onMounted(() => {
      window.scrollTo(0, 0);
      getShops();
    });
    return {
      datatable,
      page,
      headers,
      actions,
      setParams,
      getPageNumber,
      url,
      deleteProduct,
      shops,
      shopId,
      params,
      search_query,
      configConfirmed
    };
  },
});
