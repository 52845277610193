
import { defineComponent, ref } from 'vue'
import Table from "@/components/Table.vue";
import InfoIcon from "@/components/icons/InfoIcon.vue";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon.vue";
export default defineComponent({
    components:{
        Table,
        InfoIcon,
        ArrowLeftIcon
    },
    setup() {
    const headers = ref([
      {value: 'id',text:'id'},
      {value: 'origin',text:'origin'},
      {value: 'carrier',text:'carrier'},
    ]);
    const rows = ref([
      {
        id: 145233,
        origin: 'website1.fr',
        carrier: 'b213',
      },
       {
        id: 145233,
        origin: 'website1.fr',
        carrier: 'b213',
      },
       {
        id: 145233,
        origin: 'website1.fr',
        carrier: 'b213',
      },
    ]);
    const actions = ref(false);
    return {
      headers,rows,actions
    };
  },
})
