import { defineAbility } from '@casl/ability';

const ability = defineAbility((can, cannot) => {
  // Assume userPermissions is fetched from your backend
  const userPermissions = ['readPost', 'createPost', 'updatePost', 'deletePost'];

  if (userPermissions.includes('readPost')) {
    can('read', 'Post');
  }

  if (userPermissions.includes('createPost')) {
    can('create', 'Post');
  }

  if (userPermissions.includes('updatePost')) {
    can('update', 'Post');
  }

  if (userPermissions.includes('deletePost')) {
    can('delete', 'Post');
  }
});

export default ability;