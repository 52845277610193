
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent, reactive, ref } from 'vue';
import { BIconUpload } from "bootstrap-icons-vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import TrashIcon from "@/components/icons/TrashIcon.vue";

export default defineComponent({
    props: {
        id: String,
    },
    components: {
        BIconUpload,
        CloseIcon,
        TrashIcon
    },
    emits: ["addNewDeliveryDocument", "close"],
    setup(props,{ emit }) {
        const delivery_document = reactive({
            language: "",
            type: "",
            document: ""
        })
        const selectedFile = ref("");
        const rules = reactive({
            type: { required },
            language: { required },
            document: { required }
        })
        const v$ = useVuelidate(rules, delivery_document);
        function addNewDeliveryDocument() {
            v$.value.$validate();
            if (!v$.value.$error) {
                emit("addNewDeliveryDocument", delivery_document);
                close();
            }
            
        }
        function handleFileChange(event){
            const file = event.target.files[0];
            delivery_document.document = file;
        }
        function deleteDocument(){
            delivery_document.document = "";
        }
        function close(){
            emit('close');
        }
        return {
            delivery_document,
            addNewDeliveryDocument,
            v$,
            selectedFile,
            deleteDocument,
            handleFileChange,
            close
        }
    }
})
