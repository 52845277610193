<template>
  <div ref="pdf_template">
    <div class="header">
      <table>
        <tr>
          <td style="width: 50%">
            <div class="header-left">
              <h2 style="margin-bottom: 20px">Preparation de commande</h2>
              <table>
                <thead class="header-left__top">
                  <tr>
                    <td>523654</td>
                    <td colspan="2">2022-12-17 15:11:04</td>
                  </tr>
                </thead>
                <tbody class="header-left__body">
                  <tr>
                    <td width="33.33">web</td>
                    <td width="33.33">#12365</td>
                    <td width="33.33">
                      <img
                        width="60"
                        height="60"
                        src="https://gravatar.com/avatar/250872d61ce477f84f6f2030eef56bab?s=400&d=robohash&r=x"
                        alt=""
                      />
                    </td>
                  </tr>
                </tbody>
                <tfoot class="header-left__foot">
                  <tr>
                    <td colspan="3">
                      <div style="float: left">
                        Poids total: 201 g / Quantité totale: 3
                      </div>
                      <div style="float: right">
                        <span class="checkbox"></span>
                      </div>
                    </td>
                  </tr>
                </tfoot>
              </table>
              <div
                style="
                  margin-top: 10px;
                  text-align: left;
                  border-left: 2px solid gray;
                  padding: 5px 0px 5px 20px;
                "
              >
                <span>stella.heitz@orange.fr</span>
                <span> / </span>
                <span>+33652369852</span>
              </div>
            </div>
          </td>
          <td style="width: 50%; vertical-align: baseline">
            <div class="header-right">
              <ul>
                <li>Sophie Lagache</li>
                <li>35 rue Jonhstone Reckitt, 91130,</li>
                <li>Ris-Orangis</li>
                <li>91130 RIS-ORANGIS</li>
                <li>France</li>
              </ul>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="body" style="margin-top: 50px">
      <table style="width: 100%">
        <thead>
          <tr
            class="fw-bolder text-center"
            style="font-size: 14px; font-weight: 700"
          >
            <td width="20%">sku</td>
            <td width="20%">Photo</td>
            <td width="20%">Désignation</td>
            <td width="20%">Emplacement</td>
            <td width="10%">Qté</td>
            <td width="10%"></td>
          </tr>
        </thead>
        <tbody class="border-top-0">
          <tr class="fw-bolder text-center" style="font-size: 13px">
            <td style="font-weight: 700">3770024515022</td>
            <td>
              <img
                width="60"
                height="60"
                src="https://gravatar.com/avatar/250872d61ce477f84f6f2030eef56bab?s=400&d=robohash&r=x"
                alt=""
              />
            </td>
            <td>Shampooing doux - cheveux et corps</td>
            <td width="20%">Emplacement</td>
            <td width="10%">4</td>
            <td width="10%">
              <span class="checkbox"></span>
            </td>
          </tr>
          <tr class="fw-bolder text-center" style="font-size: 12px">
            <td style="font-weight: 700">3770024515022</td>
            <td>
              <img
                width="60"
                height="60"
                src="https://gravatar.com/avatar/250872d61ce477f84f6f2030eef56bab?s=400&d=robohash&r=x"
                alt=""
              />
            </td>
            <td>Shampooing doux - cheveux et corps</td>
            <td width="20%">Emplacement</td>
            <td width="10%">4</td>
            <td width="10%">
              <span class="checkbox"></span>
            </td>
          </tr>
          <tr class="fw-bolder text-center" style="font-size: 12px">
            <td style="font-weight: 700">3770024515022</td>
            <td>
              <img
                width="60"
                height="60"
                src="https://gravatar.com/avatar/250872d61ce477f84f6f2030eef56bab?s=400&d=robohash&r=x"
                alt=""
              />
            </td>
            <td>Shampooing doux - cheveux et corps</td>
            <td width="20%">Emplacement</td>
            <td width="10%">4</td>
            <td width="10%">
              <span class="checkbox"></span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  </template>
  
  
  <style scoped>
  .header table {
  border-collapse: collapse;
  }
  table {
  width: 100%;
  }
  .table > thead {
  vertical-align: middle !important;
  }
  
  .body table {
  width: 100%;
  border-collapse: collapse;
  }
  .body table,
  .body td,
  .body th {
  /*border: 1px solid black;*/
  }
  
  .header .header-left table tbody tr {
  border-bottom: 3px solid gray;
  border-top: 3px solid gray;
  }
  .header .header-left table tbody tr td,
  .header .header-left table thead tr td,
  .header .header-left table tfoot tr td{
  padding: 5px 0;
  }
  .header .header-right ul {
  margin: 0;
  padding-left: 30px;
  }
  .header .header-right ul li {
  background-color: transparent;
  border: 0;
  padding: 0;
  font-size: 13px;
  }
  .header .header-right ul li:nth-child(1){
  font-weight: 700;
  }
  .header-left__top tr td {
  font-size: 13px;
  font-weight: 700;
  }
  .header-left__body tr td{
  font-size: 16px;
  font-weight: 700;
  }
  .header-left__foot tr td{
  font-size: 14px;
  font-weight: 700;
  }
  .checkbox{
  width: 12px;
  height: 12px;
  border:2px solid;
  display: block;
  margin: auto;
  }
  .body table thead tr td{
  padding: 8px 5px;
  background-color: #808284;
  color: white;
  border-left: 1px solid white; 
  }
  .body table tbody tr td{
  border-bottom: 1px solid #999a9b;
  padding: 5px;
  }
  </style>