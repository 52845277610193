
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
import { BIconTrash } from "bootstrap-icons-vue"
import PackRequest from "@/services/requests/packs";
import newPackProduct from "@/components/PackComponents/NewPackProduct.vue";
import { useNotification } from '@kyvg/vue3-notification';
import Swal from 'sweetalert2';

export default defineComponent({
    components: {
        VPagination,
        BIconTrash,
        newPackProduct
    },
    setup() {
        interface productsData {
            _id: string,
            title: string,
            sku: string,
            quantity: string,
            stock: string,
        }
        interface DeleteProduct {
            sku: string,
            delete: boolean
        }
        const page = ref(1);
        const total_items = ref(0);
        const route = useRoute();
        const products = ref<productsData[]>();
            const notification = useNotification();
        function getPackProducts() {
            PackRequest.getPackProducts(route.params.id, page.value)
                .then((response: any) => {
                    products.value = response.data.data.data_array;
                    total_items.value = parseInt(response.data.data.totalCount);
                })
                .catch((error) => {
                    console.log(error);
                })
        }

        function changePage() {
            getPackProducts();
        }


        function deleteProduct(sku) {

            Swal.fire({
                title: "Are you sure?",
                text: "The product will be deleted",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    let id = route.params.id;
                    const product_entries = [] as DeleteProduct[];
                    product_entries.push({
                        sku: sku,
                        delete: true
                    });
                    const form_data = {
                        product_entries
                    }

                    PackRequest.editPack(id, form_data)
                        .then(() => {
                            notification.notify({
                                title: "Product successfully deleted",
                                type: "success"
                            });
                            
                            getPackProducts();
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }

            })
        }


        onMounted(() => {
            getPackProducts();
            // changePage();
        })
        return {
            products,
            getPackProducts,
            page,
            total_items,
            changePage,
            deleteProduct
        }
    }
});
