<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25.308"
    height="14.455"
    viewBox="0 0 36.308 20.455"
  >
    <g
      id="Groupe_446"
      data-name="Groupe 446"
      transform="translate(-651.97 -1422.505)"
    >
      <path
        id="Tracé_40"
        data-name="Tracé 40"
        d="M670.136,1422.505a18.766,18.766,0,0,1,9.118,2.466,31.971,31.971,0,0,1,8.328,6.48c.873.95.928,1.517.156,2.355a30.247,30.247,0,0,1-10.782,7.751,17.518,17.518,0,0,1-14.471-.334,30.566,30.566,0,0,1-9.867-7.271c-.888-.967-.857-1.539.067-2.523a29.119,29.119,0,0,1,12.106-8.077A17.067,17.067,0,0,1,670.136,1422.505Zm-.064,17.139a6.858,6.858,0,0,0,7.03-6.936,6.981,6.981,0,1,0-7.03,6.936Z"
        transform="translate(0 0)"
        fill="#f1c5d3"
      />
      <path
        id="Tracé_41"
        data-name="Tracé 41"
        d="M767.612,1483.712a2.316,2.316,0,0,1-2.285-2.331,2.369,2.369,0,0,1,2.35-2.284,2.331,2.331,0,0,1,2.273,2.349A2.288,2.288,0,0,1,767.612,1483.712Z"
        transform="translate(-97.523 -48.687)"
        fill="#f1c5d3"
      />
    </g>
  </svg>
</template>