
import Footer from "@/components/Footer.vue";
import { defineComponent, inject, onMounted, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import ArrowBottom from "@/components/icons/ArrowBottomIcon.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie'
import authRequest from "@/services/requests/auth";
import axios from "@/plugins/axios";

export default defineComponent({
  components: { Footer, ArrowBottom },
  name: "Register",
  setup() {
    const store = useStore(); 
    const router = useRouter();
    const disabled = ref(false);
    const form = reactive({
      organization: "",
      email: "",
      password: "",
      passwordConfirmation: "",
      firstname: "",
      lastname: "",
      phone: "",
      role: ""
    });
    const rules = {
      organization: { required },
      email: { required },
      password: { required },
      passwordConfirmation: { required },
      firstname: { required },
      lastname: { required },
      role: { required }
    }
    const roles = [
      { id: 1, value: "COMMERCE" },
      { id: 2, value: "LOGISTIC" },
      { id: 3, value: "HYBRID" }
    ];
    const v$ = useVuelidate(rules, form);
    function register() {
      v$.value.$validate();
      if (!v$.value.$error) {
        /*const formData = new FormData();
        for (const key in form) {
          if (form[key]) {
            formData.append(key, form[key]);
          }
        }*/

        authRequest.register(form).then((response: any) => {
          const decoded = jwt.decode(response.data.token);
          Cookies.set('access_token', response.data.token, { expires: decoded.exp })
          store.commit('setUser', decoded);
          disabled.value = false;
          axios.defaults.headers['Authorization'] = `Bearer ${Cookies.get('access_token')}`;
          router.push({name: 'Home'});
        }).catch((error) => {
          console.log(error);
        })
      }

    }
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return { form, register, v$, roles, disabled };
  },
});
