import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SideBar = _resolveComponent("SideBar")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!
  const _component_BackDrop = _resolveComponent("BackDrop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SideBar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Header),
      _createVNode(_component_router_view, { class: "px-4 px-sm-5" }),
      _createVNode(_component_Footer)
    ]),
    (_ctx.store.state.openMenu)
      ? (_openBlock(), _createBlock(_component_BackDrop, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}