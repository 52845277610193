
import OrderRequest from '@/services/requests/order';
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import VPagination from "@hennge/vue3-pagination";
import AddIcon from "@/components/icons/AddIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import NewCarton from "@/components/ConfigurationComponents/Logistics/NewCarton.vue";
import { BIconPencilSquare, BIconTrash } from "bootstrap-icons-vue";

export default defineComponent({
  components: {
    VPagination,
    AddIcon,
    SearchIcon,
    NewCarton,
    BIconPencilSquare,
    BIconTrash
  },
  setup() {
    interface Carton {
      id: string,
      reference: string,
      stock: string,
      poids: string,
      hauteur: string,
      largeur: string,
      longueur: string,
      prix: string,
      code_devise: string,
      fournisseur: string
    }
    const headers = ref([
      { value: "_id", text: "id", order: 1, show: true, type: "input" },
      { value: "reference", text: "Reference", order: 2, show: true, type: "input" },
      { value: "stock", text: "Stock", order: 3, show: true, type: "input" },
      { value: "poids", text: "Poids", order: 4, show: true, type: "input" },
      { value: "hauteur", text: "Hauteur", order: 5, show: true, type: "input" },
      { value: "largeur", text: "Largeur", order: 6, show: true, type: "input" },
      { value: "longueur", text: "Longueur", order: 7, show: true, type: "input" },
      { value: "prix", text: "Prix", order: 8, show: true, type: "input" },
      { value: "code_devise", text: "Code Devise", order: 9, show: true, type: "input" },
      { value: "fournisseur", text: "Fournisseur", order: 10, show: true, type: "input" },
      { value: "actions", text: "Actions", order: 11, show: true, type: "input" }
    ]);
    const page = ref(1);
    const total_items = ref(0);
    const route = useRoute();
    const cartons = ref<Carton[]>();
    const search_query = ref('');
    const new_carton_modal = ref(false);
    function getCartonModels() {
      cartons.value = [];
    }
    /* function getChangesHistory(){
         OrderRequest.getChangesHistory(route.params.id, page.value)
         .then((response: any) => {
             changes_history.value = response.data.data.data_array;
             total_items.value = parseInt(response.data.data.totalCount);
         })
         .catch((error) => {
             console.log(error);
         })
     }*/

    function changePage() {
      getCartonModels();
    }
    function addNewCarton() {
      console.log('add new carton');
    }
    function deleteData(id){
      console.log('delete data: '+ id);
    }
    onMounted(() => {
      getCartonModels();
      //changePage();  
    })
    return {
      headers,
      cartons,
      getCartonModels,
      page,
      total_items,
      search_query,
      new_carton_modal,
      changePage,
      addNewCarton,
      deleteData
    }
  }
});
