
import { defineComponent } from "vue";
/*import SideBar from '@/components/SideBar.vue';
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';*/

export default defineComponent({
  name: "app",
  components: {
    /*SideBar,
    Header,
    Footer,*/
  },
  setup() {
    return {};
  },
});
