
import { defineComponent, Ref, ref } from "vue";
import { BIconPlus, BIconTrash } from "bootstrap-icons-vue";
import AddRule from "./AddRule.vue";
import AddCondition from "./AddCondition.vue";
import Swal from "sweetalert2";
import vSelect  from "vue-select";
import "vue-select/dist/vue-select.css";

export default defineComponent({
  components: {
    BIconPlus,
    BIconTrash,
    AddRule,
    AddCondition,
    vSelect
  },
  setup() {
    const carriers = ref([
      { id: 1, value: "MondialRelay - Mondial Relay 'Access by 12plus'" },
      { id: 2, value: "Dhl - DHL by 12plus" },
      { id: 3, value: "Colissimo - Colissimo sans signature by 12plus" },
      { id: 4, value: "Colissimo - Colissimo Expert by 12plus" },
      { id: 5, value: "Colissimo - Colissimo avec signature by 12plus" },
      { id: 6, value: "GlsByApi - GLS Standard" },
      { id: 7, value: "GlsByApi - GLS FlexDeliveryService" },
      { id: 8, value: "GlsByApi - GLS ExpressParcelGuarenteed" },
      { id: 9, value: "Lettre Simple" },
    ]);
    const default_rule = ref("");
    const rules: Ref<any[]> = ref([]);
    const selectedRuleIndex = ref();
    const receivedCondition = ref({});
    const addConditionModal = ref(false);
    const addRuleModal = ref(false);
    function openRuleModal() {
      addRuleModal.value = true;
    }
    function addRule(event: string) {
      rules.value.push({
        name: event,
        conditions: [],
      });
      addRuleModal.value = false;
    }
    function removeRule(index) {
      Swal.fire({
        title: "Are you sure?",
        text: "The mapping rule will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          rules.value.splice(index, 1);
        }
      });
    }
    function openCoditionModal() {
      addConditionModal.value = true;
    }
    
    function addCondition(condition_data: any) {
      receivedCondition.value = condition_data;
      rules.value[selectedRuleIndex.value].conditions.push(
        receivedCondition.value
      );
      addConditionModal.value = false;
    }

    function removeCondition(parent_index, child_index) {
      Swal.fire({
        title: "Are you sure?",
        text: "The condition will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          rules.value[parent_index].conditions.splice(child_index, 1);
        }
      });
    }
    return {
      rules,
      addRule,
      removeRule,
      addCondition,
      removeCondition,
      selectedRuleIndex,
      receivedCondition,
      addConditionModal,
      addRuleModal,
      openRuleModal,
      openCoditionModal,
      carriers,
      default_rule
    };
  },
});
