import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ae93c27a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  class: "nav nav-pills mb-3",
  id: "pills-tab",
  role: "tablist"
}
const _hoisted_2 = ["onClick", "id", "data-bs-target", "aria-controls", "aria-selected"]
const _hoisted_3 = {
  key: 0,
  class: "tab-content",
  id: "pills-tabContent"
}
const _hoisted_4 = ["id", "aria-labelledby"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("ul", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "nav-item",
          role: "presentation"
        }, [
          _createElementVNode("button", {
            class: _normalizeClass(['nav-link', { 'active': _ctx.activeTab === index }]),
            onClick: ($event: any) => (_ctx.activeTab = index),
            id: 'pills-' + tab + '-tab',
            "data-bs-toggle": "pill",
            "data-bs-target": '#pills-' + tab,
            type: "button",
            role: "tab",
            "aria-controls": 'pills-' + tab,
            "aria-selected": _ctx.activeTab === index
          }, _toDisplayString(tab), 11, _hoisted_2)
        ]))
      }), 128))
    ]),
    (_ctx.dataLoaded)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: _normalizeClass(['tab-pane', 'fade', { 'show active': _ctx.activeTab === index }]),
              id: 'pills-' + tab,
              role: "tabpanel",
              "aria-labelledby": 'pills-' + tab + '-tab'
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tabComponents[index]), {
                onGetServiceDetails: _cache[0] || (_cache[0] = ($event: any) => (_ctx.getServiceDetails()))
              }))
            ], 10, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}