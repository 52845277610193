
import { computed, defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import NotificationIcon from "@/components/icons/NotificationIcon.vue";
import ArrowBottomIcon from "@/components/icons/ArrowBottomIcon.vue";
import MenuIcon from "@/components/icons/MenuIcon.vue";
export default defineComponent({
  components: {
    NotificationIcon,
    ArrowBottomIcon,
    MenuIcon
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const titleData = ref([
      { title: "WELCOME !", subTitle: "to your dashboard", routeName: "Home", breadcrumb: 'Dashboard' },
      { title: "ORDERS LIST", routeName: "OrdersList", breadcrumb: 'Order list' },
      { title: "CREATE NEW ORDER", routeName: "NewOrder", breadcrumb: 'New order' },
      { title: "EDIT ORDER", routeName: "EditOrder", breadcrumb: 'Edit order' },
      { title: "LIST OF PREPARATION SESSIONS", routeName: "Preparations", breadcrumb: 'Preparation session' },
      { title: "LIST OF PRODUCTS", routeName: "ProductsList", breadcrumb: 'Product list' },
      { title: "PACKS", routeName: "Packs", breadcrumb: 'Packs' },
      { title: "CREATE NEW PRODUCT", routeName: "NewProduct", breadcrumb: 'New product' },
      { title: "CREATE NEW PACK", routeName: "NewPack", breadcrumb: 'New pack' },
      { title: "EDIT PRODUCT", routeName: "EditProduct", breadcrumb: 'Edit product' },
      { title: "EDIT PACK", routeName: "EditPack", breadcrumb: 'Edit pack' },
      { title: "LIST OF TICKETS", routeName: "Tickets", breadcrumb: 'Tickets' },
      { title: "LIST OF SHOPS", routeName: "ShopsList", breadcrumb: 'Shops list' },
      { title: "CREATE NEW SHOP", routeName: "NewShop", breadcrumb: 'New shop' },
      { title: "INVOICING", routeName: "Invoicing", breadcrumb: 'Invoicing' },
      { title: "ACCOUNT", routeName: "Account", breadcrumb: 'Account' },
      { title: "ORDER PREPARATION", routeName: "OrderPreparation", breadcrumb: 'Order Preparation' },
      { title: "PICKING", routeName: "Picking", breadcrumb: 'Picking' },
      { title: "EXTERNAL SERVICES",routeName: "ExternalServices", breadcrumb: 'External Services'},
      { title: "EDIT EXTERNAL SERVICES",routeName: "EditExternalServices", breadcrumb: 'Edit External Services'},
      { title: "NOTIFICATION AND LABELS",routeName: "NotificationsAndLabels", breadcrumb: 'Notifications And Labels'},
      { title: "SCAN BARCODE",routeName: "ScanBarCode", breadcrumb: 'Scan BarCode'},
      { title: "GENERATE LABEL",routeName: "GenerateLabel", breadcrumb: 'Generate Label'},
      { title: "NEW ORDER STATE NOTIFICATION",routeName: "NewStatusNotification", breadcrumb: 'New Order State Notification'},
      { title: "TRANSPORTERS", routeName: "Transporters", breadcrumb: "Transporters"},
      { title: "EDIT TRANSPORTER", routeName: "EditTransporter", breadcrumb: "Edit Transporter"},
      { title: "RETURNED ORDER DETAILS", routeName: "ReturnedOrderDetails", breadcrumb: "Returned Order Details"},
      { title: "LOGISTICS", routeName: "Logistics", breadcrumb: "Logistics"},
      { title: "LOCATION ASSOCIATION", routeName: "LocationAssociation", breadcrumb: "Location Association"},
      { title: "EXPORTS", routeName: "Exports", breadcrumb: "Exports"}

    ]);
    interface titleDataType {
      title: string;
      routeName: string;
    }
    const filterTitle = computed(() => {
      return titleData.value.filter((el: titleDataType) => {
        return el.routeName == route.name;
      });
    });
    const openMenu = false;
    function toggleMenu(){
      store.state.openMenu = !store.state.openMenu;
    }
    function logOut(){
      store.dispatch('logOut').then(() => {
        router.push({name:'Login'})
      });
    }
    return {
      filterTitle,
      titleData,
      openMenu,
      toggleMenu,
      store,
      logOut,
      router
    };
  },
});
