
import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import AccessApi from "@/components/ConfigurationComponents/Transporters/ApiAccess.vue";
import TrasportersRequests from '@/services/requests/transporters';
export default defineComponent({
    components: {
        AccessApi
    },
    setup() {
        const dataLoaded = ref(false);
        const tabs = ['Accés API'];
        const activeTab = ref(0);
        const tabComponents = computed(() => {
            return [AccessApi];
        });
        const route = useRoute();
        const store = useStore();

        function getTransporterDetails(){
            TrasportersRequests.getTransporterDetails(route.params.id)
            .then((response: any) => {
                if(response.data.data && response.data.data.service) {
                    store.commit('setTransporterName', response.data.data.service);
                }
                if(response.data.data && response.data.data.acces_api){
                    store.commit('setTransportAccessApi', response.data.data.acces_api);
                }
                dataLoaded.value = true;
            })
        }
        onMounted(() => {
            getTransporterDetails();
        })
        return {
            dataLoaded,
            tabs,
            activeTab,
            tabComponents,
            getTransporterDetails,
            route,
            store
        }
    }
})
