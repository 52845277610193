
import { defineComponent, ref, onMounted, computed } from "vue";
import Table from "@/components/Table.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import NewExternalService from "@/components/ConfigurationComponents/ExternalServices/NewExternalService.vue";
import {
  BIconCheckLg,
  BIconXLg,
  BIconClock,
  BIconPencilSquare,
  BIconTrash,
  BIconUpload
} from "bootstrap-icons-vue";
import { useStore } from "vuex";
import ShopRequest from "@/services/requests/shop";
import ExternalServices from "@/services/requests/external-services";
import Swal from "sweetalert2";
import { useNotification } from "@kyvg/vue3-notification";
import RequestStatusVue from "@/components/ConfigurationComponents/ExternalServices/RequestStatus.vue";
//import EyeIcon from "@/components/icons/EyeIcon.vue";
//import TrashIcon from "@/components/icons/TrashIcon.vue";
export default defineComponent({
  components: {
    Table,
    AddIcon,
    SearchIcon,
    NewExternalService,
    BIconCheckLg,
    BIconXLg,
    BIconClock,
    BIconPencilSquare,
    BIconTrash,
    BIconUpload,
    RequestStatusVue
  },
  setup() {
    const statusModal = ref(false);
    const statuses_data = ref();
    const statusIconHtml = (status) => {
      if (status.success) {
        return `<b-icon-check-lg></b-icon-check-lg>`;
      } else {
        return `<b-icon-x-lg></b-icon-x-lg>`;
      }
    };

    const store = useStore();
    const datatable = ref();
    const url = ref(`/shops/external-services`);
    const params = ref('');
    const services = ref([
      {
        id: 1,
        value: 'Woocommerce'
      },
      {
        id: 2,
        value: 'Shopify'
      },
      {
        id: 3,
        value: 'Amazon'
      },
      {
        id: 4,
        value: 'Prestashop'
      },
      {
        id: 5,
        value: 'Magento'
      },
      {
        id: 6,
        value: 'BigCommerce'
      },
      {
        id: 7,
        value: 'Wizishop'
      },
      {
        id: 8,
        value: 'AliExpress'
      },
      {
        id: 9,
        value: 'Cdiscount'
      },
      {
        id: 10,
        value: 'Rakuten'
      }
    ]);
    const headers = ref([
      { value: "service", text: "service", show: true, order: 1, type: "input" },
      { value: "name", text: "name", show: true, order: 2, type: "input" },
      { value: "api_connexion", text: "Api Connexion", show: true, order: 3, type: "input" },
      { value: "recup_commandes", text: "Récup. commandes", show: true, order: 4, type: "input" },
      { value: "cloture_commandes", text: "Cloture commandes", show: true, order: 5, type: "input" },
      { value: "envoie_commandes", text: "Envoi commandes", show: true, order: 6, type: "input" },
      { value: "acc_commandes", text: "Acc. commandes", show: true, order: 7, type: "input" },
      { value: "recup_stock", text: "Récup. stocks", show: true, order: 8, type: "input" },
      { value: "envoie_stock", text: "Envoi. stocks", show: true, order: 7, type: "input" },
      { value: "recup_product", text: "Récup. produits", show: true, order: 10, type: "input" }
      //{ value: "actions", text: "actions" },
    ]);
    const rows = ref([
      {
        id: 1,
        service: "Amazon",
        nom: "Test",
        recup_commandes: "1",
        cloture_commandes: "1",
        envoi_commandes: "2",
        acc_commandes: "3",
        recup_stocks: "3",
        envoi_stocks: "1",
        recup_produits: "2",
      },
      {
        id: 1,
        service: "Ali Express",
        nom: "Test",
        recup_commandes: "2",
        cloture_commandes: "1",
        envoi_commandes: "1",
        acc_commandes: "2",
        recup_stocks: "3",
        envoi_stocks: "3",
        recup_produits: "1",
      },
      {
        id: 1,
        service: "Amazon",
        nom: "Test",
        recup_commandes: "3",
        cloture_commandes: "2",
        envoi_commandes: "2",
        acc_commandes: "3",
        recup_stocks: "3",
        envoi_stocks: "1",
        recup_produits: "2",
      },
    ]);
    interface deleted_service {
      _id: string,
      delete: boolean
    }
    const notification = useNotification();
    const shop_id = ref(store.state.user.firstShop);
    const actions = ref(true);
    const selectedRows = ref([]);
    function configConfirmed(new_config){
      //console.log('Config confirmed', new_config)
      headers.value = new_config;
    }
    function loadData() {
      datatable.value.loadData();
    }
    function serviceName(id) {
      const service = services.value.find((item) => item.id == id);
      return service?.value;
    }
    function deleteService(item) {
      Swal.fire({
        title: "Are you sure?",
        text: "The service will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {

          const form = {
            external_services: [] as deleted_service[]
          };
          form.external_services.push({
            _id: item._id,
            delete: true
          })
          ShopRequest.editShop(shop_id.value, form)
            .then((response) => {
              notification.notify({
                title: "Service successfully deleted",
                type: "success"
              });
              loadData();
            })
        }
      })
    }

    function getProducts() {
      /*if (selectedRows.value.length > 0) {
        selectedRows.value.forEach((item: any) => {
          ExternalServices.getExternalServicesProducts(item._id).then((response) => {
            console.log(response);
          })
        })
      } else {
        Swal.fire({
          title: "No Rows Selected",
          text: "Please select at least one row before",
          icon: "warning",
          
        })
      }*/
      // Show loading spinner


      if (selectedRows.value.length > 0) {
        Swal.fire({
          title: 'Loading...',
          allowOutsideClick: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        const promises = selectedRows.value.map((item: any) => {
          return ExternalServices.getExternalServicesProducts(item._id)
            .then((response: any) => {
             
              if (response.data.success == true) {
                return { success: true, message: `Request for ${item._id} succeeded :` };
              } else {
                //return { success: false, message: `Request for ${item._id} failed: ${response.data.message}` };
                return { success: false, message: `Request for ${item._id} failed: ` };
              }
              // Process the response as needed
            })
            .catch((error) => {
              // Handle errors

            });
        });

        Promise.all(promises)
          .then((statuses) => {

            Swal.close();
            statuses_data.value = statuses;

            statusModal.value = true;
          })
          .catch((error) => {
            // Handle errors that may occur during Promise.all
            console.error('Error processing requests:', error);
          });
      } else {
        Swal.fire({
          title: 'No Rows Selected',
          text: 'Please select at least one row before',
          icon: 'warning',
        });
      }


    }

    function getSelectedRow(event) {
      selectedRows.value = event;
    }
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      headers,
      rows,
      actions,
      url,
      params,
      loadData,
      datatable,
      serviceName,
      deleteService,
      store,
      selectedRows,
      getSelectedRow,
      getProducts,
      statusIconHtml,
      statusModal,
      statuses_data,
      configConfirmed
    };
  },
});
