
import { defineComponent, reactive } from 'vue'
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default defineComponent({
    setup() {

        const form = reactive({
            placement: "",
            puid: ""
        });

        const rules = {
            placement: { required },
            puid: { required }
        };
        const v$ = useVuelidate(rules, form)

        function submit(){
            console.log('submit');
        }
        return {
            form,
            v$,
            submit
        }
    }
})
