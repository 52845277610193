<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45.19"
    height="64.15"
    viewBox="0 0 63.524 90.18"
  >
    <g
      id="Groupe_458"
      data-name="Groupe 458"
      transform="translate(1836 -1151.439)"
      opacity="0.46"
    >
      <path
        id="Tracé_334"
        data-name="Tracé 334"
        d="M-1794.451,1254.193v-2.743h3.072v3.331l-.523.1a.834.834,0,0,0,.154.078c3.048.738,6.085,1.529,9.148,2.2a3.236,3.236,0,0,1,1.587,5.583c-1.571,1.447-3.057,2.985-4.636,4.538,1.324,1.312,2.583,2.554,3.835,3.8q2.7,2.7,5.392,5.407a1.828,1.828,0,0,1-.019,2.806c-1.813,1.82-3.658,3.609-5.43,5.468a2.106,2.106,0,0,1-3.327-.023c-2.811-2.9-5.694-5.727-8.55-8.582-.1-.1-.211-.187-.332-.293a2.338,2.338,0,0,0-1.154,1.824,1.608,1.608,0,0,1-1.642,1.434,1.768,1.768,0,0,0-.878.385,22.137,22.137,0,0,1-1.8,1.546,3.079,3.079,0,0,1-4.38-1.4.778.778,0,0,0-.856-.554q-11.028.025-22.056.012-3.584,0-7.167,0a1.752,1.752,0,0,1-1.987-1.992c.005-4.512.033-9.023.032-13.535,0-5.861-.023-11.721-.033-17.582q0-2.845,0-5.69c0-1.393.632-2.038,2-2.038q18.411,0,36.821,0c1.387,0,2.009.634,2.009,2.024,0,4.431.008,8.861,0,13.292C-1795.17,1254.055-1795.052,1254.293-1794.451,1254.193Zm-38.1,21.46h27.51c-.018-.133-.021-.214-.04-.292q-1.129-4.663-2.26-9.325c-.85-3.525-1.673-7.057-2.548-10.576a3.182,3.182,0,0,1,3.723-3.981c1.653.352,3.285.8,4.928,1.2.864.209,1.732.4,2.646.609v-11.552h-33.959Zm31.59,2.188c.163-.155.235-.222.3-.291,1.774-1.771,3.539-3.55,5.324-5.31a1.662,1.662,0,0,1,2.459-.027c1.628,1.6,3.237,3.229,4.854,4.845l4.41,4.405,3.734-3.585c-.257-.277-.482-.534-.723-.775q-4.281-4.277-8.563-8.552a1.675,1.675,0,0,1,.008-2.495q2.46-2.466,4.927-4.924c.2-.2.372-.43.6-.7l-22.885-5.537Z"
        transform="translate(0 -62.535)"
      />
      <path
        id="Tracé_335"
        data-name="Tracé 335"
        d="M-1820.654,1416.885l2.329,2.342-13.564,13.571-2.359-2.342Z"
        transform="translate(-1.262 -191.179)"
      />
      <path
        id="Tracé_336"
        data-name="Tracé 336"
        d="M-1815.3,1401.26l6.7-6.694,2.25,2.311-6.723,6.666Z"
        transform="translate(-14.91 -175.105)"
      />
      <path
        id="Tracé_337"
        data-name="Tracé 337"
        d="M-1620.951,1154.817v-3.283c2.866-.356,3.616.312,3.284,3.283Z"
        transform="translate(-154.882 -0.006)"
      />
      <path
        id="Tracé_338"
        data-name="Tracé 338"
        d="M-1620.96,1285.332h3.283a15.684,15.684,0,0,1-.021,2.14,1.493,1.493,0,0,1-1.42,1.228,13.663,13.663,0,0,1-1.842.017Z"
        transform="translate(-154.876 -96.432)"
      />
      <path
        id="Tracé_339"
        data-name="Tracé 339"
        d="M-1751.545,1151.509v3.307h-3.271C-1755.181,1152.014-1754.586,1151.172-1751.545,1151.509Z"
        transform="translate(-58.401)"
      />
      <path
        id="Tracé_340"
        data-name="Tracé 340"
        d="M-1640.088,1288.71h-3.055v-3.37h3.055Z"
        transform="translate(-138.899 -96.438)"
      />
      <path
        id="Tracé_341"
        data-name="Tracé 341"
        d="M-1643.123,1151.622h3.062v3.312h-3.062Z"
        transform="translate(-138.913 -0.132)"
      />
      <path
        id="Tracé_342"
        data-name="Tracé 342"
        d="M-1728.559,1151.65v3.307h-3.046v-3.307Z"
        transform="translate(-75.187 -0.152)"
      />
      <path
        id="Tracé_343"
        data-name="Tracé 343"
        d="M-1684.378,1151.685v3.293h-3.062v-3.293Z"
        transform="translate(-106.995 -0.177)"
      />
      <path
        id="Tracé_344"
        data-name="Tracé 344"
        d="M-1754.76,1222.022v-3.037h3.312v3.037Z"
        transform="translate(-58.51 -48.648)"
      />
      <path
        id="Tracé_345"
        data-name="Tracé 345"
        d="M-1620.92,1222.022v-3.014h3.3v3.014Z"
        transform="translate(-154.904 -48.665)"
      />
      <path
        id="Tracé_346"
        data-name="Tracé 346"
        d="M-1662.2,1288.75h-3.009V1285.4h3.009Z"
        transform="translate(-123.009 -96.483)"
      />
      <path
        id="Tracé_347"
        data-name="Tracé 347"
        d="M-1706.446,1154.972h-3.016v-3.325h3.016Z"
        transform="translate(-91.135 -0.15)"
      />
      <path
        id="Tracé_348"
        data-name="Tracé 348"
        d="M-1620.959,1177.881V1174.9h3.315v2.986Z"
        transform="translate(-154.876 -16.894)"
      />
      <path
        id="Tracé_349"
        data-name="Tracé 349"
        d="M-1617.644,1244.156h-3.307v-3.01h3.307Z"
        transform="translate(-154.882 -64.609)"
      />
      <path
        id="Tracé_350"
        data-name="Tracé 350"
        d="M-1617.608,1266.339h-3.3v-3.033h3.3Z"
        transform="translate(-154.913 -80.569)"
      />
      <path
        id="Tracé_351"
        data-name="Tracé 351"
        d="M-1662.188,1154.952h-3.024v-3.339h3.024Z"
        transform="translate(-123.004 -0.125)"
      />
      <path
        id="Tracé_352"
        data-name="Tracé 352"
        d="M-1754.694,1174.834h3.285v3.011h-3.285Z"
        transform="translate(-58.558 -16.85)"
      />
      <path
        id="Tracé_353"
        data-name="Tracé 353"
        d="M-1620.944,1196.8h3.311v3.028h-3.311Z"
        transform="translate(-154.887 -32.669)"
      />
      <path
        id="Tracé_354"
        data-name="Tracé 354"
        d="M-1751.436,1196.889v3.005h-3.317v-3.005Z"
        transform="translate(-58.515 -32.734)"
      />
    </g>
  </svg>
</template>