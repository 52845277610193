
import { defineComponent,onMounted } from "vue";

export default defineComponent({
  setup() {
    onMounted(() => {
      window.scrollTo(0, 0);
    })
    return {};
  },
});
