import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "18.613",
  height: "18.597",
  viewBox: "0 0 22.613 22.597"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Tracé_356",
      "data-name": "Tracé 356",
      d: "M-1744.847,1224.031a8.9,8.9,0,0,1-7.505,1.222,8.8,8.8,0,0,1-5.9-11.373,8.848,8.848,0,0,1,11.8-5.2,8.762,8.762,0,0,1,4.331,12.107,1.4,1.4,0,0,0,.364,2.026c1.672,1.568,3.263,3.224,4.882,4.849.887.891.979,1.8.267,2.485-.669.646-1.578.549-2.415-.285C-1740.946,1227.948-1742.862,1226.02-1744.847,1224.031Zm-5.005-14a6.777,6.777,0,0,0-6.838,6.687,6.8,6.8,0,0,0,6.692,6.845,6.8,6.8,0,0,0,6.837-6.7A6.774,6.774,0,0,0-1749.852,1210.032Z",
      transform: "translate(1758.745 -1207.977)",
      fill: _ctx.fill ? _ctx.fill : '#192440',
      opacity: "0.29"
    }, null, 8, _hoisted_2)
  ]))
}