
import { defineComponent, onMounted} from "vue";
import OrderStatusNotification from '@/components/ConfigurationComponents/NotificationsAndLabels/OrderStatusNotification.vue';
import OrderToCarrierMappingRules from '@/components/ConfigurationComponents/NotificationsAndLabels/OrderToCarrierMappingRules.vue';
export default defineComponent({
  components:{
    OrderStatusNotification,
    OrderToCarrierMappingRules
  },
  setup() {
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {};
  },
});
