import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Dashboard from '../Dashboard.vue';
import Home from '../views/Home.vue';
import OrdersList from '../views/Orders/OrdersList.vue';
import NewOrder from '../views/Orders/NewOrder.vue';
import EditOrder from '../views/Orders/EditOrder.vue';
import Preparation from '../views/Preparations/PreparationsList.vue';
import ProductsList from '../views/Products/ProductsList.vue';
import NewProduct from '../views/Products/NewProduct.vue';
import EditProduct from '../views/Products/EditProduct.vue';
import LocationAssociation from '@/views/Products/LocationAssociation.vue';
import Tickets from '../views/Tickets.vue';
import Invoicing from '../views/Invoicing.vue';
import Account from '../views/Configuration/Account.vue';
import ShopsList from '../views/Shops/ShopsList.vue';
import NewShop from '../views/Shops/NewShop.vue';
import Step1 from '../views/ScanCode/Step1.vue';
import OrderPreparation from '../views/ScanCode/OrderPreparation.vue';
import Picking from '../views/ScanCode/Picking.vue';
import QrCodeReader from '../views/ScanCode/QrCodeReader.vue';
import Login from '../views/Authentication/Login.vue';
import Register from '../views/Authentication/Register.vue';
import ExternalServices from '../views/Configuration/ExternalServices/index.vue';
import EditExternalServices from '../views/Configuration/ExternalServices/edit.vue';
import NotificationsAndLabels from '../views/Configuration/NotificationsAndLabels/index.vue';
import NewStatusNotification from '../views/Configuration/NotificationsAndLabels/OrderStateNotification.vue';
import ScanBarCode from '../views/GenerateLabel/index.vue';
import GenerateLabel from '../views/GenerateLabel/generate-label.vue';
import Packs from '../views/Products/Packs/index.vue';
import NewPack from '../views/Products/Packs/NewPack.vue';
import EditPack from '../views/Products/Packs/EditPack.vue';
import Transporters from '../views/Configuration/Transporters/index.vue';
import EditTransporter from '../views/Configuration/Transporters/edit.vue';
import ReturnedOrderDetails from '@/components/OrderComponents/ReturnedOrderDetails.vue';
import Logistics from '@/views/Configuration/Logistics/index.vue';
import Exports from '@/views/Configuration/exports/index.vue';

import store from "../store";
import Cookies from "js-cookie";
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'Home',
        component: Home
      },
      {
        path: 'orders/orders-list',
        name: 'OrdersList',
        component: OrdersList
      },
      {
        path: 'orders/new-order',
        name: 'NewOrder',
        component: NewOrder
      },
      {
        path: 'orders/:id/edit',
        name:'EditOrder',
        component: EditOrder
      },
      {
        path: 'orders/:id/edit/returned-order-details',
        name:'ReturnedOrderDetails',
        component: ReturnedOrderDetails
      },
      {
        path: 'preparations',
        name: 'Preparations',
        component: Preparation
      },
      {
        path: 'products/products-list',
        name: 'ProductsList',
        component: ProductsList
      },
      {
        path: 'products/new-product',
        name: 'NewProduct',
        component: NewProduct
      },
      {
        path: 'products/location-association',
        name: 'LocationAssociation',
        component: LocationAssociation
      },
      {
        path: 'products/packs',
        name: 'Packs',
        component: Packs
      },
      {
        path: 'products/packs/new-pack',
        name: 'NewPack',
        component: NewPack
      },
      {
        path: 'products/packs/:id/edit',
        name:'EditPack',
        component: EditPack
      },
      {
        path: 'products/:id/edit',
        name:'EditProduct',
        component: EditProduct
      },
      {
        path: 'tickets',
        name: 'Tickets',
        component: Tickets
      },
      {
        path: 'shops/shops-list',
        name: 'ShopsList',
        component: ShopsList
      },
      {
        path: 'shops/new-shop',
        name: 'NewShop',
        component: NewShop
      },
      {
        path: 'invoicing',
        name: 'Invoicing',
        component: Invoicing
      },
      {
        path: 'configutaion/account',
        name: 'Account',
        component: Account
      },
      {
        path: 'configuration/external-services',
        name: 'ExternalServices',
        component: ExternalServices
      },
      {
        path: 'configuration/external-services/:id/edit',
        name: 'EditExternalServices',
        component: EditExternalServices
      },
      {
        path: 'generate-label',
        name: 'ScanBarCode',
        component: ScanBarCode
      },
      {
        path: 'generate-label/:id',
        name: 'GenerateLabel',
        component: GenerateLabel
      },
      {
        path: 'configuration/notifications-labels',
        name: 'NotificationsAndLabels',
        component: NotificationsAndLabels
      },
      {
        path: 'configuration/status-notifications/new',
        name: 'NewStatusNotification',
        component: NewStatusNotification
      },
      {
        path: 'configuration/transporters',
        name: 'Transporters',
        component: Transporters
      },
      {
        path: 'configuration/transporters/:id/edit',
        name: 'EditTransporter',
        component: EditTransporter
      },
      {
        path: 'configuration/logistics',
        name: 'Logistics',
        component: Logistics
      },
      {
        path: 'configuration/exports',
        name: 'Exports',
        component: Exports
      }
    ],
    beforeEnter: (to, from, next) => {
      if(Cookies.get("access_token")){
        store.dispatch('getCurrentUser').then(() => {
          next();
        })
       
      }else{
        next({name: "Login"});
      }
    }
  },
  {
    path: '/preparation/step1',
    name: 'Step1',
    component: Step1
  },
  {
    path: '/preparation/order-preparation',
    name: 'OrderPreparation',
    component: OrderPreparation
  },
  {
    path: '/preparation/picking',
    name: 'Picking',
    component: Picking
  },
  {
    path: '/preparation/QrCodeReader',
    name: 'QrCodeReader',
    component: QrCodeReader
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if(Cookies.get("access_token")){
        store.dispatch('getCurrentUser')
        next({name: "Home"});
      }else{
        next();
      }
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter: (to, from, next) => {
      if(Cookies.get("access_token")){
        store.dispatch('getCurrentUser')
        next({name: "Home"});
      }else{
        next();
      }
    }
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
