<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="64"
    viewBox="0 0 36 64"
  >
    <text
      id="_"
      data-name="%"
      transform="translate(0 51)"
      fill="#fff"
      font-size="49"
      font-family="Roboto-Medium, Roboto"
      font-weight="500"
    >
      <tspan x="0" y="0">%</tspan>
    </text>
  </svg>
</template>