
import { defineComponent } from "vue";
import { BIconPencilSquare,BIconTrash, BIconPlus } from "bootstrap-icons-vue";
export default defineComponent({
  components: {
    BIconPencilSquare,
    BIconTrash,
    BIconPlus
  },
  setup() {
    return {};
  },
});
