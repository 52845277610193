
import { defineComponent, ref } from 'vue'
import {useRoute} from 'vue-router';

export default defineComponent({
    setup () {
        const route = useRoute();
        const total_items = ref(0);
        const page = ref(1);
        const messages = ref([
            {
                niveau: 'Information',
                date: 'mar, 10 juin 2021 15:30',
                email: 'test@test.com',
                title: 'Coumande découpée',
                message: 'Commande découpée depuis la commande 145875236'
            },
            {
                niveau: 'Information',
                date: 'mar, 10 juin 2021 15:30',
                email: 'test@test.com',
                title: 'Coumande découpée',
                message: 'Commande découpée depuis la commande 145875236'
            }
        ]);

        function getMessages(){
            console.log('get Messages');
        }    
        function changePage() {
            getMessages();
        }

        return {
            route,
            messages,
            page,
            total_items,
            changePage,
            getMessages
        }
    }
})
