<template>
  <div class="mb-3">
    <div class="col bg-white py-3 px-4">
      <div class="mb-3">
        <label for="formGroupExampleInput" class="form-label fw-normal"
          >Organization</label
        >
        <input
          type="text"
          class="form-control"
          id="formGroupExampleInput"
          placeholder="Organization"
        />
      </div>
      <div class="row g-3 mb-3 flex-column flex-sm-row">
        <div class="col">
          <label for="formGroupExampleInput" class="form-label fw-normal"
            >Registration number</label
          >
          <input type="text" class="form-control" placeholder="123456" />
        </div>
        <div class="col">
          <label for="formGroupExampleInput" class="form-label fw-normal"
            >Tax identification number</label
          >
          <input type="text" class="form-control" placeholder="123456" />
        </div>
      </div>
      <div class="mb-3">
        <label for="formGroupExampleInput2" class="form-label fw-normal"
          >Street</label
        >
        <input
          type="text"
          class="form-control"
          id="formGroupExampleInput2"
          placeholder=""
        />
      </div>
      <div class="row g-3 mb-3 flex-column flex-sm-row">
        <div class="col">
          <label for="formGroupExampleInput" class="form-label fw-normal"
            >Zip code</label
          >
          <input type="text" class="form-control" placeholder="123456" />
        </div>
        <div class="col">
          <label for="formGroupExampleInput" class="form-label fw-normal"
            >City</label
          >
          <input type="text" class="form-control" placeholder="123456" />
        </div>
        <div class="col">
          <label for="formGroupExampleInput" class="form-label fw-normal"
            >Country</label
          >
          <input type="text" class="form-control" placeholder="123456" />
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-center">
        <button
          class="
            btn
            lgmk-bg-color
            shadow-none
            text-white
            rounded-0
            w-100 w-sm-auto
            px-5
          "
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/css/vars.scss";
input[type="text"],
input[type="number"],
input[type="email"] {
  height: $input-height;
  border: 0;
  background-color: $main-bg-color;
  box-shadow: none;
  border-radius: 0;
}

@media screen and (min-width: 576px) {
  .w-sm-auto {
    width: auto !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
}
</style>