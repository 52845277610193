import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12.303",
  height: "12.317",
  viewBox: "0 0 12.303 12.317"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      id: "Tracé_313",
      "data-name": "Tracé 313",
      d: "M2271.227,1125.608l.882-.882q1.559-1.56,3.119-3.12a1.252,1.252,0,1,1,1.76,1.779l-3.922,3.923c-.025.025-.049.052-.085.091.269.265.536.527.8.791,1.057,1.056,2.107,2.12,3.173,3.167a1.272,1.272,0,0,1-.1,1.945,1.246,1.246,0,0,1-1.615-.114c-.642-.629-1.273-1.269-1.908-1.9l-2.077-2.078c-.011-.011-.023-.021-.052-.046-.128.132-.255.265-.384.395q-1.8,1.8-3.606,3.606a1.249,1.249,0,0,1-1.813-1.719c.472-.491.959-.966,1.441-1.448q.842-.842,1.684-1.683c.236-.236.476-.468.713-.7.068-.068.132-.139.21-.222-.2-.2-.4-.394-.6-.59q-1.7-1.7-3.4-3.4a1.249,1.249,0,0,1,.45-2.08,1.173,1.173,0,0,1,1.285.28c1.214,1.2,2.418,2.414,3.627,3.621C2270.942,1125.348,2271.083,1125.473,2271.227,1125.608Z",
      transform: "translate(-2265.062 -1121.231)",
      fill: _ctx.fill ? _ctx.fill : '#303e6b'
    }, null, 8, _hoisted_2)
  ]))
}