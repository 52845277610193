<template>
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="22px"
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#000000"
      stroke="none"
    >
      <path
        d="M960 5106 c-147 -41 -267 -162 -306 -310 -20 -76 -20 -4396 0 -4472
41 -155 171 -280 324 -313 74 -16 3088 -16 3164 0 157 33 293 170 327 327 8
38 11 557 11 1846 l0 1793 -29 39 c-35 46 -1055 1064 -1091 1088 -22 15 -127
16 -1190 15 -900 -1 -1175 -4 -1210 -13z m2240 -543 c0 -263 4 -361 14 -399
41 -155 171 -280 324 -313 33 -7 179 -11 392 -11 l340 0 0 -1711 c0 -1276 -3
-1722 -12 -1757 -16 -64 -86 -134 -150 -150 -67 -17 -3029 -17 -3096 0 -64 16
-134 86 -150 150 -17 67 -17 4309 0 4376 15 60 87 135 144 150 26 6 405 10
1117 11 l1077 1 0 -347z m673 -511 c-294 -4 -328 2 -393 67 -65 65 -70 92 -70
388 l0 248 350 -350 350 -350 -237 -3z"
      />
      <path
        d="M1530 4342 c-16 -16 -32 -45 -35 -65 -5 -25 -13 -37 -30 -41 -40 -10
-122 -94 -152 -154 -24 -48 -28 -70 -28 -137 0 -67 4 -89 28 -137 49 -100 139
-162 255 -177 80 -10 106 -24 127 -67 19 -40 19 -48 -1 -89 -25 -53 -63 -65
-208 -65 -71 0 -136 -5 -150 -11 -60 -28 -71 -122 -20 -170 21 -19 38 -24 98
-27 l73 -4 17 -38 c20 -46 72 -75 116 -65 39 9 78 50 86 92 5 26 15 38 45 53
53 27 104 81 136 143 24 45 28 63 28 137 -1 77 -4 91 -34 148 -53 98 -139 156
-248 168 -77 9 -111 28 -129 71 -19 43 -5 93 33 123 23 18 41 20 155 20 89 0
139 4 163 14 35 15 65 59 65 96 0 37 -30 81 -65 96 -19 8 -59 14 -90 14 -53 0
-55 1 -69 34 -29 71 -114 90 -166 38z"
      />
      <path
        d="M2379 3381 c-49 -49 -36 -134 25 -166 28 -14 95 -16 703 -13 l671 3
26 24 c51 48 40 142 -20 170 -18 8 -224 11 -700 11 l-676 0 -29 -29z"
      />
      <path
        d="M1333 2758 c-57 -28 -67 -122 -17 -169 l26 -24 1218 0 1218 0 26 24
c51 48 40 142 -20 170 -34 15 -2419 14 -2451 -1z"
      />
      <path
        d="M1333 2118 c-57 -28 -67 -122 -17 -169 l26 -24 1218 0 1218 0 26 24
c51 48 40 142 -20 170 -34 15 -2419 14 -2451 -1z"
      />
      <path
        d="M1333 1478 c-57 -28 -67 -122 -17 -169 l26 -24 1218 0 1218 0 26 24
c51 48 40 142 -20 170 -34 15 -2419 14 -2451 -1z"
      />
      <path
        d="M1333 838 c-57 -28 -67 -122 -17 -169 l26 -24 671 -3 c493 -2 679 1
700 9 61 26 77 121 28 170 l-29 29 -679 -1 c-448 0 -685 -4 -700 -11z"
      />
    </g>
  </svg>
</template>