import axios from "@/plugins/axios";

const PackRequest = {
    newPack(form){
        return new Promise((resolve, reject) => {
            axios.post('packs', form)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    },
    getPackProducts(id: any, page){
        return new Promise((resolve, reject) => {
            axios.get(`/packs/products/${id}?_page=${page}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    },
    editPack(id:any, form){
        return new Promise((resolve, reject) => {
            axios.patch(`packs/${id}`, form)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    },
    getChangesHistory(id: any, page){
        return new Promise((resolve, reject) => {
            axios.get(`packs/auditlogs/${id}?_page=${page}`)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
        }); 
    },
    getPackById(id){
        return new Promise((resolve, reject) =>{
            axios.get(`/packs/${id}`)
            .then((response) => resolve(response))
            .catch((err) => reject(err))
        })
    },
    deletePack(id){
        return new Promise((resolve, reject) => {
            axios.delete(`packs/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        })
    },
}

export default PackRequest;