
import { defineComponent, ref } from 'vue';
import AddIcon from "@/components/icons/AddIcon.vue";
import { BIconX, BIconTrash, BIconArrowLeftRight } from 'bootstrap-icons-vue';

export default defineComponent({
    components: {
        AddIcon,
        BIconX,
        BIconTrash,
        BIconArrowLeftRight
    },
    setup() {
        const details = ref({
            shipping_address: {
                name: 'Wassila Gonzalez',
                address: "34 avenue du general gallieni 10300 saint savine, FR",
                email: "wassila.a@gmail.com",
                phone: "+33658526298"
            },
            order_initiator: "gestion@elogise.fr",
            creation_date: "11/02/2024",
            reason: "test",
            back_in_stock_on: "",
            source_ref: "4785693",
            return_type: "Client return"
        });

        const products = ref([
            {
                id: '1',
                product: 'xxxx-1',
                title: 'Private Trip',
                quantity: 1
            },
            {
                id: '2',
                product: 'xxxx-2',
                title: 'Music Tahara',
                quantity: 1
            },
            {
                id: '3',
                product: 'xxxx-3',
                title: 'Bamboo Cotons',
                quantity: 1
            }

        ])
        return {
            details,
            products
        }
    }
})
