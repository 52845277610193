
import { defineComponent, ref } from 'vue'
import AddIcon from "@/components/icons/AddIcon.vue";
import draggable from 'vuedraggable';
import { BIconScissors, BIconDownload } from 'bootstrap-icons-vue';
export default defineComponent({
    components: {
        AddIcon,
        draggable,
        BIconScissors,
        BIconDownload
    },
    setup() {
        interface Product {
            name: string,
            quantity: number,
            sku: string
        }
        interface sub_order_product {
            id: number,
            products: Product[]
        }
        const products = ref([
            {
                name: "Product 1",
                quantity: 2,
                sku: "SKU1"
            },
            {
                name: "Product 2",
                quantity: 3,
                sku: "SKU2"
            },
            {
                name: "Product 3",
                quantity: 5,
                sku: "SKU3"
            }
        ]);
        const sub_orders = ref<sub_order_product[]>([
            {
                id: 1,
                products: []
            }
        ]);
        function retrieveRemainingProducts(subOrderIndex) {
            const subOrder = sub_orders.value[subOrderIndex];
            const remainingProducts = products.value.filter(product => {
                return !subOrder.products.some(subProduct => subProduct.sku === product.sku);
            });

            // Ajouter les produits restants dans le sous-ordre spécifique
            subOrder.products.push(...remainingProducts);

            // Vous pouvez également supprimer les produits récupérés de l'array 'products' si nécessaire
            products.value = products.value.filter(product => !remainingProducts.includes(product));
        }
        function increment(item, type, index) {
            console.log(item);
            let maxQuantityItem: Product | null = null;
            let matchingProductsLenght = 0;
            let index_of_removed_item: number | null = null;

            let sum = 0;

            console.log(sum);
            // if (existingProduct) {
            if (type == 'order') {
                // Find the product with the same SKU and calculate the sum of quantities
                products.value.forEach(product => {
                    if (product.sku === item.sku) {
                        sum += product.quantity;
                    }
                });

                products.value.forEach((element, index) => {
                    if (element.sku === item.sku) {
                        matchingProductsLenght++;
                        //sum += element.quantity;
                        if (!maxQuantityItem || element.quantity > maxQuantityItem.quantity) {
                            maxQuantityItem = element;
                            if (element.quantity < sum /*> existingProduct.quantity*/) {
                                element.quantity++;
                            }
                        }

                        if (matchingProductsLenght > 1) {
                            index_of_removed_item = index;
                        }
                    }
                });

                if (matchingProductsLenght > 0 && index_of_removed_item != null) {
                    products.value.splice(index_of_removed_item, 1);
                }
            } else if (type == 'sub_order') {
                // Find the product with the same SKU and calculate the sum of quantities
                sub_orders.value[index].products.forEach(product => {
                    if (product.sku === item.sku) {
                        sum += product.quantity;
                    }
                });

                sub_orders.value[index].products.forEach((element, index) => {
                    if (element.sku === item.sku) {
                        matchingProductsLenght++;
                        //sum += element.quantity;
                        if (!maxQuantityItem || element.quantity > maxQuantityItem.quantity) {
                            maxQuantityItem = element;
                            if (element.quantity < sum) {
                                element.quantity++;
                            }
                        }
                    }

                    if (matchingProductsLenght > 1) {
                        index_of_removed_item = index;
                    }
                });

                if (matchingProductsLenght > 0 && index_of_removed_item != null) {
                    sub_orders.value[index].products.splice(index_of_removed_item, 1);
                }
            }

            console.log(sum);

            // }
        }
        function decrement(item, type, index) {
            if (item.quantity > 1) {
                item.quantity--;

                // Create a new product with a quantity of 1
                const newProduct = {
                    name: item.name,
                    quantity: 1,
                    sku: item.sku // Generate a new SKU for the new product
                };

                // Add the new product to the products array
                if (type == 'order') {
                    products.value.push(newProduct);
                } else if (type == 'sub_order') {
                    sub_orders.value[index].products.push(newProduct);
                }

            }
        }

        function log(evt) {
            window.console.log(evt);
        }

        function dragend(event, type) {
            /*console.log(event, type);
            console.log(event.newIndex)*/
            //console.log(type);

            let new_index = event.newIndex;
            let products_length = 0;
            if (type == 'sub_order') {

                const product = products.value.find((element, index) => index == new_index);
                if (product && product.quantity > 1) {
                    products.value.forEach((element, index) => {
                        //console.log(element);
                        if (product?.sku == element.sku) {
                            products_length++;
                        }
                    });
                    console.log('products length : ' + products_length);
                    if (products_length > 1) {
                        // get the product with greater quantity and add the quatity of the element dragged
                        /*let max = Math.max(...products.value.map(product => product.quantity));
                        console.log('max: '+max);*/
                        // Initialize variables to track maximum quantity and corresponding object
                        let maxQuantity = -Infinity;
                        let objectWithMaxQuantity: Product | null = null;

                        // Iterate through the array
                        for (let i = 0; i < products.value.length; i++) {
                            let currentObject = products.value[i];

                            // Check if the current object's quantity is greater than the maximum quantity found so far
                            if (product?.sku == currentObject.sku && currentObject.quantity > maxQuantity) {
                                maxQuantity = currentObject.quantity;
                                objectWithMaxQuantity = currentObject;
                            }
                        }
                        if(objectWithMaxQuantity){
                            objectWithMaxQuantity.quantity += product?.quantity;
                        }
                        
                        //objectWithMaxQuantity.quantity += product.quantity
                        console.log('Object with maximum quantity:', objectWithMaxQuantity);

                    }
                }

            } /*else if (type == 'order') {
                sub_orders.value[new_index].products.forEach((element, index) => {
                    //console.log(product);
                    if (index == new_index) {
                        console.log(element);
                    }
                });
            }*/

            
            // console.log(event);
        }

        function dragstart(event) {
            //console.log(event);
        }

        function addSubOrder() {
            sub_orders.value.push({
                id: sub_orders.value.length + 1,
                products: []
            });
        }

        function removeSubOrder(event) {
            console.log(event)
        }

        function splitingOrder(product, type, index) {

            const originalQuantity = product.quantity;

            // Loop to split the order until the original quantity becomes 1
            while (product.quantity > 1) {
                // Create a new product with quantity 1
                const newProduct = {
                    name: product.name,
                    quantity: 1,
                    sku: product.sku
                };

                // Decrease the quantity of the original product
                product.quantity -= 1;

                // Add the new product to the products array
                if (type == 'order') {
                    products.value.push(newProduct);
                } else if (type == 'sub_order') {
                    sub_orders.value[index].products.push(newProduct);
                }

            }

            // Adjust the quantity of the original product to 1
            if (originalQuantity > 1) {
                product.quantity = 1;
            }
        }

        return {
            products,
            sub_orders,
            increment,
            decrement,
            log,
            dragend,
            dragstart,
            addSubOrder,
            removeSubOrder,
            splitingOrder,
            retrieveRemainingProducts
        }
    }
})
