<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="11"
    viewBox="0 0 9 11"
  >
    <path
      id="Polygone_2"
      data-name="Polygone 2"
      d="M5.5,0,11,9H0Z"
      transform="translate(0 11) rotate(-90)"
      fill="#192440"
    />
  </svg>
</template>