
import { computed, defineComponent, onMounted, ref } from "vue";
import ApiAccess from "@/components/ConfigurationComponents/ExternalServices/ApiAccess.vue";
import OrderRecovery from "@/components/ConfigurationComponents/ExternalServices/OrderRecovery.vue";
import OrderStatusUpdate from "@/components/ConfigurationComponents/ExternalServices/OrderStatusUpdate.vue";
import StockUpdate from "@/components/ConfigurationComponents/ExternalServices/StockUpdate.vue"
import ExternalServices from "@/services/requests/external-services";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
  components: {
    ApiAccess,
    OrderRecovery,
    OrderStatusUpdate,
    StockUpdate
  },
  setup() {
    const dataLoaded = ref(false);
    const tabs = ['Accés API', 'Récuperation des commandes', "Mise a jour de l'état des commandes", 'Mise a jour du stock'];
    const activeTab = ref(0);
    const tabComponents = computed(() => {
      return [ApiAccess, OrderRecovery, OrderStatusUpdate, StockUpdate];
    });
    
    const route = useRoute();
    const store = useStore();
    function getServiceDetails(){
      let id = route.params.id;
      ExternalServices.getServiceDetails(id)
      .then((response: any) => {
        if(response.data.data && response.data.data.service) {
          store.commit('setServiceName', response.data.data.service);
        }
        if(response.data.data && response.data.data.acces_api){
          store.commit('setAccessApi', response.data.data.acces_api);
        }
        if(response.data.data && response.data.data.recup_orders){
          store.commit('setRecupOrder', response.data.data.recup_orders);
        }
        if(response.data.data && response.data.data.update_orders){
          store.commit('setUpdateOrder', response.data.data.update_orders);
        }
        if(response.data.data && response.data.data.update_stock){
          store.commit('setUpdateStock', response.data.data.update_stock);
        }
        
        dataLoaded.value = true;
      }).catch((error) => {
        console.log(error);
      })
    }
    onMounted(() => {
      getServiceDetails();
    })
    return {
      getServiceDetails,
      tabComponents,
      activeTab,
      tabs,
      dataLoaded
    };
  },
});
