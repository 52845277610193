import axios from "@/plugins/axios";
const OrderRequest = {
    getOrderById(id: any) {
        return new Promise((resolve, reject) => {
            axios.get(`/orders/${id}`)
            .then(res => resolve(res))
            .catch(err => reject(err))
        });
    },
    addOrder(form){
        return new Promise((resolve, reject) => {
            axios?.post("orders", form)
            .then(res => resolve(res))
            .catch(err => reject(err))
        });
    },
    deleteOrder(id: any) {
        return new Promise((resolve, reject) => {
            axios?.delete(`orders/${id}`)
            .then(res => resolve(res))
            .catch(err => reject(err))
        })
    },
    editOrder(id,form) {
        return new Promise((resolve, reject) => {
            axios.patch(`orders/${id}`, form)
            .then(res => resolve(res))
            .catch(err => reject(err))
        });
    },
    getChangesHistory(id: any, page){
        return new Promise((resolve, reject) => {
            axios.get(`orders/auditlogs/${id}?_page=${page}`)
            .then((response) => resolve(response))
            .catch((err) => reject(err));
        }); 
    },
    generateLabel(url: string){
        return new Promise((resolve, reject) => {
            axios.get(url,{
                responseType: 'blob', // important
                })
            .then((response) => resolve(response))
            .catch((err) => reject(err))
        })
    },
    getDocuments(id: any, page){
        return new Promise((resolve, reject) => {
            axios.get(`orders/documents/${id}?_page=${page}`)
            .then((response) => resolve(response))
            .catch((err) => reject(err))
        })
    },
    getShipmentHistory(id: any, page){
        return new Promise((resolve, reject) => {
            axios.get(`orders/shipments/${id}?_page=${page}`)
            .then((response) => resolve(response))
            .catch((err) => reject(err))
        })
    },
    getTransporters(){
        return new Promise((resolve, reject) => {
            axios.get(`transporters/user`)
            .then(res => resolve(res))
            .catch(err => reject(err))
        });
    }    
};
  
export default OrderRequest;
