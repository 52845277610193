
import { defineComponent } from 'vue'

export default defineComponent({
  props:{
    width: null,
    height: null,
    fill: null
  },
  setup() {
    return{

    }
  },
})
