<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="18.667"
    height="19.256"
    viewBox="0 0 11.667 12.256"
  >
    <defs>
      <clipPath id="clip-path">
        <rect
          id="Rectangle_235"
          data-name="Rectangle 235"
          width="11.667"
          height="12.256"
          fill="#9d9d9d"
        />
      </clipPath>
    </defs>
    <g id="Groupe_471" data-name="Groupe 471" transform="translate(0 0)">
      <g
        id="Groupe_470"
        data-name="Groupe 470"
        transform="translate(0 0)"
        clip-path="url(#clip-path)"
      >
        <path
          id="Tracé_1010"
          data-name="Tracé 1010"
          d="M.01,57.852a5.69,5.69,0,0,1,3.348-5.258c2.289,1.866,2.888,1.869,4.9.02a5.562,5.562,0,0,1,3.392,5.238Z"
          transform="translate(0 -45.595)"
          fill="#9d9d9d"
        />
        <path
          id="Tracé_1011"
          data-name="Tracé 1011"
          d="M22.56,0c2.489-.006,4.115,2.978,2.868,5.264-.063.116-.134.229-.207.34C24.3,6.994,22.9,7.635,21.772,7.187a4,4,0,0,1-2.4-4.409A3.358,3.358,0,0,1,22.56,0"
          transform="translate(-16.734 0)"
          fill="#9d9d9d"
        />
      </g>
    </g>
  </svg>
</template>