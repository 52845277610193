<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30.57"
    height="32.57"
    viewBox="0 4 22 50"
  >
    <text
      id="_"
      data-name="+"
      transform="translate(0 40)"
      fill="currentColor"
      font-size="38"
      font-family="Roboto-Light, Roboto"
      font-weight="300"
    >
      <tspan x="0" y="0">+</tspan>
    </text>
  </svg>
</template>