
import { defineComponent, onMounted } from 'vue'
import InvoiceIcon from "@/components/icons/InvoiceIcon.vue";
export default defineComponent({
    components: {
        InvoiceIcon
    },
    setup() {

        onMounted(() => {
            console.log('invoices');
        })
        return{

        }
    },
})
