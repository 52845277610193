
import { defineComponent, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from 'vuex';
import DashboardIcon from "@/components/icons/DashboardIcon.vue";
import OrderIcon from "@/components/icons/OrderIcon.vue";
import ProductIcon from "@/components/icons/ProductIcon.vue";
import PreparationIcon from "@/components/icons/PreparationIcon.vue";
import TicketIcon from "@/components/icons/TicketIcon.vue";
import InvoicingIcon from "@/components/icons/InvoicingIcon.vue";
import ConfigurationIcon from "@/components/icons/ConfigurationIcon.vue";
import LogoIcon from "@/components/icons/LogoIcon.vue";
import ShopsIcon from "@/components/icons/ShopsIcon.vue";
import PreparationRequest from "@/services/requests/preparation";
export default defineComponent({
  components: {
    DashboardIcon,
    OrderIcon,
    ProductIcon,
    PreparationIcon,
    TicketIcon,
    InvoicingIcon,
    ConfigurationIcon,
    LogoIcon,
    ShopsIcon,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const pending_orders_count = ref('');
    const menuItems = ref([
      { title: "Dashboard", routeName: "Home", iconComponent: "DashboardIcon" },
      {
        title: "Orders",
        routeName: null,
        iconComponent: "OrderIcon",
        subMenu: [
          { title: "List of Orders", routeName: "OrdersList" },
          { title: "Create new order", routeName: "NewOrder" },
        ],
      },
      {
        title: "Preparation",
        routeName: "Preparations",
        iconComponent: "PreparationIcon",
      },
      {
        title: "Generate label",
        routeName: "ScanBarCode",
        iconComponent: "PreparationIcon",
      },
      {
        title: "Products",
        routeName: null,
        iconComponent: "ProductIcon",
        subMenu: [
          { title: "List of products", routeName: "ProductsList" },
          { title: "Packs", routeName: "Packs" },
          { title: "Create new product", routeName: "NewProduct" },
          { title: "Location Association", routeName: "LocationAssociation" },
        ],
      },
      { title: "Tickets", routeName: "Tickets", iconComponent: "TicketIcon" },
      {
        title: "Shops",
        routeName: null,
        iconComponent: "ShopsIcon",
        subMenu: [
          { title: "List of shops", routeName: "ShopsList" },
          { title: "Create new shop", routeName: "NewShop" },
        ],
      },
      {
        title: "Invoicing",
        routeName: "Invoicing",
        iconComponent: "InvoicingIcon",
      },
      {
        title: "Configuration",
        routeName: null,
        iconComponent: "ConfigurationIcon",
        subMenu: [
          { title: "Account", routeName: 'Account'},
          { title: "Service Externes", routeName: 'ExternalServices'},
          { title: "Notifications & labels", routeName: "NotificationsAndLabels"},
          { title: "Transporters", routeName: "Transporters"},
          { title: "Logistics", routeName: "Logistics"},
          { title: "Exports", routeName: "Exports"}
        ],
      },
    ]);
    interface subMenu {
      routeName: string;
    }
    const findCurrentRoute = (arr: []) => {
      return arr.find((el: subMenu) => el.routeName == route.name);
    };

    function getPendingOrdersCount(){
      PreparationRequest.getPendingOrdersCount()
      .then((response: any)=> {
        //console.log(response);
        pending_orders_count.value = response.data.data.totalCount;
      })
    }

    onMounted(() => {
      getPendingOrdersCount()
    })
    return {
      router,
      route,
      store,
      menuItems,
      findCurrentRoute,
      pending_orders_count
    };
  },
});
