
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useNotification } from "@kyvg/vue3-notification";
import ProductRequest from "@/services/requests/product";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash"; // Import Lodash
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import DragDrop from "@/components/icons/DragDropIcon.vue";
import Swal from "sweetalert2";

//import { Product } from "@/types";
export default defineComponent({
  components: {
    DragDrop
  },
  setup() {
    const loading = ref(false);
    const notification = useNotification();
    const route = useRoute();
    const router = useRouter();
    const initialForm = reactive({
      title: '',
      sku: '',
      stock: '',
      location: '',
      ean13: '',
      picture: '',
      sh_code: '',
      weight: '',
      remark: '',
      dimensions: {
        width: '',
        height: '',
        length: ''
      },
      details: {
        ref1: "",
        ref2: "",
        ref3: ""
      }
    });
    const form = reactive({ ...initialForm });
    const originalFormData: any = reactive({});
    const rules = {
      sku: { required }
    }
    const v$ = useVuelidate(rules, form)
    const image = ref("");

    function onDrop(e: any) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      createFile(files[0]);
    }
    function onChange(e: any) {
      var files = e.target.files;
      form.picture = files[0];
      createFile(files[0]);
    }
    function createFile(file: any) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      //var img = new Image();
      var reader = new FileReader();
      //var vm = this;

      reader.onload = function (e: any) {
        image.value = e.target.result;
      };
      reader.readAsDataURL(file);
    }
    function removeImage() {
      image.value = "";
      form.picture = ""
    }

    function findDifferences(obj1, obj2) {
      const differences = {};

      for (const key in obj2) {
        if (key === 'picture') {
          // Skip 'picture' when comparing objects
          continue;
        }

        if (Object.hasOwnProperty.call(obj2, key)) {
          if (Array.isArray(obj2[key])) {
            // If the property is an array, compare the array elements
            const newObjects = obj2[key].filter(item2 => !obj1[key].some(item1 => item1._id === item2._id));
            if (newObjects.length > 0) {
              differences[key] = newObjects;
            }
          } else if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key]) && obj1[key] !== undefined) {
            console.log(obj1[key], obj2[key])
            // If the property is an object, recursively compare it
            const nestedDifferences = findDifferences(obj1[key], obj2[key]);
            if (Object.keys(nestedDifferences).length > 0) {
              differences[key] = nestedDifferences;
            }
          } /*else if(typeof obj2[key] === 'object' && !Array.isArray(obj2[key]) && obj1[key] == undefined){
            differences[key] = obj2[key];
          } */
          else if (obj1[key] !== obj2[key]) {
            // If the property is not an object or array and has changed, track the difference
            differences[key] = obj2[key];
          }
        }
      }

      return differences;
    }


    function appendFieldsToFormData(dataObject, formData, parentKey = "") {
      for (const key in dataObject) {
        if (Object.hasOwnProperty.call(dataObject, key)) {
          const value = dataObject[key];
          const fieldName = parentKey ? `${parentKey}[${key}]` : key;

          if (typeof value === 'object' && value !== null) {
            // If the value is an object (nested object), recursively call the function
            // Pass the current fieldName as the parentKey for the nested object
            appendFieldsToFormData(value, formData, fieldName);
          } else {
            // If it's a leaf property, append it to FormData
            formData.append(fieldName, value);
          }
        }
      }
    }

    function isFile(value: any): value is File {
      return value instanceof File;
    }
    function editProduct() {
      //loading.value = true;
      const differences: any = findDifferences(originalFormData, form);
      
      v$.value.$validate();
      if (!v$.value.$error) {
        Swal.showLoading();
        const formData = new FormData();
        appendFieldsToFormData(differences, formData);
        if (isFile(form.picture) || form.picture !== originalFormData.picture) {
          formData.append('picture', form.picture);
        }

        ProductRequest.editProduct(route.params.id, formData)
          .then((response: any) => {
            if (response.data.success == true) {
              Swal.close();
              notification.notify({
                title: "Product successfully updated",
                type: "success"
              });
              /*setTimeout(() => {
                router.push({ name: 'ProductsList' });
              }, 500);*/
            } else {
              Swal.close();
            }
          }).catch(() => Swal.close())
      }else{
        let animationFrameId;
        const animateScroll = () => {
          const errorElements = document.querySelectorAll('.item__error');
          console.log(errorElements);
          if (errorElements.length > 0) {
            const firstErrorParent = errorElements[0].closest('.input-item');
            console.log(errorElements[0]);
            if (firstErrorParent) {
              firstErrorParent.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
            animationFrameId = requestAnimationFrame(animateScroll);
          }
        };

        animationFrameId = requestAnimationFrame(animateScroll);
      }
    }
    function getProduct() {
      ProductRequest.getProductById(route.params.id)
        .then((response: any) => {
          const product = response.data.data;
          _.merge(form, product);
          _.merge(originalFormData, product);
          image.value = product.picture;
        })
    }
    onMounted(() => {
      getProduct();
    })
    return {
      form,
      editProduct,
      loading,
      getProduct,
      rules,
      v$,
      image,
      removeImage,
      onChange,
      onDrop,
      appendFieldsToFormData,
      originalFormData,
      initialForm
    };
  },
});
