
  import { defineComponent, inject, onMounted, reactive, ref } from "vue";
  import CloseIcon from "@/components/icons/CloseIcon.vue";
  import useVuelidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import VueSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  export default defineComponent({
    props: {
      id: String,
      selectedShop: Object,
      mode: String
    },
    components: {
      CloseIcon,
      "v-select": VueSelect
    },
    setup(props, { emit }) {
      const close = ref();
      const transporters = ref([]);
      const form = reactive({
        transporter: "",
        number: ""
      });
      const rules = {
        transporter: { required },
        number: { required }
      };
      const v$ = useVuelidate(rules, form);
      function closeModal(){
        emit('closeModal');
      }
      function AddTrackingNumber() {
        console.log('Adding tracking number');
        /*v$.value.$validate();
        if (!v$.value.$error) {
          if (props.mode == 'add') {
            ShopRequest.newShop(form)
              .then((response) => {
                emit('loadShops');
                close.value.click();
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (props.mode == 'edit') {
            ShopRequest.editShop(props.selectedShop?._id, form)
              .then((response) => {
                emit('loadShops');
                close.value.click();
              })
              .catch((error) => {
                console.log(error);
              });
          }
  
        } else {
          //error_alert.value = true;
          //window.scrollTo(0, 0);
        }*/
      }
      onMounted(() => {
        /*if(props.mode == 'edit') {
          form.email = props.selectedShop?.email;
          form.name = props.selectedShop?.name;
        }*/
      })
      return {
        form,
        rules,
        v$,
        AddTrackingNumber,
        close,
        closeModal,
        transporters
      };
    },
  });
  