
import { defineComponent, ref, onMounted, reactive, computed  } from "vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import ArrowBottom from "@/components/icons/ArrowBottomIcon.vue";
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export default defineComponent({
  props: {
    id: String,
  },
  components: {
    CloseIcon,
    ArrowBottom,
  },
  setup(props, {emit}) {
    const conditions_one = ref([
      { id: 1, value: "Total weight (g)" },
      { id: 2, value: "Source" },
      { id: 3, value: "Source reference" },
      { id: 4, value: "Origin" },
      { id: 5, value: "Original reference" },
      { id: 6, value: "Carrier code" },
      { id: 7, value: "Pick up location reference" },
      { id: 8, value: "Shipping zip code" },
      { id: 9, value: "shipping city" },
      { id: 10, value: "shipping country" },
      { id: 11, value: "Recipient company name" },
      { id: 12, value: "Recipient mobile phone" },
      { id: 13, value: "Recipient email" },
      { id: 14, value: "Shipping within UE" },
      { id: 15, value: "Shipping to DOM TOM" },
      { id: 16, value: "Requires CN23" },
      { id: 17, value: "Shipping French Department" },
      { id: 18, value: "First line of merged address" },
      { id: 19, value: "Second line of merged address" },

    ]);
    const conditions_two = ref([
        { id: 1, value: "greater than or equal" },
        { id: 2, value: "less than or equal to" },
        { id: 3, value: "strictly less than" },
        { id: 4, value: "strictly greater than" },
        { id: 5, value: "is different from" },
        { id: 6, value: "equal to" },
        { id: 7, value: "is specified" },
        { id: 8, value: "is not specified" },
        { id: 9, value: "has one of the following value" },
        { id: 9, value: "exclude of the following values" }
    ]);
    const condition = reactive({
      element_one: "",
      element_two: "",
      value: ""
    });
    const rules = computed(() => {
      return{
        element_one: { required },
        element_two: { required },
        value: { required }
      }
      
    })
    const addConditionModal = ref();
    const close = ref();
    const v$ = useVuelidate(rules, condition)
    function addCondition(){
      v$.value.$validate();
      if(!v$.value.$error){
        emit('addCondition', condition);
        closeModal();
      }
    }
    function closeModal(){
      emit("closeModal");
    }
    onMounted(() => {
      /*addConditionModal.value.addEventListener('hide.bs.modal', function(){
        console.log("modal hidden")  
      })*/
    })
    return {
      conditions_one,
      conditions_two,
      condition,
      addCondition,
      addConditionModal,
      close,
      rules,
      v$,
      closeModal
    };
  },
});
