
import { computed, defineComponent, onMounted, ref, toRefs, watch, watchEffect } from "vue";
//import EyeIcon from "@/components/icons/EyeIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
//import TrashIcon from "@/components/icons/TrashIcon.vue";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import axios from "@/plugins/axios";
import cloneDeep from 'lodash/cloneDeep';
import TableConfig from "@/components/TableConfig.vue";
import SavedFilters from "./SavedFilters.vue";
import SaveSearch from "./SaveSearch.vue";
import { BIconSliders2, BIconTable, BIconSave, BIconDisc } from "bootstrap-icons-vue";

interface TableColumn {
  value: string;
  text: string;
  order: number;
  show: boolean;
  type: string
}
export default defineComponent({
  components: {
    //EyeIcon,
    SearchIcon,
    //TrashIcon,
    VPagination,
    BIconTable,
    BIconSliders2,
    TableConfig,
    BIconSave,
    BIconDisc,
    SavedFilters,
    SaveSearch
  },
  props: {
    headers: {
      type: Array as () => TableColumn[],
      default: () => []
    },
    rows_data: {
      type: Array,
      default: () => [],
    },
    states: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Boolean,
    },
    /*total: {
      type: Number,
      default: 0,
    },*/
    editRouteName: {
      type: String,
      default: "EditOrder",
    },
    searchByColumn: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    params: {
      type: String,
    }
  },
  emits: ["attribute", "pageNumber", "selectedRows", "configConfirmed"],
  setup(props, { emit }) {
    //const axios = inject<AxiosInstance>("axios");
    const table_config = ref(false);
    const save_filter = ref(false);
    const saved_filters = ref(false);
    const page = ref(1);
    const total_items = ref(0);
    const rows = ref<any>([]);
    const selectedRows = ref([]);
    const { headers } = toRefs(props);

    const sorted_headers = ref([]);

    const search_form = {
      id: "",
      state: "",
      origin: "",
      original_ref: "",
      source: "",
      date: "",
      name: "",
    };
    function sortHeaders(headers) {
      const sortedAndFilteredHeaders = cloneDeep(headers).sort((a: any, b: any) => {
        // Sort by show property first (true comes first)
        if (a.show && !b.show) {
          return -1; // a comes first
        } else if (!a.show && b.show) {
          return 1; // b comes first
        } else {
          // If show properties are the same, then sort by order
          return a.order - b.order;
        }
      });

      // Filter out columns with show: false
      sorted_headers.value = sortedAndFilteredHeaders.filter(column => column.show);

    }
    function openConfig() {
      table_config.value = true;
    }
    function openSaveFilterComponent(){
      save_filter.value = true;
    }
    function openSavedFiltersComponent(){
      saved_filters.value = true;
    }
    function configConfirmed(new_headers_config) {
      sortHeaders(new_headers_config);
      emit('configConfirmed', new_headers_config)
      /*headers.value = new_headers_config;

      console.log(new_headers_config);*/

    }
    function getValue() {
      let obj_filtered = Object.entries(search_form).filter(([key, val]) => {
        return val !== "";
      });
      const new_search_form = Object.fromEntries(obj_filtered);

      emit("attribute", new_search_form);
    }
    function changePage() {
      emit("pageNumber", page.value);
      loadData()
    }

    function loadData() {
      if (!axios) {
        //console.error('Axios instance is not defined')
        return {}
      }
      if (props.url == '') {
        total_items.value = props.rows_data.length;
        rows.value = props.rows_data;
      } else {
        let url = '';
        if (props.params == "") {
          url = `${props.url}?_page=${page.value}`
        } else {
          url = `${props.url}?_page=${page.value}&${props.params}`
        }

        axios
          .get(url)
          .then((response) => {
            total_items.value = parseInt(response.data.data.totalCount);
            rows.value = response.data.data.data_array;
          })
          .catch((error) => console.log(error));
      }

    }

    function emitSelectRows(val) {
      emit("selectedRows", val);
    }

    watch(selectedRows, (val) => {
      emitSelectRows(val);
    })

    watchEffect(() => loadData());

    onMounted(() => {
      //loadData();
      getValue();
      sortHeaders([...headers.value]);
    });
    return {
      search_form,
      page,
      getValue,
      changePage,
      loadData,
      total_items,
      rows,
      emitSelectRows,
      selectedRows,
      sorted_headers,
      openConfig,
      table_config,
      configConfirmed,
      sortHeaders,
      save_filter,
      openSaveFilterComponent,
      saved_filters,
      openSavedFiltersComponent
    };
  },
});
