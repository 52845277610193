
import { defineComponent,ref,onMounted } from "vue";
import OrderIcon from "@/components/icons/OrderIcon.vue";
import PercentageIcon from "@/components/icons/PercentageIcon.vue";
import ChartIcon from "@/components/icons/ChartIcon.vue";
import Table from "@/components/Table.vue";
export default defineComponent({
  name: "Home",
  components: {
    OrderIcon,
    PercentageIcon,
    ChartIcon,
    Table
  },
  setup() {
    const headers = ref([
      //{ value: "_id", text: "id", show: true, order: 1 },
      { value: "state", text: "state", show: true, order: 2, type: "input" },
      { value: "origin", text: "origin", show: true, order: 3, type: "input" },
      { value: "reference", text: "origin ref", show: true, order: 4, type: "input" },
      { value: "order_date", text: "date", show: true, order: 5, type: "input" },
      { value: "source", text: "source", show: true, order: 6, type: "input" },
      { value: "status", text: "status", show: true, order: 7, type: "select" },
    ]);
    const rows = ref([
      {
        _id: 145233,
        state: 'to be prepared',
        origin: 'website1.fr',
        original_ref: '45236',
        date: '28/02/2022',
        source: 'amazon',
        name: 'john doe',
      },
      {
        _id: 145233,
        state: 'to be prepared',
        origin: 'website1.fr',
        original_ref: '45236',
        date: '28/02/2022',
        source: 'amazon',
        name: 'john doe',
      },
      {
        _id: 145233,
        state: 'to be prepared',
        origin: 'website1.fr',
        original_ref: '45236',
        date: '28/02/2022',
        source: 'amazon',
        name: 'john doe',
      },
    ]);
    const actions = ref(true);
    function configConfirmed(new_config){
      //console.log('Config confirmed', new_config)
      headers.value = new_config;
    }
    onMounted(() => {
      window.scrollTo(0, 0);
    })
    return{
      headers,rows,actions,
      configConfirmed
    }
  }
});
