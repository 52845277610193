import { createStore } from 'vuex'
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';
export default createStore({
  state: {
    openMenu : false,
    user: {},
    duplicateOrder: {},
    auditlogs: [],
    editOrderData: {},
    externalServicesDetails: {
      access_api: {},
      recup_orders: {},
      update_orders: {},
      update_stock: {}
    },

    transporterDetails: {
      access_api: {}
    },
    serviceName: '',
    transporterName: ''

  },
  getters: {
    currentTransporterName(state){
      return state.transporterName;
    },
    currentServiceName(state){
      return state.serviceName;
    },
    accessApi(state){
      return state.externalServicesDetails.access_api;
    },
    recupOrders(state){
      return state.externalServicesDetails.recup_orders;
    },
    updateOrders(state){
      return state.externalServicesDetails.update_orders
    },
    updateStock(state){
      return state.externalServicesDetails.update_stock;
    },
    transporterAccessApi(state){
      return state.transporterDetails.access_api;
    }
  },
  mutations: {
    setUser(state,payload){
      state.user = payload;
    },
    setLogout(state){
      state.user = {};
    },
    setDuplicateOrder(state, payload){
      state.duplicateOrder = payload;
    },
    clearDuplicateOrder(state){
      state.duplicateOrder = {};
    },
    clearExternalServicesDetails(state){
      state.externalServicesDetails.access_api = {};
      state.externalServicesDetails.recup_orders = {};
    },
    setAuditLogs(state, payload){
      state.auditlogs = payload;
    },
    setEditOrderData(state, payload){
      state.editOrderData = payload;
    },
    setAccessApi(state, payload){
      state.externalServicesDetails.access_api = payload;
    },
    setRecupOrder(state, payload){
      state.externalServicesDetails.recup_orders = payload;
    },
    setUpdateOrder(state, payload){
      state.externalServicesDetails.update_orders = payload;
    },
    setUpdateStock(state, payload){
      state.externalServicesDetails.update_stock = payload;
    },
    setTransportAccessApi(state, payload){
      state.transporterDetails.access_api = payload;
    },
    setServiceName(state, payload) {
      switch (payload) {
        case 1:
          return state.serviceName = 'Woocommerce';
        case 2:
          return state.serviceName = 'Shopify';
        case 3:
          return state.serviceName = 'Amazon';
        case 4:
          return state.serviceName = 'Prestashop';
        case 5:
          return state.serviceName = 'Magento';
        case 6:
          return state.serviceName = 'BigCommerce';
        case 7:
          return state.serviceName = 'Wizishop';
        case 8:
          return state.serviceName = 'AliExpress';
        case 9:
          return state.serviceName = 'Cdiscount';
        case 10:
          return state.serviceName = 'Rakuten';
        default:
          return '';
      }
    },
    setTransporterName(state, payload) {
      switch (payload) {
        case 1:
          return state.transporterName = 'GLS';
        case 2:
          return state.transporterName = 'Mondial Relay';
        default:
          return '';
      }
    }
  },
  actions: {
    getCurrentUser(context){
      return new Promise((resolve, reject) => {
        const token = Cookies.get("access_token"); 
        const decoded = jwt.decode(token);
        context.commit('setUser', decoded);
        resolve(decoded)
      })
    },
    logOut(context){
      return new Promise<void>((resolve, reject) => {
        if(Cookies.get("access_token")){
          Cookies.remove('access_token');
          context.commit('setLogout');
          resolve()
        }
      })
      
    }
  },
  modules: {
  }
})
