<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26.56"
    height="18.26"
    viewBox="0 0 37.34 25.672"
  >
    <g
      id="Groupe_111"
      data-name="Groupe 111"
      transform="translate(-1233.684 1173.993)"
    >
      <path
        id="Tracé_53"
        data-name="Tracé 53"
        d="M1252.344-1173.99h15.8c2.1,0,2.877.76,2.878,2.825q0,9.982,0,19.964c0,2.08-.789,2.878-2.845,2.878q-15.848,0-31.7,0c-1.945,0-2.779-.822-2.789-2.743q-.013-2.507,0-5.015c.008-1.165.268-1.439,1.41-1.558a3.55,3.55,0,0,0,3.231-3.272,3.5,3.5,0,0,0-2.914-3.735c-1.731-.339-1.731-.339-1.731-2.14,0-1.514-.01-3.028,0-4.542.014-1.837.818-2.655,2.662-2.659C1241.683-1174,1247.013-1173.99,1252.344-1173.99Zm16.355,23.32v-21h-32.758c0,1.488-.023,2.9.024,4.316a.914.914,0,0,0,.526.614,5.868,5.868,0,0,1,4.143,5.6,5.86,5.86,0,0,1-4.1,5.537c-.237.093-.556.383-.563.59-.049,1.44-.026,2.882-.026,4.346Z"
        fill="currentColor"
      />
      <path
        id="Tracé_54"
        data-name="Tracé 54"
        d="M1321.818-1132.892c-.418-.355-.9-.565-.96-.861a1.865,1.865,0,0,1,.249-1.362c1.213-1.567,2.511-3.068,3.781-4.59q3.628-4.35,7.26-8.7c.121-.145.24-.291.361-.435.494-.584,1.082-.815,1.72-.283a1.138,1.138,0,0,1,.038,1.757c-1.011,1.246-2.056,2.466-3.084,3.7-2.722,3.263-5.437,6.533-8.173,9.784A9.181,9.181,0,0,1,1321.818-1132.892Z"
        transform="translate(-70.641 -19.92)"
        fill="currentColor"
      />
      <path
        id="Tracé_55"
        data-name="Tracé 55"
        d="M1327.1-1145.878a3.42,3.42,0,0,1-3.4,3.453,3.471,3.471,0,0,1-3.5-3.464,3.48,3.48,0,0,1,3.468-3.505A3.43,3.43,0,0,1,1327.1-1145.878Zm-3.494,1.18a1.313,1.313,0,0,0,1.255-1.254,1.249,1.249,0,0,0-1.251-1.148,1.118,1.118,0,0,0-1.121,1.241A1.134,1.134,0,0,0,1323.6-1144.7Z"
        transform="translate(-70.128 -19.941)"
        fill="currentColor"
      />
      <path
        id="Tracé_56"
        data-name="Tracé 56"
        d="M1360.685-1092.83a3.4,3.4,0,0,1-3.464-3.473,3.387,3.387,0,0,1,3.508-3.419,3.376,3.376,0,0,1,3.418,3.413A3.411,3.411,0,0,1,1360.685-1092.83Zm-.039-2.269a1.208,1.208,0,0,0,1.245-1.219,1.156,1.156,0,0,0-1.231-1.153,1.087,1.087,0,0,0-1.136,1.214A1.109,1.109,0,0,0,1360.646-1095.1Z"
        transform="translate(-100.146 -60.208)"
        fill="currentColor"
      />
      <path
        id="Tracé_57"
        data-name="Tracé 57"
        d="M1283.563-1149.215h2.13v2.2h-2.13Z"
        transform="translate(-40.435 -20.087)"
        fill="currentColor"
      />
      <path
        id="Tracé_58"
        data-name="Tracé 58"
        d="M1283.333-1122.121v-2.13h2.186v2.13Z"
        transform="translate(-40.248 -40.324)"
        fill="currentColor"
      />
      <path
        id="Tracé_59"
        data-name="Tracé 59"
        d="M1285.652-1072.807h-2.144v-2.178h2.144Z"
        transform="translate(-40.39 -80.261)"
        fill="currentColor"
      />
      <path
        id="Tracé_60"
        data-name="Tracé 60"
        d="M1283.443-1097.436v-2.107h2.15v2.107Z"
        transform="translate(-40.337 -60.354)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>