
  import { defineComponent, ref, onMounted } from "vue";
  import Table from "@/components/Table.vue";
  import AddIcon from "@/components/icons/AddIcon.vue";
  import SearchIcon from "@/components/icons/SearchIcon.vue";
  import NewOrderStateNotification from "@/components/ConfigurationComponents/StatusNotification/NewOrderStateNotification.vue";
  import EyeIcon from "@/components/icons/EyeIcon.vue";
  import TrashIcon from "@/components/icons/TrashIcon.vue";
  export default defineComponent({
    components: {
      Table,
      AddIcon,
      SearchIcon,
      NewOrderStateNotification,
      EyeIcon,
      TrashIcon
    },
    setup() {
      const headers = ref([
        { value: "id", text: "Id" },
        { value: "title", text: "Titre de la notification" },
        { value: "status", text: "Etat de la commande" },
        { value: "envoi_email", text: "Envoi d'email" },
        { value: "actions",text: "actions"}
      ]);
      const rows = ref([
        {
          id: 1,
          title: "Amazon",
          status: 2,
          envoi_email: true,
        },
        {
          id: 2,
          title: "Amazon",
          status: 3,
          envoi_email: false,
        },
        {
          id: 3,
          title: "Amazon",
          status: 1,
          envoi_email: true,
        }
        
      ]);
      const actions = ref(true);
      function changeStatusBadge(status){
        switch(status){
            case 1:
                return "bg-success";
            case 2:
                return "bg-primary";
            case 3:
                return "bg-warning";
        }
      }
      function changeStatus(status){
        switch(status){
            case 1:
                return "Expédiée";
            case 2:
                return "En préparation";
            case 3:
                return "En attente de paiement";
        }
      }
      onMounted(() => {
        window.scrollTo(0, 0);
      });
      return {
        headers,
        rows,
        actions,
        changeStatusBadge,
        changeStatus
      };
    },
  });
  