<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22.33"
    height="25.38"
    viewBox="0 0 31.393 35.683"
  >
    <g
      id="Groupe_9"
      data-name="Groupe 9"
      transform="translate(367.886 -27.219)"
    >
      <path
        id="Tracé_29"
        data-name="Tracé 29"
        d="M-367.861,131.55c.429.245.811.459,1.19.679,4.225,2.443,8.437,4.912,12.689,7.308a1.687,1.687,0,0,1,1.029,1.736c-.051,5.312-.025,10.625-.025,16a3.229,3.229,0,0,1-.354-.148q-7.126-4.108-14.243-8.231a.7.7,0,0,1-.3-.506q-.024-8.256-.014-16.513C-367.886,131.79-367.873,131.7-367.861,131.55Z"
        transform="translate(0 -94.399)"
        fill="currentColor"
      />
      <path
        id="Tracé_30"
        data-name="Tracé 30"
        d="M-179.974,131.587c.009.2.021.339.021.476q0,8.088.01,16.176a.775.775,0,0,1-.446.783q-6.988,4.011-13.96,8.05c-.145.084-.3.154-.511.262v-.428q0-8.136,0-16.271a.638.638,0,0,1,.322-.666q7.149-4.106,14.285-8.234C-180.188,131.695-180.117,131.661-179.974,131.587Z"
        transform="translate(-156.551 -94.432)"
        fill="currentColor"
      />
      <path
        id="Tracé_31"
        data-name="Tracé 31"
        d="M-359.213,35.7l3.786-2.188q5.309-3.067,10.614-6.141a.8.8,0,0,1,.939.007q6.9,4.009,13.827,7.988c.175.1.343.212.58.36l-2.709,1.565q-5.906,3.411-11.81,6.824a.555.555,0,0,1-.646.054q-7.214-4.186-14.44-8.351A1.714,1.714,0,0,1-359.213,35.7Z"
        transform="translate(-7.847)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>