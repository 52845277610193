
import { defineComponent, ref } from "vue";
// import TrashIcon from "@/components/icons/TrashIcon.vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
export default defineComponent({
  components: {
    //TrashIcon,
  },
  setup() {
    const editor = ref(ClassicEditor);
    const editorData = ref("<p>Content of the editor.</p>");
    const editorConfig = ref({
      language: "en",
      toolbarCanCollapse: true,
      toolbar: {
        items: [
          "Source",
          "heading",
          "|",
          "alignment",
          "|",
          "bold",
          "italic",
          "strikethrough",
          "underline",
          "subscript",
          "superscript",
          "|",
          "link",
          "|",
          "bulletedList",
          "numberedList",
          "todoList",
          "-", // break point
          "fontfamily",
          "fontsize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "code",
          "codeBlock",
          "|",
          "insertTable",
          "|",
          "outdent",
          "indent",
          "|",
          "uploadImage",
          "blockQuote",
          "|",
          "undo",
          "redo",
        ],
        shouldNotGroupWhenFull: true,
      },
    });
    const emailSection = ref(true);
    const tags_arrays = ref({
      sources : [],
      origins : [],
      carriers : []
    })
    
    function addTag(event, array_name) {
      event.preventDefault();
      let val = event.target.value.trim();
      if (val.length > 0) {
        if (tags_arrays.value[array_name].length >= 1) {
          for (let i = 0; i < tags_arrays.value[array_name].length; i++) {
            if (tags_arrays.value[array_name][i] === val) {
              return false;
            }
          }
        }
        tags_arrays.value[array_name].push(val);
        event.target.value = "";
        console.log(tags_arrays.value[array_name]);
      }
    }
    function removeTag(index,array_name) {
      tags_arrays.value[array_name].splice(index, 1);
    }
    /*function removeLastTag(event,array_name) {
      if (event.target.value.length === 0) {
        const arr = tags_arrays[array_name].value
        removeTag(.length - 1);
      }
    }*/
    return {
      editor,
      editorData,
      editorConfig,
      emailSection,
      tags_arrays,
      addTag,
      removeTag,
      //removeLastTag,
    };
  },
});
