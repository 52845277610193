
import { defineComponent, reactive, ref } from 'vue';
import CloseIcon from "@/components/icons/CloseIcon.vue";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default defineComponent({
    components: {
        CloseIcon,
        vSelect
    },
    props: {
        id: String,
    },
    setup(props, { emit }) {
        interface Item {
            id: string
        }
        const form = reactive({
            reason: '',
            reason_ref: '',
            return_type: '',
            products: [
                {
                    id: '1',
                    product: 'xxxx-1',
                    title: 'Private Trip',
                    quantity: 1
                },
                {
                    id: '2',
                    product: 'xxxx-2',
                    title: 'Music Tahara',
                    quantity: 1
                },
                {
                    id: '3',
                    product: 'xxxx-3',
                    title: 'Bamboo Cotons',
                    quantity: 1
                }
            ]
        })
        const rules = reactive({
            reason: { required },
            reason_ref: { required },
            return_type: { required }
        });
        const selectedRows = ref<Item[]>([]);
        const v$ = useVuelidate(rules, form);
        const types = ref([]);

        function addNewReturnedOrder() {
            console.log('adding')
        }
        function close() {
            emit('close');
        }
        function selectAll() {
            // Clear the selectedRows array first
            selectedRows.value = [];

            // Iterate through each product and push its id to selectedRows
            form.products.forEach((item: any) => {
                selectedRows.value.push(item.id);
            });
        }
        function unSelectAll(){
            // Clear the selectedRows array first
            selectedRows.value = [];
        }
        return {
            form,
            addNewReturnedOrder,
            close,
            v$,
            rules,
            types,
            selectedRows,
            selectAll,
            unSelectAll
        }
    }
})
