
import { useNotification } from "@kyvg/vue3-notification";
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import ShopRequest from "@/services/requests/shop";

export default defineComponent({
  setup(props, {emit}) {
    const store = useStore();
    const route = useRoute();
    const notification = useNotification();
    const update_stock = computed(() => {
      return store.getters.updateStock
    });
    const form = reactive({
      update_stock_boolean: update_stock.value.update_stock_boolean
    });
    interface externalServices {
      _id: any,
      update_stock_boolean: boolean,
      //envoie_stock: number,
    }

    function EditUpdateStock() {
      let id = store.state.user.firstShop;

      const form_data = {
        external_services: [] as externalServices[]
      };

      form_data.external_services.push({
        _id: route.params.id,
        update_stock_boolean: form.update_stock_boolean,
        //envoie_stock: form.envoie_stock
      });
      Swal.showLoading();
      ShopRequest.editShop(id, form_data)
        .then(() => {
          emit('getServiceDetails');
          Swal.close();
          notification.notify({
            title: "Successfully updated",
            type: "success"
          });
        })
        .catch(() => {
          Swal.close();
          notification.notify({
            title: "Something went wrong",
            type: "error"
          });
        })
    }
    return {
      notification,
      route,
      store,
      EditUpdateStock,
      form
    };
  },
});
