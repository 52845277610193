
import { reactive, defineComponent, ref, computed } from 'vue';
import { BIconEye } from 'bootstrap-icons-vue';
import { BIconEyeSlash } from 'bootstrap-icons-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useNotification } from '@kyvg/vue3-notification';
import TrasportersRequests from '@/services/requests/transporters';
import Swal from "sweetalert2";
export default defineComponent({
  components: {
    BIconEye,
    BIconEyeSlash
  },
  setup(props,{emit}){
    const showPublicKey = ref(false);
    const showPrivateKey = ref(false);
    const showCodeEnseigne = ref(false);
    const store = useStore();
    const route = useRoute();
    const notification = useNotification();
    const access_api = computed(() => {
      return store.getters.transporterAccessApi;
    });
    const currentTransporter = computed(() => {
      return store.getters.currentTransporterName
    });
    /*const access_api = computed(() => {
      return store.getters.accessApi
    });*/
    const form = reactive({
      acces_api: {
        contact_id: access_api.value.contact_id,
        public_key: access_api.value.public_key,
        private_key: access_api.value.private_key,
        code_enseigne: access_api.value.code_enseigne
      }
      
    });

    function togglePublicKeyVisibility() {
      showPublicKey.value = !showPublicKey.value;
    }
    function togglePrivateKeyVisibility() {
      showPrivateKey.value = !showPrivateKey.value;
    }
    function toggleCodeEnseigneVisibility(){
      showCodeEnseigne.value = !showCodeEnseigne.value;
    }

    function EditApiAccess(){
      Swal.showLoading();
      TrasportersRequests.editTransporter(route.params.id, form)
      .then((response: any) => {
        if(response.data.success == true){
          emit('getTransporterDetails');
          Swal.close();
          notification.notify({
            title: "successfully updated",
            type: "success"
          });
        }else {
          Swal.close();
        }
        
      })
    }

    return {
      showPublicKey,
      showPrivateKey,
      form,
      togglePrivateKeyVisibility,
      togglePublicKeyVisibility,
      EditApiAccess,
      store,
      route,
      notification,
      access_api,
      currentTransporter,
      showCodeEnseigne,
      toggleCodeEnseigneVisibility
    }
  }
})
