<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21.35"
    height="24.04"
    viewBox="0 0 30.051 33.829"
  >
    <g
      id="Groupe_454"
      data-name="Groupe 454"
      transform="translate(2800.785 -420.883)"
    >
      <path
        id="Tracé_309"
        data-name="Tracé 309"
        d="M-2756.964,445.786v-8.8q0-5.888,0-11.776,0-1.708,0-3.416a.914.914,0,0,1,.791-.9.923.923,0,0,1,1.017.7,1.2,1.2,0,0,1,.022.277q0,7.207,0,14.413,0,4.606,0,9.212v.189c.119-.111.209-.189.293-.273.606-.609,1.21-1.222,1.818-1.83.546-.547,1.1-1.088,1.646-1.634q1.537-1.534,3.073-3.069a.922.922,0,0,1,1.3.029.935.935,0,0,1-.019,1.29c-.919.918-1.833,1.841-2.751,2.76-.735.735-1.475,1.464-2.209,2.2q-1.567,1.568-3.13,3.142c-.1.1-.206.2-.308.3a1.007,1.007,0,0,1-1.419,0c-.483-.479-.961-.963-1.442-1.443q-.932-.931-1.865-1.862-1.559-1.563-3.115-3.128c-.659-.661-1.325-1.317-1.98-1.982a.9.9,0,0,1,.027-1.3.92.92,0,0,1,1.276,0c.693.681,1.376,1.372,2.063,2.059l2.363,2.363q1.174,1.175,2.348,2.35a1.142,1.142,0,0,1,.125.186Z"
        transform="translate(-29.639)"
        fill="#192440"
      />
      <path
        id="Tracé_310"
        data-name="Tracé 310"
        d="M-2772.616,580.225c0-.378,0-.736,0-1.094q0-2.795-.007-5.59a.938.938,0,0,1,.94-.983.947.947,0,0,1,.947.981q0,3.77,0,7.54a.952.952,0,0,1-1.04,1.04h-27.671c-.1,0-.2,0-.3.008a.924.924,0,0,1-1.041-.967c0-1.268.009-2.537.009-3.805,0-1.237-.009-2.475-.009-3.712a.937.937,0,0,1,.97-.971.938.938,0,0,1,.914.9c.01.315,0,.631,0,.947v5.712Z"
        transform="translate(0 -127.416)"
        fill="#192440"
      />
    </g>
  </svg>
</template>