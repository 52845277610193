
  import { defineComponent, reactive, ref } from "vue";
  import CloseIcon from "@/components/icons/CloseIcon.vue";
  import useVuelidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import vSelect  from "vue-select";
  import "vue-select/dist/vue-select.css";
  import TransportersRequests from "@/services/requests/transporters"; 
  import {useStore} from "vuex";
  
  export default defineComponent({
    props: {
      id: String,
    },
    components: {
      CloseIcon,
      vSelect
    },
    setup(props, { emit }) {
      const close = ref();
      const store = useStore();
      const form = reactive({
        service: ''
      })
      const user_id = ref(store.state.user.id);
      const rules = reactive({
        service: { required }
      })
      const v$ = useVuelidate(rules, form);
      const transporters = ref([
        {
          id: '1',
          value: 'GLS'
        },
        {
          id: '2',
          value: 'Mondial Relay'
        },
      ]);
      function submit(){
        v$.value.$validate();
        if (!v$.value.$error) {
          /*const transporter_services: any = [];
         // transporter_services.push(form)
          /*formData.append(`external_services[0][name]`, form.name);
          formData.append(`external_services[0][service]`, form.service);*/
          TransportersRequests.newTrasnporter(user_id.value, form)
          .then((response: any) => {
            emit('loadData');
            close.value.click();
          })
          .catch((error :any) => {
            console.log(error);
          })
        }
      }
      return {
        form,
        rules,
        v$,
        submit,
        transporters,
        close
      };
    },
  });
  