
import { defineComponent, onMounted, ref } from "vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import ArrowBottom from "@/components/icons/ArrowBottomIcon.vue";
export default defineComponent({
  props: {
    id: String,
  },
  components: {
    CloseIcon,
    ArrowBottom,
  },
  setup(props, { emit }) {
    const target = ref([
      { id: 1, value: "MondialRelay - Mondial Relay 'Access by 12plus'" },
      { id: 2, value: "Dhl - DHL by 12plus" },
      { id: 3, value: "Colissimo - Colissimo sans signature by 12plus" },
      { id: 4, value: "Colissimo - Colissimo Expert by 12plus" },
      { id: 5, value: "Colissimo - Colissimo avec signature by 12plus" },
      { id: 6, value: "GlsByApi - GLS Standard" },
      { id: 7, value: "GlsByApi - GLS FlexDeliveryService" },
      { id: 8, value: "GlsByApi - GLS ExpressParcelGuarenteed" },
      { id: 9, value: "Lettre Simple" },
    ]);
    const targetIsRequired = ref(false);
    const close = ref();
    const addRuleModal = ref();
    const selectedRule = ref("");
    function addRule() {
      if (selectedRule.value != "") {
        emit("addRule", selectedRule.value);
        selectedRule.value = "";
        closeModal();
      } else {
        targetIsRequired.value = true;
      }
    }
    function closeModal(){
      emit("closeModal");
    }
    onMounted(() => {
      addRuleModal.value.addEventListener('hide.bs.modal', function(){
        selectedRule.value = "";
        targetIsRequired.value = false;
      })
    })
    return {
      target,
      addRule,
      selectedRule,
      close,
      targetIsRequired,
      addRuleModal,
      closeModal
    };
  },
});
