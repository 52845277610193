
import { defineComponent, ref, onMounted, reactive } from "vue";
import DragDrop from "@/components/icons/DragDropIcon.vue";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { BIconExclamationTriangleFill, BIconX } from "bootstrap-icons-vue";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
import PackRequest from "@/services/requests/packs";
import { useRoute } from "vue-router";
import _ from "lodash"; // Import Lodash

export default defineComponent({
  components: {
    DragDrop,

    BIconExclamationTriangleFill,
    BIconX
  },
  setup() {
    const notification = useNotification();
    const router = useRouter()
    const route = useRoute();
    const error_alert = ref(false);
    const initialForm = reactive({
      title: "",
      sku: "",
      ean13: "",
      picture: "",
      remark: "",
      available_stock:""
    });
    const form = reactive({ ...initialForm });
    const originalFormData: any = reactive({});

    const rules = reactive({
      //title: { required },
      sku: { required },
      /*ean13: { required },
      remark: { required } */
    });
    const v$ = useVuelidate(rules, form)
    const image = ref("");
    function onDrop(e: any) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      createFile(files[0]);
    }
    function onChange(e: any) {
      var files = e.target.files;
      form.picture = files[0];
      createFile(files[0]);
    }
    function createFile(file: any) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      //var img = new Image();
      var reader = new FileReader();
      //var vm = this;

      reader.onload = function (e: any) {
        image.value = e.target.result;
      };
      reader.readAsDataURL(file);
    }
    function removeImage() {
      image.value = "";
      form.picture = "";
    }


    function findDifferences(obj1, obj2) {
      const differences = {};

      for (const key in obj2) {
        if (key === 'picture') {
          // Skip 'picture' when comparing objects
          continue;
        }

        if (Object.hasOwnProperty.call(obj2, key)) {
          if (Array.isArray(obj2[key])) {
            // If the property is an array, compare the array elements
            const newObjects = obj2[key].filter(item2 => !obj1[key].some(item1 => item1._id === item2._id));
            if (newObjects.length > 0) {
              differences[key] = newObjects;
            }
          } else if (typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
            // If the property is an object, recursively compare it
            const nestedDifferences = findDifferences(obj1[key], obj2[key]);
            if (Object.keys(nestedDifferences).length > 0) {
              differences[key] = nestedDifferences;
            }
          } else if (obj1[key] !== obj2[key]) {
            // If the property is not an object or array and has changed, track the difference
            differences[key] = obj2[key];
          }
        }
      }

      return differences;
    }


    function appendFieldsToFormData(dataObject, formData, parentKey = "") {
      for (const key in dataObject) {
        if (Object.hasOwnProperty.call(dataObject, key)) {
          const value = dataObject[key];
          const fieldName = parentKey ? `${parentKey}[${key}]` : key;

          if (typeof value === 'object' && value !== null) {
            // If the value is an object (nested object), recursively call the function
            // Pass the current fieldName as the parentKey for the nested object
            appendFieldsToFormData(value, formData, fieldName);
          } else {
            // If it's a leaf property, append it to FormData
            formData.append(fieldName, value);
          }
        }
      }
    }


    function isFile(value: any): value is File {
      return value instanceof File;
    }

    function editPack() {

      const differences: any = findDifferences(originalFormData, form);
      //console.log(differences);
      //return;
      v$.value.$validate();
      if (!v$.value.$error) {
        Swal.showLoading();
        const formData = new FormData();
        appendFieldsToFormData(differences, formData);
        if (isFile(form.picture) || form.picture !== originalFormData.picture) {
          formData.append('picture', form.picture);
        }

        PackRequest.editPack(route.params.id,formData)
          .then((response: any) => {
            if (response.data.success == true) {
              Swal.close();
              notification.notify({
                title: "Pack successfully updated",
                type: "success"
              });
              /*setTimeout(() => {
                router.push({ name: 'Packs' });
              }, 500);*/
            } else {
              Swal.close();
            }

          }).catch((error) => {
            Swal.close();
          })
      } else {
        //error_alert.value = true;
        //error_alert.value = true;
        let animationFrameId;
        const animateScroll = () => {
          const errorElements = document.querySelectorAll('.item__error');
          console.log(errorElements);
          if (errorElements.length > 0) {
            const firstErrorParent = errorElements[0].closest('.input-item');
            console.log(errorElements[0]);
            if (firstErrorParent) {
              firstErrorParent.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
            animationFrameId = requestAnimationFrame(animateScroll);
          }
        };

        animationFrameId = requestAnimationFrame(animateScroll);
      }

    }
    function getPack() {
      PackRequest.getPackById(route.params.id)
        .then((response: any) => {
          const product = response.data.data;
          _.merge(form, product);
          _.merge(originalFormData, product);
          image.value = product.picture;
        })
    }
    onMounted(() => {
      window.scrollTo(0, 0);
      getPack();
    });
    return {
      image,
      onDrop,
      onChange,
      createFile,
      removeImage,
      form,
      editPack,
      v$,
      error_alert
    };
  },
});
