
import { defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import AccountRequest from "@/services/requests/account";
import useVuelidate from "@vuelidate/core";
import _ from "lodash"; // Import Lodash
import { required, email } from "@vuelidate/validators";
import { useNotification } from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
export default defineComponent({
  setup() {
    const store = useStore();
    const notification = useNotification();
    const originalFormData = reactive({});
    const initialForm = reactive({
      firstname: "",
      lastname: "",
      phone: "",
      organization: "",
      email: "",
      country: "",
      city: "",
      zip_code: "",
      address1: "",
      address2: "",
      //role: ""
    });
    const form = reactive({ ...initialForm });
    const rules = {
      firstname: { required },
      lastname: { required },
      phone: { required },
      organization: { required },
      email: { required, email },
      country: { required },
      city: { required },
      zip_code: { required },
      address1: { required }
    };
    const v$ = useVuelidate(rules, form);
    const modifiedData = ref({});
    function getUser() {
      AccountRequest.getUserInfo(store.state.user.id)
        .then((response: any) => {
          const user = response.data;
          _.merge(form, user);
          _.merge(originalFormData, user);
        })
    }

    function editUser() {
      v$.value.$validate();

      if (!v$.value.$error) {
        Swal.showLoading();
        const changedData = {};
        for (const key in form) {
          // Check if the property is an array
          if (Array.isArray(form[key])) {
            // Skip arrays, if you don't want them in changedData
            continue;
          }

          if (form[key] !== originalFormData[key]) {
            changedData[key] = form[key];
          }
        }
        AccountRequest.editUserInfo(store.state.user.id, changedData)
          .then(() => {
            Swal.close();
            notification.notify({
              title: "User successfully updated",
              type: "success"
            });
          }).catch(() => {
            Swal.close();
          })
      } else {
        let animationFrameId;
        const animateScroll = () => {
          const errorElements = document.querySelectorAll('.item__error');
          if (errorElements.length > 0) {
            const firstErrorParent = errorElements[0].closest('.input-item');
            if (firstErrorParent) {
              firstErrorParent.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
            animationFrameId = requestAnimationFrame(animateScroll);
          }
        };
        animationFrameId = requestAnimationFrame(animateScroll);
      }
    }

    onMounted(() => {
      getUser();
    });

    return {
      getUser,
      form,
      v$,
      editUser,
      originalFormData,
      modifiedData
    }
  }
})
