
import { defineComponent, ref, onMounted, reactive } from "vue";
import DragDrop from "@/components/icons/DragDropIcon.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { BIconExclamationTriangleFill, BIconX } from "bootstrap-icons-vue";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
import PackRequest from "@/services/requests/packs";

export default defineComponent({
  components: {
    DragDrop,
    AddIcon,
    BIconExclamationTriangleFill,
    BIconX
  },
  setup() {
    const notification = useNotification();
    const router = useRouter()
    const error_alert = ref(false);
    const form = reactive({
      title: "",
      sku: "",
      ean13: "",
      picture: "",
      remark: ""
    });
    const rules = reactive({
     // title: { required },
      sku: { required },
      /*ean13: { required },
      remark: { required } */
    });
    const v$ = useVuelidate(rules, form)
    const image = ref("");
    function onDrop(e: any) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      createFile(files[0]);
    }
    function onChange(e: any) {
      var files = e.target.files;
      form.picture = files[0];
      createFile(files[0]);
    }
    function createFile(file: any) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      //var img = new Image();
      var reader = new FileReader();
      //var vm = this;

      reader.onload = function (e: any) {
        image.value = e.target.result;
      };
      reader.readAsDataURL(file);
    }
    function removeImage() {
      image.value = "";
    }

    function AddNewPack() {
      v$.value.$validate();
      if (!v$.value.$error) {
        const formData = new FormData();
        for (const key in form) {
          if (form[key]) {
            formData.append(key, form[key]);
          }
        }

        Swal.showLoading();

        PackRequest.newPack(formData)
          .then(() => {
            //console.log(response);
            Swal.close();
            notification.notify({
              title: "Pack successfully created",
            });
            setTimeout(() => {
              router.push({ name: 'Packs' });
            }, 500);

          }).catch(() => {
            Swal.close();
          })
      } else {
        let animationFrameId;
        const animateScroll = () => {
          const errorElements = document.querySelectorAll('.item__error');
          if (errorElements.length > 0) {
            const firstErrorParent = errorElements[0].closest('.input-item');
            if (firstErrorParent) {
              firstErrorParent.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
            animationFrameId = requestAnimationFrame(animateScroll);
          }
        };

        animationFrameId = requestAnimationFrame(animateScroll);
      }

    }
    onMounted(() => {
     window.scrollTo(0, 0);
    });
    return {
      image,
      onDrop,
      onChange,
      createFile,
      removeImage,
      form,
      AddNewPack,
      v$,
      error_alert
    };
  },
});
