
  import { defineComponent, ref } from "vue";
  import CloseIcon from "@/components/icons/CloseIcon.vue";
  import ArrowBottom from "@/components/icons/ArrowBottomIcon.vue";
  export default defineComponent({
    props: {
      id: String,
    },
    components: {
      CloseIcon,
      ArrowBottom
    },
    setup() {
      const status = ref([
        {id: 1,value: "en attente de paiement"},
        {id: 2,value: "en attente de stock"},
        {id: 3,value: "aiguillée"},
        {id: 4,value: "a préparer"},
        {id: 5,value: "en préparation"},
        {id: 6,value: "expédiée"},
        {id: 7,value: "remis au transporteur"},
        {id: 8,value: "en point retrait"},
        {id: 9,value: "livré"},
        {id: 10,value: "annulé"},
        {id: 11,value: "facture générer"},
        {id: 12,value: "découpée"}
      ]);
      return {
        status
      };
    },
  });
  