
  import { defineComponent, onMounted, ref } from "vue";
  import CloseIcon from "@/components/icons/CloseIcon.vue";
  import {
  BIconCheckLg,
  BIconXLg
} from "bootstrap-icons-vue";
  export default defineComponent({
    props: {
        statuses: Array,
    },
    components: {
      CloseIcon,
      BIconCheckLg,
      BIconXLg
    },
    setup(props, { emit }) {
        
        const statusIconHtml = (status) => {
            if (status.success) {
                return `<b-icon-check-lg></b-icon-check-lg>`;
            } else {
                return `<b-icon-x-lg></b-icon-x-lg>`;
            }
        };
      const close = ref();
      
      function closeModal(){
        emit('closeModal');
      }
      
      onMounted(() => {
        /*if(props.mode == 'edit') {
          form.email = props.selectedShop?.email;
          form.name = props.selectedShop?.name;
        }*/
      })
      return {
        close,
        closeModal,
        statusIconHtml
      };
    },
  });
  