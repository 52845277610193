
import { defineComponent, reactive, ref } from "vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import PackRequest from "@/services/requests/packs";
import { useRoute } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";

export default defineComponent({
    props: {
        id: String,
    },
    components: {
        CloseIcon,
    },
    setup(props, { emit }) {
        interface product {
            quantity: string,
            sku: string
        }
        const notification = useNotification();
        const route = useRoute();
        const close = ref();
        const form = reactive({
            quantity: "",
            sku: ""
        });
        const rules = {
            quantity: { required },
            sku: { required }
        };
        const v$ = useVuelidate(rules, form);

        function addNewProduct() {
            v$.value.$validate();
            if (!v$.value.$error) {
                
                let id = route.params.id;
                const product_entries = [] as product[];
                product_entries.push(form);
                const form_data = {
                    product_entries
                }

                PackRequest.editPack(id, form_data)
                    .then(() => {
                       
                        notification.notify({
                            title: "Product successfully added",
                            type: "success"
                        });
                        emit('loadProducts');
                        close.value.click();
                    })
                    .catch((error) => {
                        console.log(error);
                    });

            }
        }
        return {
            form,
            rules,
            v$,
            addNewProduct,
            close
        };
    },
});
