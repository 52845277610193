

import { defineComponent, watch } from 'vue';
import Table from "@/components/Table.vue";
import { ref } from "vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import NewExport from '@/components/ConfigurationComponents/Exports/NewExport.vue';
import { BIconDownload, BIconTrash } from "bootstrap-icons-vue";
import Swal from "sweetalert2";

export default defineComponent({
    components: {
        Table,
        AddIcon,
        SearchIcon,
        NewExport,
        BIconDownload,
        BIconTrash
    },
    setup() {
        const datatable = ref();
        const page = ref(1);
        const pageLength = ref(0);
        const url = ref('');
        const params = ref('');
        const search_query = ref('');
        const actions = ref(true);
        const headers = ref([
            { value: "created_at", text: "created at", show: true, order: 1, type: "input" },
            { value: "start_date", text: "start date", show: true, order: 2, type: "input" },
            { value: "end_date", text: "end date", show: true, order: 3, type: "input" }
        ]);

        const rows = ref([
            {
                _id: 145233,
                created_at: 'Sun, Mar 3, 2024 11:39 AM',
                start_date: 'Thu, Jan 1, 2024 11:39 AM',
                end_date: 'Thu, Feb 1, 2024 11:39 AM'
            },
            {
                _id: 145233,
                created_at: 'Sun, Mar 3, 2024 11:39 AM',
                start_date: 'Thu, Jan 1, 2024 11:39 AM',
                end_date: 'Thu, Feb 1, 2024 11:39 AM'
            },
            {
                _id: 145233,
                created_at: 'Sun, Mar 3, 2024 11:39 AM',
                start_date: 'Thu, Jan 1, 2024 11:39 AM',
                end_date: 'Thu, Feb 1, 2024 11:39 AM'
            },
        ]);

        watch(search_query, (val) => {
            setParams(val);
        });

        function configConfirmed(new_config) {
            headers.value = new_config;
        }

        function getPageNumber(value) {
            page.value = value;
        }
        function setParams(val) {
            if (val !== '') {
                params.value = `_q=${val}`;
            } else {
                params.value = '';
            }
        }

        function deleteExport(id) {
            Swal.fire({
                title: "Are you sure?",
                text: "The export will be deleted",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) { 
                    console.log('deleted')
                }
            });

        }

        return {
            headers,
            datatable,
            configConfirmed,
            page,
            pageLength,
            url,
            getPageNumber,
            actions,
            params,
            search_query,
            rows,
            deleteExport
        }
    }
})
