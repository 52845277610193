import axios from "@/plugins/axios";

const ShopRequest = {
    getShopsName(){
        return new Promise((resolve, reject) => {
            axios.get(`/shops/names`)
            .then(res => resolve(res))
            .catch(err => reject(err))
        })
    },
    newShop(form){
        return new Promise((resolve, reject) => {
            axios.post('shops', form)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        }); 
    },
    editShop(id,form){
        return new Promise((resolve, reject) => {
            axios.patch(`/shops/${id}`, form)
            .then((response) => resolve(response))
            .catch((err) => reject(err))
        })
    },
    deleteShop(id){
        return new Promise((resolve, reject) => {
            axios.delete(`shops/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        })
    },
}

export default ShopRequest;