import axios from "@/plugins/axios";

const PreparationRequest = {
    prepareOrder(form){
        return new Promise((resolve, reject) => {
            axios.post('preparations', form)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    },
    downloadPreparation(id){
        return new Promise((resolve, reject) => {
            axios.get(`packing-slip/${id}`,{
                responseType: 'blob', // important
                })
            .then((response) => resolve(response))
            .catch((err) => reject(err))
        })
      
    },
    deletePreparation(id){
        return new Promise((resolve, reject) => {
            axios.delete(`preparations/${id}`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        })
    },
    getPendingOrdersCount(){
        return new Promise((resolve, reject) => {
            axios.get(`/preparations/pendingcount`)
            .then((response) => resolve(response))
            .catch((error) => reject(error));
        });
    }
}


export default PreparationRequest;