
import { defineComponent, ref, toRefs } from "vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
import draggable from 'vuedraggable'
import { BIconEye, BIconEyeSlash } from "bootstrap-icons-vue";
import cloneDeep from 'lodash/cloneDeep';
interface TableColumn {
    value: string;
    text: string;
    order: number;
    show: boolean;
    type: string;
}
export default defineComponent({
    props: {
        headers: {
            type: Array as () => TableColumn[],
            default: () => []
        }
    },
    components: {
        CloseIcon,
        draggable,
        BIconEye,
        BIconEyeSlash
    },
    setup(props, { emit }) {

        const { headers } = toRefs(props);
        const displayedColumns = ref(cloneDeep([...headers.value]));
        const columnOrder = ref(cloneDeep([...headers.value.sort((a, b) => a.order - b.order)]));
        // const headers_list = ref(props.headers);
        const list = ref([
            {
                id: 1,
                name: "List 1"
            },
            {
                id: 2,
                name: "List 2"
            },
            {
                id: 3,
                name: "List 3"
            }
        ]);
        const drag = ref(false);
        const close = ref();
        function closeModal() {
            emit('closeModal');
        }
        function onDragEnd() {
            columnOrder.value.forEach((item, index) => {
                item.order = index + 1;
                // console.log(item, index, item.order);
            })
        }
        function Confirm() {
            const combinedArray = displayedColumns.value.map((displayedColumn) => {
                // Find the corresponding column in columnOrder
                const matchingOrderColumn = columnOrder.value.find((orderColumn) =>
                    orderColumn.value === displayedColumn.value
                );

                if (matchingOrderColumn) {
                    // Update the order and show properties
                    return {
                        value: displayedColumn.value,
                        text: displayedColumn.text,
                        order: matchingOrderColumn.order,
                        show: displayedColumn.show,
                        type: displayedColumn.type
                    };
                }

                // Handle the case where a matching column is not found
                return null;
            }).filter(column => column !== null); // Remove null values

            // Create a result object with the combined array
            /*const result = {
                combinedArray,
            };*/


            //console.log(result);
            emit('configConfirmed', combinedArray);
            closeModal();
        }

        /*onMounted(() => {
            
        })*/
        return {
            list,
            drag,
            Confirm,
            close,
            closeModal,
            displayedColumns,
            columnOrder,
            onDragEnd
        };
    },
});
