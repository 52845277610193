
import { computed, defineComponent, reactive } from "vue";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import ShopRequest from "@/services/requests/shop";
import Swal from "sweetalert2";
import { useNotification } from "@kyvg/vue3-notification";
export default defineComponent({
  components: {
    Datepicker,
  },
  setup(props, {emit}) {
    const store = useStore();
    const route = useRoute();
    const notification = useNotification();
    const recup_orders = computed(() => {
      return store.getters.recupOrders
    });

    const form = reactive({
      start_date: recup_orders.value.start_date,
      enabled: recup_orders.value.enabled,
      current_date: ''
    });
    interface externalServices {
      _id: any,
      start_date: string,
      enabled: boolean,
      current_date: string
    }
    function EditRecupOrder(){
      let id = store.state.user.firstShop;

      const form_data = {
        external_services: [] as externalServices[]
      };

      form_data.external_services.push({
        _id:  route.params.id,
        start_date: form.start_date,
        enabled: form.enabled,
        current_date: form.current_date
      });
      Swal.showLoading();
      ShopRequest.editShop(id,form_data)
      .then(() => {
        emit('getServiceDetails');
        Swal.close();
        notification.notify({
          title: "Successfully updated",
          type: "success"
        });
      })
      .catch(() =>{
        Swal.close();
        notification.notify({
          title: "Something went wrong",
          type: "error"
        });
      })
    }
    return {
        form,
        recup_orders,
        EditRecupOrder
    };
  },
});
