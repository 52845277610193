
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent, reactive, ref } from 'vue';
import CloseIcon from "@/components/icons/CloseIcon.vue";


export default defineComponent({
    props: {
        id: String,
    },
    components: {
        CloseIcon
    },
    emits: ["addNewDeliveryDocument", "close"],
    setup(props,{ emit }) {
        const form = reactive({
            reference: "",
            stock: "",
            poids: "",
            hauteur: "",
            largeur: "",
            longueur: "",
            prix: "",
            code_devise: "",
            fournisseur: ""
        })
        const selectedFile = ref("");
        const rules = reactive({
            reference: { required },
            stock: { required },
            poids: { required },
            hauteur: { required },
            largeur: { required },
            longueur: { required },
            prix: { required },
            code_devise: { required },
            fournisseur: { required }
        })
        const v$ = useVuelidate(rules, form);
        function addNewDeliveryDocument() {
            v$.value.$validate();
            if (!v$.value.$error) {
                emit("addNewDeliveryDocument", form);
                close();
            }
            
        }
        
        function close(){
            emit('close');
        }
        return {
            form,
            addNewDeliveryDocument,
            v$,
            selectedFile,
            close
        }
    }
})
