<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.27"
    height="17.93"
    viewBox="0 0 20.065 25.322"
  >
    <g
      id="Groupe_445"
      data-name="Groupe 445"
      transform="translate(-667.957 -1120.09)"
    >
      <path
        id="Tracé_42"
        data-name="Tracé 42"
        d="M679.26,1174.563h16.753v.751q0,7.078,0,14.157c0,1.982-.883,2.881-2.855,2.884q-5.594.01-11.188,0a2.454,2.454,0,0,1-2.744-2.718q-.007-7.217,0-14.433C679.224,1175,679.246,1174.8,679.26,1174.563Zm3.86,7.869q0,2.827,0,5.654c0,.37-.037.808.493.817s.525-.405.525-.79q0-5.688,0-11.376c0-.379.014-.8-.5-.819s-.52.415-.52.793Q683.119,1179.571,683.12,1182.432Zm3.982,0h.005c0,1.93-.017,3.861.022,5.791,0,.233.309.46.474.689.175-.221.356-.438.521-.667.033-.045,0-.135,0-.2q0-5.584,0-11.169c0-.138.048-.308-.015-.407a4.528,4.528,0,0,0-.487-.562,2.908,2.908,0,0,0-.5.538c-.079.142-.025.359-.025.543Q687.1,1179.711,687.1,1182.434Zm5.011.065h0v-5.515c0-.092,0-.184,0-.276,0-.381-.01-.808-.528-.79s-.492.451-.494.826c0,.023,0,.046,0,.069q0,5.618,0,11.235c0,.4-.043.866.526.851s.488-.5.489-.887Q692.117,1185.255,692.113,1182.5Z"
        transform="translate(-9.708 -46.949)"
        fill="#FF6B6B"
      />
      <path
        id="Tracé_43"
        data-name="Tracé 43"
        d="M681.689,1122.983c1.406,0,2.693,0,3.981,0a2.216,2.216,0,0,1,2.171,3.13c-.079.212-.539.393-.825.4-2.255.033-4.512.02-6.767.02l-11.256,0c-.943,0-1.031-.087-1.033-1.016,0-.207-.005-.415,0-.622a1.849,1.849,0,0,1,1.933-1.9c1.355-.019,2.71,0,4.169,0,0-.422-.008-.8,0-1.184a1.582,1.582,0,0,1,1.68-1.7q2.141-.027,4.281,0a1.561,1.561,0,0,1,1.662,1.7C681.7,1122.172,681.689,1122.536,681.689,1122.983Zm-.957.016c0-.4,0-.745,0-1.085.008-.516-.231-.752-.756-.747-1.379.012-2.76-.019-4.138.028a.876.876,0,0,0-.652.5,5.329,5.329,0,0,0-.03,1.306Z"
        fill="#FF6B6B"
      />
    </g>
  </svg>
</template>