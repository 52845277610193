
import { defineComponent, reactive, ref } from 'vue';
import CloseIcon from "@/components/icons/CloseIcon.vue";
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default defineComponent( {
    components: {
        CloseIcon
    },
    props: {
        type: String
    },
    setup (props, { emit }) {
        const form = reactive({
           name: "",
           query: "" 
        });
        const rules = reactive({
            name: { required }
        });

        const v$ = useVuelidate(rules, form);

        const close = ref();
        function closeModal() {
            emit('closeModal');
        }
        function Confirm() {
            v$.value.$validate();
            if (!v$.value.$error) {
                //emit("addNewDeliveryDocument", delivery_document);
                closeModal();
            }
        }

        return {
            close,
            Confirm,
            closeModal,
            form,
            v$
        }
    }
})
