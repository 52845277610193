
import { defineComponent } from "vue";
import {useStore} from "vuex";
import SideBar from "@/components/SideBar.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import BackDrop from "@/components/BackDrop.vue";

export default defineComponent({
  name: "Dashboard",
  components: {
    SideBar,
    Header,
    Footer,
    BackDrop
  },
  setup() {
    const store = useStore();
    return {
      store
    };
  },
});
