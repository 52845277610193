<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56.26"
    height="49.61"
    viewBox="0 0 79.088 69.731"
  >
    <g
      id="Groupe_444"
      data-name="Groupe 444"
      transform="translate(305.336 333.623)"
    >
      <path
        id="Tracé_289"
        data-name="Tracé 289"
        d="M-222.688-262.623c-4.531,7.873-8.865,15.5-13.354,23.038-.395.662-1.795,1.016-2.73,1.021q-17.988.1-35.975-.024a4.193,4.193,0,0,1-2.79-1.491c-2.771-3.432-3.979-6.812-.692-10.97,2.858-3.615,4.044-9.345,7.569-11.412,3.411-2,8.864-.558,13.42-.561q16.715-.015,33.429.039A6.274,6.274,0,0,1-222.688-262.623Z"
        transform="translate(-9.097 -25.38)"
        fill="#192440"
      />
      <path
        id="Tracé_290"
        data-name="Tracé 290"
        d="M-292.52-275.576c-4.414-7.608-8.6-14.729-12.635-21.935a3.544,3.544,0,0,1,.352-2.866c5.926-10.429,11.9-20.834,18-31.159a5.275,5.275,0,0,1,3.217-1.842,10.239,10.239,0,0,1,4.427-.006,6.009,6.009,0,0,1,3.511,1.953c2.424,3.733,4.6,7.639,6.678,11.578a3.816,3.816,0,0,1,.078,3.13C-276.594-303.161-284.4-289.661-292.52-275.576Z"
        fill="#192440"
      />
      <path
        id="Tracé_291"
        data-name="Tracé 291"
        d="M-251.986-333.313c8.265,0,16.309-.05,24.352.08.776.012,1.8.984,2.262,1.772q9.074,15.514,17.992,31.118c1.59,2.778-1.329,7.7-4.544,7.724-4.986.04-9.975.081-14.959-.046a3.827,3.827,0,0,1-2.834-1.285c-7.455-12.711-14.787-25.494-22.135-38.267A4.493,4.493,0,0,1-251.986-333.313Z"
        transform="translate(-19.315 -0.109)"
        fill="#192440"
      />
    </g>
  </svg>
</template>