import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height"]
const _hoisted_2 = {
  id: "Groupe_11",
  "data-name": "Groupe 11",
  transform: "translate(0 0)"
}
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]
const _hoisted_6 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: _ctx.width != null ? _ctx.width : '24.56',
    height: _ctx.height != null ? _ctx.height :'24.56',
    viewBox: "0 0 34.533 34.669"
  }, [
    _createElementVNode("g", _hoisted_2, [
      _createElementVNode("path", {
        id: "Tracé_19",
        "data-name": "Tracé 19",
        d: "M272.273-34.27c-2.239,0-4.478,0-6.718,0a4.065,4.065,0,0,1-4.394-3.343q-2.661-9.581-5.318-19.163c-.341-1.228-.652-1.465-1.928-1.472-.5,0-1.01.016-1.514-.007a1.25,1.25,0,0,1-1.264-1.175,1.159,1.159,0,0,1,1-1.388,7.014,7.014,0,0,1,4.163.414,3.953,3.953,0,0,1,2,2.513c.525,1.812,1.087,3.614,1.558,5.439.186.723.523.912,1.242.909,7.538-.026,15.075-.017,22.613-.016,1.745,0,2.156.458,1.859,2.141q-1.041,5.9-2.117,11.793a3.957,3.957,0,0,1-4.083,3.348C277-34.25,274.638-34.27,272.273-34.27Zm10.514-14.62H260.934c.053.279.078.494.134.7.9,3.306,1.828,6.6,2.692,9.919a1.647,1.647,0,0,0,1.825,1.39c4.542-.01,9.084,0,13.626-.008A1.541,1.541,0,0,0,281-38.414c.3-1.736.612-3.47.908-5.207S282.486-47.086,282.787-48.889Z",
        transform: "translate(-251.124 60.938)",
        fill: _ctx.fill != null ? _ctx.fill : 'currentColor'
      }, null, 8, _hoisted_3),
      _createElementVNode("path", {
        id: "Tracé_20",
        "data-name": "Tracé 20",
        d: "M370.859,96.792a2.554,2.554,0,0,1,2.627-2.6,2.633,2.633,0,0,1,2.653,2.615,2.732,2.732,0,0,1-2.717,2.685A2.645,2.645,0,0,1,370.859,96.792Z",
        transform: "translate(-348.191 -64.823)",
        fill: _ctx.fill != null ? _ctx.fill : 'currentColor'
      }, null, 8, _hoisted_4),
      _createElementVNode("path", {
        id: "Tracé_21",
        "data-name": "Tracé 21",
        d: "M333.9,96.868a2.62,2.62,0,1,1-5.238-.093,2.62,2.62,0,1,1,5.238.093Z",
        transform: "translate(-313.983 -64.823)",
        fill: _ctx.fill != null ? _ctx.fill : 'currentColor'
      }, null, 8, _hoisted_5),
      _createElementVNode("path", {
        id: "Tracé_22",
        "data-name": "Tracé 22",
        d: "M341.065,26.488c-1.291,0-2.582.012-3.873,0-.983-.012-1.486-.481-1.469-1.317.016-.8.5-1.246,1.438-1.251q3.825-.022,7.651,0c.935,0,1.516.539,1.5,1.317a1.31,1.31,0,0,1-1.466,1.253C343.584,26.5,342.324,26.488,341.065,26.488Z",
        transform: "translate(-319.707 -7.843)",
        fill: _ctx.fill != null ? _ctx.fill : 'currentColor'
      }, null, 8, _hoisted_6)
    ])
  ], 8, _hoisted_1))
}