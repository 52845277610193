
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import AccountRequest from "@/services/requests/account";
import useVuelidate from "@vuelidate/core";
import _ from "lodash"; // Import Lodash
import { required, requiredIf } from "@vuelidate/validators";
import { useNotification } from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
export default defineComponent({
  setup() {
    const store = useStore();
    const notification = useNotification();
    const originalFormData = reactive({});
    const initialForm = reactive({
      token: "",
      auto_print: false,
      enable_cn23: false,
      cn23_qty: "",
      label_printer_id: "",
      cn23_printer_id: ""
    });
    const form = reactive({ ...initialForm });
    const dynamicAutoPrintValidation = computed(() => {
      return requiredIf(form.auto_print == true);
    }) 
       // Assuming you have requiredIf imported
  
    const rules = {
      token: { required },
      auto_print: { required },
      enable_cn23: { required },
      cn23_qty: { required: dynamicAutoPrintValidation },
      label_printer_id: { required: dynamicAutoPrintValidation },
      cn23_printer_id: { required: dynamicAutoPrintValidation },
    };
    const v$ = useVuelidate(rules, form);
    const modifiedData = ref({});

    function getUserLogistic() {
      AccountRequest.getUserLogistic(store.state.user.id)
        .then((response: any) => {
          const user = response.data.logistic;
          _.merge(form, user);
          _.merge(originalFormData, user);
        })
    }
    function submit() {
      v$.value.$validate();
      if (!v$.value.$error) {
        Swal.showLoading();
        const logistic = {};
        for (const key in form) {
          // Check if the property is an array
          if (Array.isArray(form[key])) {
            // Skip arrays, if you don't want them in changedData
            continue;
          }

          if (form[key] !== originalFormData[key]) {
            logistic[key] = form[key];
          }
        }

       let changedData = {logistic};

        console.log(changedData);
        //return;
       AccountRequest.editUserInfo(store.state.user.id, changedData)
          .then(() => {
            Swal.close();
            notification.notify({
              title: "User successfully updated",
              type: "success"
            });
          }).catch(() => {
            Swal.close();
          })
      }else {
        let animationFrameId;
        const animateScroll = () => {
          const errorElements = document.querySelectorAll('.item__error');
          if (errorElements.length > 0) {
            const firstErrorParent = errorElements[0].closest('.input-item');
            if (firstErrorParent) {
              firstErrorParent.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
            animationFrameId = requestAnimationFrame(animateScroll);
          }
        };
        animationFrameId = requestAnimationFrame(animateScroll);
      }
    }

    onMounted(() => {
      getUserLogistic();
    });

    return {
      form,
      v$,
      submit,
      originalFormData,
      modifiedData,
      getUserLogistic
    }
  }
})
