<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="16"
    viewBox="0 0 29 16"
  >
    <g id="Groupe_469" data-name="Groupe 469" transform="translate(-34 -98)">
      <rect
        id="Rectangle_228"
        data-name="Rectangle 228"
        width="29"
        height="2"
        transform="translate(34 98)"
        fill="#fff"
      />
      <rect
        id="Rectangle_229"
        data-name="Rectangle 229"
        width="29"
        height="2"
        transform="translate(34 105)"
        fill="#fff"
      />
      <rect
        id="Rectangle_231"
        data-name="Rectangle 231"
        width="29"
        height="2"
        transform="translate(34 112)"
        fill="#fff"
      />
    </g>
  </svg>
</template>