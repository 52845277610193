
import { defineComponent, inject, reactive, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { AxiosInstance } from "axios";

export default defineComponent({
  setup() {
    const axios = inject<AxiosInstance>("axios");
    const error_alert = ref(false);
    const form = reactive({
      name: "",
      email: ""
    });
    const rules = {
      name: { required },
      email: { required },
    };
    const v$ = useVuelidate(rules, form);

    function AddNewShop(){
      v$.value.$validate();
      if (!v$.value.$error) {
        /*const formData = new FormData();
        for (const key in form) {
          if (form[key]) {
            formData.append(key, form[key]);
          }
        }*/
        /*if (!axios) {
          //console.error('Axios instance is not defined')
          return {};
        }*/
        axios?.post("shops", form)
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        error_alert.value = true;
        window.scrollTo(0, 0);
      }
    }
    return {
      form,
      rules,
      v$,
      AddNewShop,
      error_alert
    };
  },
});
