
import { defineComponent, ref, onMounted, inject, watch } from "vue";
import Table from "@/components/Table.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import NewShopModal from "@/components/NewShopModal.vue";
import { BIconPencilSquare, BIconTrash } from "bootstrap-icons-vue";
import Swal from "sweetalert2";
import { useNotification } from "@kyvg/vue3-notification";
import { useStore } from "vuex";
import ShopRequest from "@/services/requests/shop";
export default defineComponent({
  components: {
    Table,
    AddIcon,
    SearchIcon,
    NewShopModal,
    BIconPencilSquare,
    BIconTrash
  },
  setup() {
    const shopModal = ref(false);
    const datatable = ref();
    const store = useStore();
    const url = ref(`shops/user/${store.state.user.id}`);
    const notification = useNotification();
    const params = ref('');
    const search_query = ref('');
    const headers = ref([
      {value: '_id',text:'id', show: true, order: 1, type: "input"},
      {value: 'name',text:'name', show: true, order: 2, type: "input"},
      {value: 'email',text:'email', show: true, order: 3, type: "input"},
      {value: 'created_at',text:'created at', show: true, order: 4, type: "input"},
    ]);
    const actions = ref(true);
    const selectedShop = ref();
    const mode = ref('');
    function selectItem(item){
      mode.value = 'edit';
      selectedShop.value = item;
      shopModal.value = true;
    }
    watch(search_query, (val) => {
      setParams(val);
    });
    function configConfirmed(new_config){
        //console.log('Config confirmed', new_config)
        headers.value = new_config;
      }
    function setParams(val){
      if(val !== ''){
        params.value = `_q=${val}`;
      }else{
        params.value = '';
      }
    }
    function loadShops(){
      datatable.value.loadData();
    }
    function deleteShop(id){
      Swal.fire({
        title: "Are you sure?",
        text: "The shop will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if(result.isConfirmed){
            ShopRequest.deleteShop(id).then((response) => {
            notification.notify({
              title: "Shop successfully deleted",
              type: 'success'
            });
            datatable.value.loadData();
          }) 
        }
        
      })
    }
    onMounted(() => {
      window.scrollTo(0, 0);
    });
    return {
      headers,
      actions,
      url,
      datatable,
      loadShops,
      deleteShop, 
      params,
      search_query,
      setParams,
      selectedShop,
      selectItem,
      mode,
      shopModal,
      configConfirmed
    };
  },
});
