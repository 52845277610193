
import { defineComponent } from "vue";
import CloseIcon from "@/components/icons/CloseIcon.vue";
export default defineComponent({
  props: {
    id: String,
  },
  components: {
    CloseIcon,
  },
  setup() {
    return {};
  },
});
