
import { defineComponent, ref, onMounted, inject, computed, reactive } from "vue";
import DragDrop from "@/components/icons/DragDropIcon.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { BIconExclamationTriangleFill, BIconX } from "bootstrap-icons-vue";
import { useRouter } from "vue-router";
import { useNotification } from "@kyvg/vue3-notification";
import Swal from "sweetalert2";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import ProductRequest from "@/services/requests/product";
import ShopRequest from "@/services/requests/shop";

export default defineComponent({
  components: {
    DragDrop,
    AddIcon,
    BIconExclamationTriangleFill,
    BIconX,
    "v-select": VueSelect
  },

  setup() {
    const notification = useNotification();
    const router = useRouter()
    const error_alert = ref(false);
    interface shopResponse {
      _id: string;
      name: string;
    }
    const shops = ref<shopResponse>();
    const form = reactive({
      title: "",
      sku: "",
      stock: "",
      location: "",
      ean13: "",
      image: "",
      sh_code: "",
      weight: "",
      remark: "",
      origin: "",
      source: "",
      shopIds: [],
      dimensions: {
        height: "",
        width: "",
        length: ""
      },
      details: {
        ref1: "",
        ref2: "",
        ref3: ""
      }
    });
    const rules = {
      sku: { required },
      shopIds: { required }
    };
    const v$ = useVuelidate(rules, form)
    const image = ref("");
    function onDrop(e: any) {
      e.stopPropagation();
      e.preventDefault();
      var files = e.dataTransfer.files;
      createFile(files[0]);
    }
    function onChange(e: any) {
      var files = e.target.files;
      form.image = files[0];
      createFile(files[0]);
    }
    function createFile(file: any) {
      if (!file.type.match("image.*")) {
        alert("Select an image");
        return;
      }
      //var img = new Image();
      var reader = new FileReader();
      //var vm = this;

      reader.onload = function (e: any) {
        image.value = e.target.result;
      };
      reader.readAsDataURL(file);
    }
    function removeImage() {
      image.value = "";
    }
    
    function AddNewProduct() {
      v$.value.$validate();
      if (!v$.value.$error) {
        Swal.showLoading();

        const formData = new FormData();

        // Append fields from the form object to the FormData
        formData.append('title', form.title);
        formData.append('sku', form.sku);
        formData.append('stock', form.stock);
        formData.append('location', form.location);
        formData.append('ean13', form.ean13);
        formData.append('picture', form.image);
        formData.append('sh_code', form.sh_code);
        formData.append('weight', form.weight);
        formData.append('remark', form.remark);
        formData.append('origin', form.origin);
        formData.append('source', form.source);

        // Append shopIds as an array (assuming shopIds is an array of values)
        for (const shopId of form.shopIds) {
          formData.append('shopIds[]', shopId);
        }

        // Append dimensions
        formData.append('dimensions[height]', form.dimensions.height);
        formData.append('dimensions[width]', form.dimensions.width);
        formData.append('dimensions[length]', form.dimensions.length);

        // Append details
        formData.append('details[ref1]', form.details.ref1);
        formData.append('details[ref2]', form.details.ref2);
        formData.append('details[ref3]', form.details.ref3);

        ProductRequest.newProduct(formData)
          .then((response: any) => {
            //console.log(response);
            if (response.data.success == true) {
              Swal.close();
              notification.notify({
                title: "Product successfully added",
                type: "success"
              });
              setTimeout(() => {
                router.push({ name: 'ProductsList' });
              }, 500);
            } else {
              Swal.close();
            }
          }).catch((error) => {
            Swal.close();
          })
      } else {
        //error_alert.value = true;
        let animationFrameId;
        const animateScroll = () => {
          const errorElements = document.querySelectorAll('.item__error');
          console.log(errorElements);
          if (errorElements.length > 0) {
            const firstErrorParent = errorElements[0].closest('.input-item');
            console.log(errorElements[0]);
            if (firstErrorParent) {
              firstErrorParent.scrollIntoView({ behavior: 'smooth' });
            }
          } else {
            animationFrameId = requestAnimationFrame(animateScroll);
          }
        };

        animationFrameId = requestAnimationFrame(animateScroll);
      }

    }
    function getShops() {
      ShopRequest.getShopsName()
        .then((response: any) => {
          shops.value = response.data.data;
        })
    }

    onMounted(() => {
      window.scrollTo(0, 0);
      getShops();
    });
    return {
      image,
      onDrop,
      onChange,
      createFile,
      removeImage,
      form,
      AddNewProduct,
      v$,
      error_alert,
      shops
    };
  },
});
