
import { defineComponent, ref } from 'vue'
import {useRoute} from 'vue-router';

export default defineComponent({
    setup () {
        const route = useRoute();
        const total_items = ref(0);
        const page = ref(1);
        const sub_orders = ref([
            {
                _id: '1256985',
                state: 'pending',
                quantity: 4
            },
            {
                _id: '1256985',
                state: 'pending',
                quantity: 4
            }
        ]);

        function getSubOrders(){
            console.log('get SubOrders');
        }    
        function changePage() {
            getSubOrders();
        }

        return {
            route,
            sub_orders,
            page,
            total_items,
            changePage,
            getSubOrders
        }
    }
})
