
import { defineComponent, ref, onMounted, watch, reactive } from "vue";
import Table from "@/components/Table.vue";
import AddIcon from "@/components/icons/AddIcon.vue";
import SearchIcon from "@/components/icons/SearchIcon.vue";
import { useNotification } from "@kyvg/vue3-notification";
import { BIconPencilSquare, BIconTrash } from "bootstrap-icons-vue";
import Swal from "sweetalert2";
import OrderRequest  from "@/services/requests/order";

export default defineComponent({
  components: {
    Table,
    AddIcon,
    SearchIcon,
    BIconPencilSquare,
    BIconTrash
  },
  setup() {
    const datatable = ref();
    const page = ref(1);
    const pageLength = ref(0);
    const url = ref('orders/user');
    const notification = useNotification();
    const params = ref('');
    const search_query = ref('');
    const headers = ref([
      { value: "_id", text: "id", show: true, order: 1, type: "input" },
      { value: "state", text: "state", show: true, order: 2, type: "input" },
      { value: "origin", text: "origin", show: true, order: 3, type: "input" },
      { value: "reference", text: "origin ref", show: true, order: 4, type: "input" },
      { value: "order_date", text: "date", show: true, order: 5, type: "input" },
      { value: "source", text: "source", show: true, order: 6, type: "input" },
      { value: "status", text: "status", show: true, order: 7, type: "select" },
    ]);
    const rows = ref([]);
    const order_status = ref([
      { value: 1, text: "To Prepare", color:'#3498db' },
      { value: 2, text: "In Preparation", color:'#2ecc71' },
      { value: 3, text: "Shipped", color:'#e74c3c' },
      { value: 4, text: "Awaiting Stock", color:'#f39c12' },
      { value: 5, text: "Cancelled", color:'#c0392b' },
      { value: 6, text: "Error", color:'#7f8c8d' },
      { value: 7, text: "On Hold", color:'#f1c40f' }
    ]);
    const actions = ref(true);
    watch(search_query, (val) => {
      setParams(val);
    });
    function configConfirmed(new_config){
      //console.log('Config confirmed', new_config)
      headers.value = new_config;
    }
    function statusColor(status){
      const statusItem = order_status.value.find((item) => item.value == status);
      return statusItem?.color;
    }
    function statusName(status){
      const statusItem = order_status.value.find((item) => item.value == status);
      return statusItem?.text;
    }
    function setParams(val){
      if(val !== ''){
        params.value = `_q=${val}`;
      }else{
        params.value = '';
      }
    }
    
    function deleteOrder(id){
      Swal.fire({
        title: "Are you sure?",
        text: "The product will be deleted",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if(result.isConfirmed){
          OrderRequest.deleteOrder(id).then((res) => {
            notification.notify({
              title: "Product successfully deleted",
              type: 'success'
            });
            datatable.value.loadData();
          });
        }
        
      })
    }
    /*function getAttr(value: any) {
      console.log(value);
    }*/
    function getPageNumber(value) {
      page.value = value;
    }
    onMounted(() => {
      //getAttr;
      window.scrollTo(0, 0);
    });
    return {
      page,
      headers,
      rows,
      order_status,
      actions,
      pageLength,
      //getAttr,
      getPageNumber,
      url,
      deleteOrder,
      params,
      search_query,
      setParams,
      datatable,
      statusColor,
      statusName,
      configConfirmed
    };
  },
});
