<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27.74"
    height="27.74"
    viewBox="0 0 39 38.991"
  >
    <g
      id="Groupe_448"
      data-name="Groupe 448"
      transform="translate(-411.08 -857.633)"
    >
      <path
        id="Tracé_292"
        data-name="Tracé 292"
        d="M490,861.244c0,.754.03,1.51-.005,2.263a1.329,1.329,0,0,1-1.41,1.437,1.3,1.3,0,0,1-1.291-1.428c-.016-1.508-.029-3.018.011-4.525a1.227,1.227,0,0,1,1.323-1.358,1.287,1.287,0,0,1,1.358,1.35C490.043,859.732,490,860.49,490,861.244Z"
        transform="translate(-58.04)"
        fill="currentColor"
      />
      <path
        id="Tracé_293"
        data-name="Tracé 293"
        d="M489.994,994.234c0,.71.012,1.421,0,2.131a1.362,1.362,0,0,1-1.328,1.517,1.3,1.3,0,0,1-1.377-1.445c-.019-1.5-.031-3,.006-4.5.02-.8.395-1.369,1.295-1.373.938,0,1.345.573,1.4,1.42C490.031,992.733,489.994,993.485,489.994,994.234Z"
        transform="translate(-58.034 -101.259)"
        fill="currentColor"
      />
      <path
        id="Tracé_294"
        data-name="Tracé 294"
        d="M426.827,900.742a8.978,8.978,0,0,1-1.067-.411c-1.236-.7-2.458-1.423-3.69-2.127a1.309,1.309,0,0,1-.629-1.977,1.289,1.289,0,0,1,1.959-.353c1.259.738,2.575,1.392,3.761,2.232a2.006,2.006,0,0,1,.647,1.55C427.783,900.042,427.167,900.39,426.827,900.742Z"
        transform="translate(-7.709 -28.907)"
        fill="currentColor"
      />
      <path
        id="Tracé_295"
        data-name="Tracé 295"
        d="M538.263,901.171c-.511-.425-1.108-.68-1.187-1.046a1.831,1.831,0,0,1,.345-1.56,34.682,34.682,0,0,1,4.393-2.542,1.564,1.564,0,0,1,1.432.38c.269.311.347,1.281.132,1.436C541.765,899,540.059,900.026,538.263,901.171Z"
        transform="translate(-95.945 -29.198)"
        fill="currentColor"
      />
      <path
        id="Tracé_296"
        data-name="Tracé 296"
        d="M454.5,873.759c-.217.234-.493.792-.874.875a1.676,1.676,0,0,1-1.446-.377,36.507,36.507,0,0,1-2.563-4.5,1.636,1.636,0,0,1,.564-1.439,1.486,1.486,0,0,1,1.443.081c1.016,1.532,1.888,3.16,2.8,4.761A2.425,2.425,0,0,1,454.5,873.759Z"
        transform="translate(-29.325 -8.028)"
        fill="currentColor"
      />
      <path
        id="Tracé_297"
        data-name="Tracé 297"
        d="M517.309,874.258c-1.33.04-2.018-.909-1.533-1.859.734-1.436,1.568-2.824,2.425-4.193a1.169,1.169,0,0,1,1.736-.451,1.218,1.218,0,0,1,.508,1.81c-.76,1.335-1.494,2.686-2.3,3.991A2.917,2.917,0,0,1,517.309,874.258Z"
        transform="translate(-79.631 -7.543)"
        fill="currentColor"
      />
      <path
        id="Tracé_298"
        data-name="Tracé 298"
        d="M422.734,967.551c-.263-.247-.841-.543-.95-.962a1.621,1.621,0,0,1,.411-1.431,31.682,31.682,0,0,1,4.309-2.489,1.757,1.757,0,0,1,1.584.512,1.553,1.553,0,0,1-.157,1.529c-1.466,1-3.045,1.827-4.588,2.709A3.123,3.123,0,0,1,422.734,967.551Z"
        transform="translate(-8.129 -79.969)"
        fill="currentColor"
      />
      <path
        id="Tracé_299"
        data-name="Tracé 299"
        d="M538.063,961.641a10.016,10.016,0,0,1,1.13.452c1.2.675,2.388,1.369,3.576,2.066.718.422,1.167.981.707,1.839a1.293,1.293,0,0,1-2,.483,40.894,40.894,0,0,1-3.855-2.279,2.041,2.041,0,0,1-.541-1.6C537.1,962.25,537.721,961.95,538.063,961.641Z"
        transform="translate(-95.968 -79.226)"
        fill="currentColor"
      />
      <path
        id="Tracé_300"
        data-name="Tracé 300"
        d="M519.052,989.418a2.7,2.7,0,0,1-.893-.666c-.82-1.3-1.571-2.639-2.312-3.983a1.269,1.269,0,0,1,.442-1.852,1.2,1.2,0,0,1,1.818.482c.817,1.346,1.615,2.706,2.353,4.1C520.951,988.415,520.324,989.408,519.052,989.418Z"
        transform="translate(-79.634 -95.251)"
        fill="currentColor"
      />
      <path
        id="Tracé_301"
        data-name="Tracé 301"
        d="M454.289,984.292a10.207,10.207,0,0,1-.407,1.007c-.7,1.242-1.42,2.471-2.12,3.712a1.326,1.326,0,0,1-1.9.664,1.277,1.277,0,0,1-.444-1.945c.751-1.3,1.445-2.647,2.3-3.879a1.76,1.76,0,0,1,1.458-.611C453.614,983.323,453.951,983.948,454.289,984.292Z"
        transform="translate(-29.003 -95.668)"
        fill="currentColor"
      />
      <path
        id="Tracé_302"
        data-name="Tracé 302"
        d="M414.793,936.221a9.26,9.26,0,0,1-2.368-.053,2.061,2.061,0,0,1-1.315-1.188,1.187,1.187,0,0,1,1.265-1.392c1.586-.059,3.178-.068,4.763.006a1.242,1.242,0,0,1,1.259,1.432,1.186,1.186,0,0,1-1.341,1.194C416.3,936.232,415.547,936.223,414.793,936.221Z"
        transform="translate(0 -57.822)"
        fill="currentColor"
      />
      <path
        id="Tracé_303"
        data-name="Tracé 303"
        d="M547.666,936.18q-1.125,0-2.249,0c-.859,0-1.465-.511-1.35-1.274a1.877,1.877,0,0,1,1.194-1.31,33.128,33.128,0,0,1,4.845-.045,1.239,1.239,0,0,1,1.258,1.422,1.2,1.2,0,0,1-1.331,1.2C549.244,936.19,548.455,936.18,547.666,936.18Z"
        transform="translate(-101.289 -57.778)"
        fill="currentColor"
      />
    </g>
  </svg>
</template>