
  import { defineComponent, ref, onMounted, watch, inject } from "vue";
  import Table from "@/components/Table.vue";
  import AddIcon from "@/components/icons/AddIcon.vue";
  import SearchIcon from "@/components/icons/SearchIcon.vue";
  //import axios from "axios";
  import { AxiosInstance } from 'axios'
  import { BIconPencilSquare, BIconTrash } from "bootstrap-icons-vue";
  import Swal from "sweetalert2";
  import { useNotification } from "@kyvg/vue3-notification";
import PackRequest from "@/services/requests/packs";
  export default defineComponent({
    components: {
      Table,
      AddIcon,
      SearchIcon,
      BIconPencilSquare,
      BIconTrash
    },

    setup() {
      const notification = useNotification();
      const axios = inject<AxiosInstance>('axios')
      const url = ref('packs/user');
      const page = ref(1);
      const datatable = ref();
      const headers = ref([
        { value: "_id", text: "id", show: true, order: 1, type: "input" },
        { value: "sku", text: "sku", show: true, order: 2, type: "input" },
        { value: "title", text: "title", show: true, order: 3, type: "input" },
        { value: "available_stock", text: "stock", show: true, order: 4, type: "input" },
        { value: "ean13", text: "ean 13", show: true, order: 5, type: "input" }
      ]);
      //const rows = ref([]);
      const actions = ref(true);
      watch(page, (page, prevPage) => {
        //getProducts();
      });
      function configConfirmed(new_config){
        //console.log('Config confirmed', new_config)
        headers.value = new_config;
      }
      
      function getProducts() {
        /*if (!axios) {
          //console.error('Axios instance is not defined')
          return {}
        }
        axios
          .get(`products?_page=${page.value}`)
          .then((response) => {
            console.log(response);
            total.value = parseInt(response.headers["x-total-count"]);
            rows.value = response.data;
          })
          .catch((error) => console.log(error));*/
      }
      function getPageNumber(value) {
        page.value = value;
      }
      function deleteProduct(id){
        Swal.fire({
          title: "Are you sure?",
          text: "The product will be deleted",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if(result.isConfirmed){
              PackRequest.deletePack(id)
              .then(() => {
                notification.notify({
                  title: "Pack successfully deleted",
                  type: 'success'
                });
                datatable.value.loadData();
            }) 
          }
          
        })
      }
  
       
      onMounted(() => {
        //getProducts();
        window.scrollTo(0, 0);
      });
      return {
        datatable,
        page,
        headers,
        actions,
        getProducts,
        getPageNumber,
        url,
        deleteProduct,
        configConfirmed
      };
    },
  });
  